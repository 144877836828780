import { Box, Tooltip } from "@mui/material";
import {
  CheckValueLocale,
  convertUnixTimeStampToTimeFormat,
  convertUnixTimeStampToUTCFormat,
  getSocialIcon,
} from "utils/helpers";
import OptionsDropdown from "./optionsDropdown";
import SocialListening from "images/Historical Request/SocialListening.svg";
import Omnichannel from "images/Historical Request/Omnichannel.svg";

export const generateHistoricalTableBody = (
  data,
  processingIndicator,
  cancellableDatasources,
  intl,
) => {
  const getStatus = (status) => {
    return (
      <Box className={"tb-status tb-status-" + status}>
        {CheckValueLocale(status, "", {}, intl)}
      </Box>
    );
  };

  const getCreater = (creater, createdAt) => {
    const newCreatedAt = createdAt
      ?.split("T")[0]
      .split("-")
      .reverse()
      .join("-");
    return (
      <Box>
        <Box>{creater}</Box>
        <Box className={"created-at-historical"}>{newCreatedAt}</Box>
      </Box>
    );
  };

  const sourcesBodyCell = (item) => {
    return (
      <Box className="icon-div">
        {getSocialIcon(item?.attributes?.data_source.source)}
        <Box className="req-to">{item?.attributes?.monitor_model?.name}</Box>
      </Box>
    );
  };
  const optionsCell = (item) => {
    return (
      <OptionsDropdown
        requestId={item?.id}
        requestData={item?.attributes}
        processingIndicator={processingIndicator}
        cancellableDatasources={cancellableDatasources}
      />
    );
  };

  const grtProductIcon = (product) => {
    if (product?.name === "SM") {
      return (
        <Box
          component={"img"}
          src={SocialListening}
          alt={CheckValueLocale(product?.name, "", {}, intl)}
        />
      );
    }
    return (
      <Box
        component={"img"}
        src={Omnichannel}
        alt={CheckValueLocale(product?.name, "", {}, intl)}
      />
    );
  };

  const productsCell = (product) => {
    return (
      <Tooltip
        title={
          <Box>
            {CheckValueLocale(
              product?.name === "SM" ? "social_listening_v2" : product?.name,
              "",
              {},
              intl,
            )}
          </Box>
        }
        arrow
        placement="top"
      >
        {grtProductIcon(product)}
      </Tooltip>
    );
  };

  const getDateRangeCell = (start, end) => {
    const dateRange = `${convertUnixTimeStampToUTCFormat(start)} - ${convertUnixTimeStampToUTCFormat(end)}`;
    const startTime = `${CheckValueLocale("start_time", "", {}, intl)}: ${convertUnixTimeStampToTimeFormat(start)}`;
    const endTime = `${CheckValueLocale("end_time", "", {}, intl)}: ${convertUnixTimeStampToTimeFormat(end)}`;
    return (
      <Tooltip
        title={
          <Box>
            <Box>{startTime}</Box>
            <Box>{endTime}</Box>
          </Box>
        }
        arrow
        placement="top"
      >
        {dateRange}
      </Tooltip>
    );
  };

  return !data?.data?.length
    ? []
    : data?.data?.map((item) => {
        return {
          data: [
            { body: item?.id ? item?.id : "—" },
            { body: productsCell(item?.attributes?.product) },
            {
              body: item?.attributes?.created_by?.trim()?.length
                ? getCreater(
                    item?.attributes?.created_by,
                    item?.attributes?.created_at,
                  )
                : "—",
              isCreatedBy: true,
            },
            { body: sourcesBodyCell(item) },
            {
              body: getDateRangeCell(
                item?.attributes?.start_date,
                item?.attributes?.end_date,
              ),
            },
            {
              body: `${
                item?.attributes?.collected?.toLocaleString("en-US") ?? 0
              } / ${
                item?.attributes?.count_quota_requested?.toLocaleString(
                  "en-US",
                ) ?? 0
              }`,
            },
            {
              body: `${
                item?.attributes?.data_source?.id === 1
                  ? (item?.attributes?.duplicates ?? 0)
                  : "N/A"
              }`,
            },
            { body: getStatus(item?.attributes?.status) },
            { body: optionsCell(item) },
          ],
        };
      });
};
