import { useMutation } from "@tanstack/react-query";
import { SingleTweetServices } from "services/api/singleTweetServices";

export const useUpdateSingleTweetAnalysis = () => {
  return useMutation({
    mutationFn: (queryData) => {
      return SingleTweetServices.createAnalysisSingleTweet(queryData);
    },
  });
};
