import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import "../../createMonitor.scss";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { CheckValueLocale, isEmptyArray } from "utils/helpers";
import TwitterPublicAccount from "./PublicAccountDataSources/twitterPublicAccount";
import InstagramPublicAccount from "./PublicAccountDataSources/instagramPublicAccount";
import FacebookPublicAccount from "./PublicAccountDataSources/facebookPublicAccount";
import SocialMediaTabs from "../../Components/SocialMediaTabs/socialMediaTab";

import Topic from "../../../MonitorsShared/SharedComponent/manualTopics/index";
import { checkKeywordsLimits } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/kewordsSharedFunctions";
import SnackBar from "components/snackBar";
import xPlatform from "images/social-icons/x-platform-icon.svg";
import { useIntl } from "react-intl";

const PublicAccountDataSources = (props) => {
  const intl = useIntl();
  const { monitorData, monitorErrors } = props;
  const {
    setMonitorData,
    setMonitorErrors,
    handleCreateMonitorBtn,
    companyAllowedSources,
    authorizedAccounts,
    isLoading,
    monitorTopics,
    setMonitorTopics,
    errorKeywordsMsg,
    setErrorKewyordsMsg,
    errorExKeywordsMsg,
    setExErrorKewyordsMsg,
    displayNameMsgError,
    setDisplayNameMsgError,
    disabledManualTopic,
    manualTopicStats,
    getTwitterLanguagesAndCountries,
    twitterLanguagesAndCountries,
    twitterWordsCounter,
    value,
    setValue,
    handleMonitorTopicsUsage,
  } = props;
  const [open, setOpen] = useState(false);
  let categotyDataSources = companyAllowedSources;
  const icons = [xPlatform, faFacebook, faInstagram];
  const handleChange = (event, newValue) => {
    setMonitorData({
      monitorName: monitorData?.monitorName,
      monitorDescription: monitorData?.monitorDescription,
      userName: "",
      cstCareAccount: "",
      spamKeywords: [],
      ignoreTweets: [],
      languages: [],
      twitterCountries: [],
      twitterLanguages: [],
      auto_summary_widget: false,
    });

    setMonitorErrors({
      monitorName: monitorErrors?.monitorName || "",
      monitorDescription: monitorErrors?.monitorDescription || "",
      userName: "",
      cstCareAccount: "",
    });
    setValue(newValue);
  };
  //hide topics in ig PA
  let showTopics =
    (!isEmptyArray(authorizedAccounts?.twitterAccounts) && value === 1) ||
    (!isEmptyArray(authorizedAccounts?.facebookAccounts) && value === 2);

  const twLimit = value === 1 && twitterWordsCounter >= 100;
  const handleClose = () => {
    setOpen(false);
  };
  const { checkExceedLimited, messageLimits } = checkKeywordsLimits(twLimit);
  useEffect(() => {
    if (twitterWordsCounter >= 100) {
      setOpen(true);
    }
  }, [twitterWordsCounter]);

  return (
    <>
      {checkExceedLimited && (
        <>
          <SnackBar
            open={open}
            handleClose={handleClose}
            message={CheckValueLocale(messageLimits, "", { limit: 100 }, intl)}
            severity="error"
          />
        </>
      )}
      <Box>
        <SocialMediaTabs
          handleChange={handleChange}
          value={value}
          categotyDataSources={categotyDataSources}
          icons={icons}
          socialMediaCount={1}
          isPublicAccount
        />
        {value === 1 && (
          <TwitterPublicAccount
            setMonitorData={setMonitorData}
            monitorData={monitorData}
            setMonitorErrors={setMonitorErrors}
            monitorErrors={monitorErrors}
            handleCreateMonitorBtn={handleCreateMonitorBtn}
            twSourceId={props.dataSourcesId.twSourceId}
            setActiveDataSourceId={props.setActiveDataSourceId}
            twitterAccounts={authorizedAccounts?.twitterAccounts}
            isLoading={isLoading}
            authorizedAccounts={authorizedAccounts}
            getTwitterLanguagesAndCountries={getTwitterLanguagesAndCountries}
            twitterLanguagesAndCountries={twitterLanguagesAndCountries}
            twitterWordsCounter={props.twitterWordsCounter}
            setTwitterWordsCounter={props.setTwitterWordsCounter}
          />
        )}
        {value === 3 && (
          <InstagramPublicAccount
            setMonitorData={setMonitorData}
            monitorData={monitorData}
            setMonitorErrors={setMonitorErrors}
            monitorErrors={monitorErrors}
            handleCreateMonitorBtn={handleCreateMonitorBtn}
            igSourceId={props.dataSourcesId.igSourceId}
            setActiveDataSourceId={props.setActiveDataSourceId}
            instagramAccounts={authorizedAccounts?.instagramAccounts}
            isLoading={isLoading}
            authorizedAccounts={authorizedAccounts}
          />
        )}
        {value === 2 && (
          <FacebookPublicAccount
            setMonitorData={setMonitorData}
            monitorData={monitorData}
            setMonitorErrors={setMonitorErrors}
            monitorErrors={monitorErrors}
            handleCreateMonitorBtn={handleCreateMonitorBtn}
            fbSourceId={props.dataSourcesId.fbSourceId}
            setActiveDataSourceId={props.setActiveDataSourceId}
            facebookAccounts={authorizedAccounts?.facebookAccounts}
            isLoading={isLoading}
            dataSourceName={"facebook"}
            authorizedAccounts={authorizedAccounts}
          />
        )}
      </Box>
      {showTopics && (
        <Box>
          <Topic
            monitorTopics={monitorTopics}
            setMonitorTopics={setMonitorTopics}
            errorKeywordsMsg={errorKeywordsMsg}
            setErrorKewyordsMsg={setErrorKewyordsMsg}
            errorExKeywordsMsg={errorExKeywordsMsg}
            setExErrorKewyordsMsg={setExErrorKewyordsMsg}
            displayNameMsgError={displayNameMsgError}
            setDisplayNameMsgError={setDisplayNameMsgError}
            disabledManualTopic={disabledManualTopic}
            manualTopicStats={manualTopicStats}
            addSeperatedLine
            handleMonitorTopicsUsage={handleMonitorTopicsUsage}
          />
        </Box>
      )}
    </>
  );
};

export default PublicAccountDataSources;
