import { CheckValueLocale, getDatasourceIconName } from "utils/helpers";
import * as Yup from "yup";

export const processFarewellGreetingMessagesData = (inputData) => {
  const processedFarewellGreetingMessages =
    inputData.farewell_greeting_messages?.map((dataSource) => {
      const currentDataSource = dataSource.data_source_name?.toLowerCase();
      let greetingMessages = dataSource.messages?.filter(
        (m) => m?.message_type === "greeting",
      );
      let farewellMessages = dataSource.messages?.filter(
        (m) => m?.message_type === "farewell",
      );

      greetingMessages = fillMissingMessages(
        greetingMessages,
        inputData?.lookup_messages,
        "greeting",
        currentDataSource,
      );
      farewellMessages = fillMissingMessages(
        farewellMessages,
        inputData?.lookup_messages,
        "farewell",
        currentDataSource,
      );

      return {
        ...dataSource,
        messages: [...greetingMessages, ...farewellMessages]?.slice(0, 4),
      };
    });

  return {
    ...inputData,
    farewell_greeting_messages: processedFarewellGreetingMessages,
  };
};

const fillMissingMessages = (
  messages,
  lookupMessages,
  messageType,
  currentDataSource,
) => {
  while (messages.length < 2) {
    const lookupMessage = lookupMessages?.find(
      (m) =>
        m?.message_type === messageType &&
        !messages?.some((msg) => msg?.message === m?.message),
    );
    if (lookupMessage) {
      messages.push({
        id: lookupMessage.id,
        message: lookupMessage?.message,
        message_type: messageType,
        selected: false,
        deleted: false,
        engagement_settings_id: null,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        isNew: true,
        data_source_id: currentDataSource,
      });
    } else {
      break;
    }
  }
  return messages;
};

export const countChangesInFarewellAndGreetingMessages = (
  oldState,
  newState,
) => {
  let changes = 0;

  function compareMessageArrays(oldArr, newArr) {
    let greetingSelectionChanged = false;
    let farewellSelectionChanged = false;
    const maxLength = Math.max(oldArr?.length, newArr?.length);

    for (let i = 0; i < maxLength; i++) {
      if (i < oldArr?.length && i < newArr?.length) {
        if (oldArr[i]?.message !== newArr[i]?.message) {
          changes++;
        }

        if (oldArr[i]?.selected !== newArr[i]?.selected) {
          if (
            oldArr[i]?.message_type === "greeting" &&
            !greetingSelectionChanged
          ) {
            changes++;
            greetingSelectionChanged = true;
          } else if (
            oldArr[i]?.message_type === "farewell" &&
            !farewellSelectionChanged
          ) {
            changes++;
            farewellSelectionChanged = true;
          }
        }
      } else {
        changes++;
      }
    }
  }

  oldState?.farewell_greeting_messages?.forEach((oldSource, index) => {
    const newSource = newState?.farewell_greeting_messages[index];
    if (newSource) {
      compareMessageArrays(oldSource?.messages, newSource?.messages);
    } else {
      changes += oldSource?.messages?.length;
    }
  });

  if (
    newState?.farewell_greeting_messages?.length >
    oldState?.farewell_greeting_messages?.length
  ) {
    for (
      let i = oldState?.farewell_greeting_messages?.length;
      i < newState?.farewell_greeting_messages?.length;
      i++
    ) {
      changes += newState?.farewell_greeting_messages[i]?.messages?.length;
    }
  }

  const oldEnabled = oldState?.farewell_greeting_enabled;
  const newEnabled = newState?.farewell_greeting_enabled;
  for (const key in oldEnabled) {
    if (oldEnabled[key] !== newEnabled[key]) {
      changes++;
    }
  }

  return changes;
};

// if we are adding a new message (from the lookup messages) we need to remove the id and isNew fields
// this will only be used for transforming the PAYLOAD ONLY
export const transformMessageDataPayload = (data) => {
  return {
    ...data,
    farewell_greeting_messages: data?.farewell_greeting_messages?.map(
      (source) => ({
        ...source,
        messages: source?.messages?.map((message) => {
          if (message?.isNew === true) {
            return {
              ...message,
              id: undefined,
              isNew: undefined,
            };
          }
          return message;
        }),
      }),
    ),
  };
};

export const greetingFarewellMessagesTaps = (intl) => [
  {
    label: CheckValueLocale("instagram", "", {}, intl),
    id: "instagram",
    icon: getDatasourceIconName("INSTAGRAM"),
  },
  {
    label: CheckValueLocale("facebook", "", {}, intl),
    id: "facebook",
    icon: getDatasourceIconName("FACEBOOK"),
  },
  {
    label: CheckValueLocale("twitter", "", {}, intl),
    id: "twitter",
    icon: getDatasourceIconName("XPLATFORM"),
  },
];

export const editMessageValidationSchema = Yup.object({
  message: Yup.string().when("message_type", {
    is: "greeting", // If the message type is 'greeting'
    then: () => Yup.string(),
    otherwise: () => Yup.string()
      .test("is-not-all-spaces", "", (value) => {
        return value?.trim().length > 0; // Ensure non-empty and not just spaces
      })
      .required("Message is required"), // Make it mandatory for 'farewell'
  }),
});
