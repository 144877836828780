import {
  Box,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
  Tab,
  Typography,
} from "@mui/material";
import GreetingFarewellMessage from "../components/greetingFarewellMessage";
import { useState } from "react";
import EditGreetingFarewellMessageModal from "./editGreetingFarewellMessageModal";
import { TabContext, TabList } from "@mui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import SurveyRequest from "../../aiAgents/component/aiAgentsContent/aiAgentsFormContent/component/endConversation/surveyRequest";
import FarewellMessageSurvey from "../components/farewellMessageSurvey";
import { greetingFarewellMessagesTaps } from "../utils/farewellGreetingUtils";
import SnackBar from "components/snackBar";

const GreetingAndFarewellBasicSwitch = ({
  title,
  description,
  checked,
  handleChange,
}) => {
  const intl = useIntl();
  return (
    <Box>
      <FormControlLabel
        className="greeting-farewell-message-switch-form-control-label"
        classes={{ label: "greeting-farewell-message-switch-label" }}
        control={
          <Switch
            size="small"
            className="greeting-farewell-message-switch"
            checked={checked}
            onChange={handleChange}
          />
        }
        label={CheckValueLocale(title, "", {}, intl)}
      />
      <FormHelperText className="greeting-farewell-message-switch-helperText">
        {CheckValueLocale(description, "", {}, intl)}
      </FormHelperText>
    </Box>
  );
};

const GreetingAndFarewell = ({ className, messagesData, setMessagesData }) => {
  const intl = useIntl();
  const [isEditMessageModalOpen, setIsEditMessageModalOpen] = useState(false);
  const [selectedTap, setSelectedTap] = useState("instagram");
  const [selectedItemDate, setSelectedItemDate] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState({
    success: false,
    type: "",
  });

  const greetingFarewellEnabledObject = messagesData?.farewell_greeting_enabled;
  const lowerCaseSelectedTap = selectedTap?.toLowerCase();
  const upperCaseSelectedTap = selectedTap?.toUpperCase();

  const handleSwitchChange = (type) => {
    setMessagesData({
      ...messagesData,
      farewell_greeting_enabled: {
        ...greetingFarewellEnabledObject,
        [`${selectedTap}_${type}_message_enabled`]:
          !greetingFarewellEnabledObject[
            `${selectedTap}_${type}_message_enabled`
          ],
      },
    });
  };

  const handleChangeMessageText = ({ messageItem, newText }) => {
    handleSelectedMessage(messageItem?.id, messageItem?.message_type, newText);
    setIsEditMessageModalOpen(false);
    setSnackbarOpen({
      success: true,
      type: messageItem?.message_type,
    });
  };

  const handleSelectedMessage = (id, messageType, newText) => {
    const currentSelectedTapDate =
      messagesData?.farewell_greeting_messages?.find(
        (message) => message.data_source_name === upperCaseSelectedTap,
      );
    const currentSelectedTapMessages = currentSelectedTapDate?.messages;
    const updatedMessages = currentSelectedTapMessages?.map((message) => {
      if (message.id === id) {
        return {
          ...message,
          selected: message?.id === id,
          message: newText === undefined ? message?.message : newText,
        };
      }
      if (message?.message_type === messageType) {
        return {
          ...message,
          selected: false,
        };
      }
      return message;
    });

    setMessagesData({
      ...messagesData,
      farewell_greeting_messages: messagesData?.farewell_greeting_messages?.map(
        (message) => {
          if (message?.data_source_name === upperCaseSelectedTap) {
            return {
              ...message,
              messages: updatedMessages,
            };
          }
          return message;
        },
      ),
    });
  };

  const activeProducts = JSON.parse(
    window?.localStorage?.activeProducts || null,
  );
  const surveyActive = activeProducts?.find(
    (product) => product?.name == "SURVEY",
  );

  const getMessages = ({ type }) => {
    return messagesData.farewell_greeting_messages
      .find((message) => message?.data_source_name === upperCaseSelectedTap)
      ?.messages?.filter((message) => message?.message_type === type);
  };

  const isTwitterSelected = selectedTap === "twitter";

  return (
    <Box className={`greeting-farewell-message-wrapper ${className}`}>
      <TabContext value={selectedTap}>
        <Box className="data-sources-tap-container">
          <TabList onChange={(_, newValue) => setSelectedTap(newValue)}>
            {greetingFarewellMessagesTaps(intl)?.map((tap) => (
              <Tab
                key={tap?.label}
                label={tap?.label}
                icon={<FontAwesomeIcon icon={tap?.icon} />}
                iconPosition="start"
                value={tap?.id}
              />
            ))}
          </TabList>
        </Box>
      </TabContext>
      <GreetingAndFarewellBasicSwitch
        title="send_greeting_message"
        description="send_greeting_message_description"
        checked={
          greetingFarewellEnabledObject[
            `${lowerCaseSelectedTap}_greeting_message_enabled`
          ]
        }
        handleChange={() => handleSwitchChange("greeting")}
      />
      {greetingFarewellEnabledObject[
        `${lowerCaseSelectedTap}_greeting_message_enabled`
      ] ? (
        <Box className="greeting-messages-farewell-background-container">
          {isTwitterSelected ? (
            <Box className="farewell-disclaimer-container">
              <FontAwesomeIcon
                className="farewell-disclaimer-icon"
                icon={faTriangleExclamation}
              />
              <Typography className="farewell-disclaimer-text">
                {CheckValueLocale(
                  "farewell_x_limit_rate_disclaimer",
                  "",
                  {},
                  intl,
                )}
              </Typography>
            </Box>
          ) : null}
          <Grid container alignItems="stretch" spacing="18px">
            {getMessages({
              type: "greeting",
            })?.map((message) => (
              <Grid key={message?.id} item xs={6}>
                <GreetingFarewellMessage
                  key={message.id}
                  handleEditClick={(e) => {
                    e.stopPropagation();
                    setIsEditMessageModalOpen(true);
                    setSelectedItemDate(message);
                  }}
                  isSelected={message?.selected}
                  message={message?.message}
                  handleSelectedMessage={() => {
                    handleSelectedMessage(message?.id, "greeting");
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : null}
      <GreetingAndFarewellBasicSwitch
        title="send_farewell_message"
        description="send_farewell_message_description"
        checked={
          greetingFarewellEnabledObject[
            `${lowerCaseSelectedTap}_farewell_message_enabled`
          ]
        }
        handleChange={() => handleSwitchChange("farewell")}
      />
      {greetingFarewellEnabledObject[
        `${lowerCaseSelectedTap}_farewell_message_enabled`
      ] ? (
        <Box className="greeting-messages-farewell-background-container">
          <Grid container alignItems="stretch" spacing="18px">
            {getMessages({
              type: "farewell",
            })?.map((message) => (
              <Grid key={message?.id} item xs={6}>
                <GreetingFarewellMessage
                  key={message.id}
                  handleEditClick={(e) => {
                    e.stopPropagation();
                    setIsEditMessageModalOpen(true);
                    setSelectedItemDate(message);
                  }}
                  isSelected={message?.selected}
                  message={message?.message}
                  handleSelectedMessage={() => {
                    handleSelectedMessage(message?.id, "farewell");
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : null}
      {greetingFarewellEnabledObject[
        `${lowerCaseSelectedTap}_farewell_message_enabled`
      ] ? (
        surveyActive ? (
          <FarewellMessageSurvey
            messagesData={messagesData}
            setMessagesData={setMessagesData}
            selectedTapSurveyIdKey={`${lowerCaseSelectedTap}_survey_id`}
          />
        ) : (
          <SurveyRequest isFarewell={true} />
        )
      ) : null}
      {isEditMessageModalOpen ? (
        <EditGreetingFarewellMessageModal
          close={() => setIsEditMessageModalOpen(false)}
          open={isEditMessageModalOpen}
          message={selectedItemDate}
          handleChangeMessageText={handleChangeMessageText}
        />
      ) : null}
      {snackbarOpen?.success ? (
        <SnackBar
          open={snackbarOpen?.success}
          handleClose={() => {
            setSnackbarOpen({
              success: false,
              message: "",
              type: "",
            });
          }}
          severity="success"
          message={
            <Box className="ai-agent-snackBar-message">
              {CheckValueLocale(
                `${
                  snackbarOpen?.type && snackbarOpen?.type === "greeting"
                    ? "greeting"
                    : "farewell"
                }_message_edited_successfully`,
                "",
                {},
                intl,
              )}
            </Box>
          }
        />
      ) : null}
    </Box>
  );
};

export default GreetingAndFarewell;
