import { useMutation } from "@tanstack/react-query";
import { FiltersServices } from "services/api/filterServices";

export const useGetAllSavedFilters = () => {
  return useMutation({
    mutationFn: ({ monitor_id, product_id, data_source_id, section_name }) => {
      const queryData = {
        params: {
          monitor_id,
          product_id,
          data_source_id,
          section_name,
        },
      };
      return FiltersServices.getAllSavedFilters({ queryData });
    },
  });
};
