import { Box, Divider, Tooltip, Typography } from "@mui/material";
import PageHeader from "components/pageHeader";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import DateRangePicker from "shared/reduxComponents/dateRangePicker/dateRangePicker";
import {
  CheckValueLocale,
  convertXPlatformToTwitter,
  handlelUserRoles,
  isEmptyArray,
} from "utils/helpers";
import BreadCrumb from "../../../../components/breadCrumb";
import Filter from "../../../../shared/reduxComponents/filters/general/filter";
import "./monitorHeader.scss";
import HelpIcon from "@mui/icons-material/Help";
import MonitorCreateAlert from "../actionButtons/monitorCreateAlert";
import MonitorCreateReport from "../actionButtons/monitorCreateReport";
import MonitorLiveDashboardMenu from "../actionButtons/monitorLiveDashboardMenu";
import SharedExportButton from "shared/exportButton/sharedExportButton";
import MonitorsController from "services/controllers/monitorsController";
import SnackBar from "components/snackBar";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";

const MonitorHeader = (props) => {
  const supportedBackendExcelTabs = [
    "posts",
    "comments_mentions",
    "questions",
    "authors",
    "customer_care",
  ];
  const intl = useIntl();
  const [openSnackBar, setOpenSnackBar] = useState(false); // to show snackbar
  const [snackBarData, setSnackBarData] = useState({
    // snackbar data
    message: "",
    severity: "",
    title: "",
  });
  const [pptPages, setPPtPages] = useState([]);
  const [isAnalyticsPage, setIsAnalyticsPage] = useState(false);
  const [isDataLoadingBE, setIsDataLoadingBE] = useState(false);
  const hideArabicExport = supportedBackendExcelTabs?.includes(
    props?.activeTab,
  );

  const urlParams = useParams();
  const location = useLocation();

  const dataSourceName = convertXPlatformToTwitter(urlParams.monitorDataSource);
  const monitorDataSource =
    dataSourceName === "TALKWALKER" || dataSourceName === "NEWSBLOGS"
      ? "News & Blogs"
      : dataSourceName;

  let monitorActiveTab = urlParams.activeTab;
  monitorActiveTab =
    monitorActiveTab.charAt(0).toUpperCase() + monitorActiveTab.slice(1);
  const monitorDetails = props.monitorData;
  const isAuthorProfile = location?.pathname?.includes("author-profile"); //if avaliable redirect user to AuthorsProfilePage.js
  const isDeepInsights = location?.pathname?.includes("deep-insights"); //if avaliable redirect user to DeepInsightsPage.js

  let showButtons = !isAuthorProfile && !isDeepInsights;

  const allowExport = JSON.parse(
    window?.localStorage?.info_attributes,
  )?.allow_posts_export;
  const hidePPTExport =
    props?.activeTab === "questions" ||
    props?.activeTab === "posts" ||
    props?.activeTab === "comments_mentions" ||
    props?.activeTab === "authors";
  const hideExcelExport =
    !handlelUserRoles("SM", "EXPORT_DATA") || allowExport === false;
  const showExportButton = !hidePPTExport || !hideExcelExport; // if both are hidden we won't the show the export button

  const { changeFilterParamsToString } = CommonFunctions();

  /*______________________________________________________________*/
  //Bread Crumb Links

  const links = [
    {
      name: "monitors_list",
      url: "social/monitor_list",
      addNameLocal: true,
    },
    {
      name: isAuthorProfile
        ? `${monitorDetails?.id} - ${monitorDetails?.attributes.name}` +
          ` (${CheckValueLocale(
            monitorDataSource.toLowerCase(),
            "",
            {},
            intl,
          )})` +
          `    /  ${monitorActiveTab}`
        : `${monitorDetails?.id} - ${monitorDetails?.attributes?.name}`,
      url: `monitor/${urlParams.monitorType}/${urlParams.monitorDataSource}/${urlParams.activeTab}/${urlParams.monitorId}`,
      addNameLocal: false,
      state: monitorDetails,
    },
  ];

  let header = isAuthorProfile
    ? CheckValueLocale("engager_profile", "", {}, intl)
    : isDeepInsights
      ? CheckValueLocale("profile_analytics", "", {}, intl)
      : CheckValueLocale(urlParams.activeTab, "", {}, intl);

  let monitorActiveTabId = `${monitorDataSource?.toLowerCase()}-${monitorActiveTab?.toLowerCase()}-${
    urlParams?.monitorId
  }`;

  const reduxFilterParams = useSelector(
    (state) => state?.socialListening?.newFilterParams,
  );

  const reduxFilterParamsCount = useSelector(
    (state) => state?.socialListening?.filterParamsCount,
  );

  const reduxFilterStartDateUX = useSelector(
    (state) => state?.socialListening?.startDateUnix,
  );
  const reduxFilterEndDateUX = useSelector(
    (state) => state?.socialListening?.endDateUnix,
  );

  const savedFileName =
    `${props?.monitorData?.attributes?.account_name}` +
    "-" +
    `${props?.activeTab}`;

  const fildesNames = props?.filterFields?.map((item) => item?.name);
  const inApplicableFilters = {};
  Object.keys(reduxFilterParams)?.map((key) => {
    if (!fildesNames?.includes(key)) {
      inApplicableFilters[key] = reduxFilterParams[key];
    }
  });

  let inApplicableFiltersArray = Object?.entries(inApplicableFilters)?.map(
    (key) => {
      return {
        name: key[0],
        value: key[1],
      };
    },
  );

  let toolTipText = !isEmptyArray(inApplicableFiltersArray)
    ? inApplicableFiltersArray?.map((item, index) => {
        return (
          <li key={index}>
            {`${CheckValueLocale(item?.name, "", {}, intl)}: ${
              Array.isArray(item?.value)
                ? item?.name === "account_lists" ||
                  item?.name === "exclude_account_lists"
                  ? props?.accountListsOptions?.data?.data
                      ?.filter((accountsList) =>
                        item?.value
                          ?.map((i) => i?.toString())
                          .includes(accountsList?.id?.toString()),
                      )
                      ?.map((accountsList) => accountsList?.attributes?.name)
                      ?.join(", ")
                  : CheckValueLocale(item?.value?.join(", "), "", {}, intl)
                : (item?.value?.replace(",", ", ") ?? null)
            }`}
          </li>
        );
      })
    : null;

  const getExportPages = () => {
    MonitorsController.getExportPages(
      +window?.localStorage?.sm_id,
      +props?.dataSourceId,
      +urlParams.monitorId,
    ).then((data) => {
      if (data?.data?.status == 200) {
        setPPtPages(data?.data?.data?.pages);
      }
    });
  };

  const handlePageChange = (event) => {
    if (event?.target?.value == "analyticsPages") {
      setIsAnalyticsPage(true);
    } else {
      setIsAnalyticsPage(false);
    }
  };

  const handlePPTExport = (language, handleClose) => {
    MonitorsController.getSocialMediaPPT(
      props?.monitorDataSource,
      props?.monitorData?.attributes?.id,
      window?.localStorage?.sm_id,
      props?.activeTab,
      reduxFilterStartDateUX,
      reduxFilterEndDateUX,
      !props?.showFilterBtn
        ? {}
        : changeFilterParamsToString(reduxFilterParams),
      props?.pageNumber,
      language,
      true,
    ).then((data) => {
      if (data?.data?.status == 200) {
        setSnackBarData({
          severity: "success",
          message: "ppt_success",
          title: "ppt_title",
        });
        setOpenSnackBar(true);
      } else {
        setSnackBarData({
          severity: "error",
          message: "wrong_request_parameter",
          title: "try_again",
        });
        setOpenSnackBar(true);
      }
      handleClose();
      setIsDataLoadingBE(false);
    });
  };

  const handleExportAnalyticsPages = (language, handleClose) => {
    // used in exporting the analytics page
    MonitorsController.exportPPTAnalyticsPage(
      +window?.localStorage?.sm_id,
      +props?.dataSourceId,
      +props?.monitorId,
      language,
      reduxFilterStartDateUX,
      reduxFilterEndDateUX,
    ).then((data) => {
      if (data?.data?.status == 200) {
        setSnackBarData({
          severity: "success",
          message: "ppt_success",
          title: "ppt_title",
        });
        setOpenSnackBar(true);
      } else {
        setSnackBarData({
          severity: "error",
          message: "wrong_request_parameter",
          title: "try_again",
        });
        setOpenSnackBar(true);
      }
      handleClose();
      setIsDataLoadingBE(false);
    });
  };

  const exportPPT = ({ language, handleClose }) => {
    setIsDataLoadingBE(true);
    if (isAnalyticsPage) {
      handleExportAnalyticsPages(language, handleClose);
    } else {
      handlePPTExport(language, handleClose);
    }
    setIsAnalyticsPage(false);
  };

  const handleExportExcel = (handleClose) => {
    setIsDataLoadingBE(true);
    // handleExportExcel function is used to call to endpoints, both have different body and response
    // 1- new api xlsx export for SL
    // 2 - old api excel export for monitors
    // we trigger the new api if the page is SL and the tab is one of the supported tabs (which now all tans inside SL are supported)
    // in the xlsx the backend is expecting new parameters, such as page_name, total_posts, etc.
    // total_posts is'not shared variable like props.checkDataIsEmpty, so we had to make it global variable, to be used in the xlsx export.
    // we trigger the old api if the page is'nt SL or the tab is'nt one of the supported tabs
    const pageNameMap = {
      posts: "PostsPage",
      comments_mentions: "CommentsMentionsPage",
      questions: "QuestionsPage",
      authors: "AuthorsPage",
      customer_care: "CustomerCarePage",
    };
    const page_name = pageNameMap[props?.activeTab] || props?.activeTab;

    let queryData = {
      product_id: window?.localStorage?.sm_id,
      monitor_id: props?.monitorData?.attributes?.id,
      data_source: props?.monitorDataSource,
      start_date: reduxFilterStartDateUX,
      end_date: reduxFilterEndDateUX,
      filters: !props?.showFilterBtn
        ? {}
        : changeFilterParamsToString(reduxFilterParams),
    };
    let exportFn = MonitorsController?.getSLExportXLSX;
    // inject the sort_by in the filter if it exists
    if (props?.postsSortBy) {
      if (!queryData?.filters) queryData.filters = {};
      queryData.filters = {
        ...queryData?.filters,
        sort_by: props?.postsSortBy,
      };
    }
    // in the new api if the filters object is empty, we don't send it at all.
    if (!queryData?.filters || Object.keys(queryData?.filters).length === 0) {
      delete queryData?.filters;
    }
    // if the data source is news blogs, we change it to TALKWALKER
    if (queryData?.data_source === "nb") {
      queryData.data_source = "TALKWALKER";
    }
    // get the total posts count from the props (as global start)
    const total_posts = props?.totalPostsCount;
    const SocialListing = {
      ...queryData,
      page_name,
      total_posts,
    };
    // the new api expects the data to be inside SocialListing object
    queryData = {
      data: {
        SocialListening: SocialListing,
      },
    };

    exportFn(queryData)?.then((data) => {
      if (data?.data?.status == 204 || data?.data?.status == 200) {
        setSnackBarData({
          severity: "success",
          message: "ppt_success",
          title: "your_exported_excel_on_way",
        });
      } else {
        setSnackBarData({
          severity: "error",
          message: "wrong_request_parameter",
          title: "try_again",
        });
      }
      setIsDataLoadingBE(false);
      handleClose();
      setOpenSnackBar(true);
    });
  };

  const logExcelExportProcess = () => {
    MonitorsController.exportExcelLogExport(props?.monitorId).then((res) => {});
  };
  // Handle ID method for ChurnZero elements
  const handleExportMonitorButtonId = (val) => {
    const monitorId = props?.monitorData?.id ?? "Id";
    const dataSource = props?.monitorDataSource?.toLowerCase();
    const activeTab = props?.activeTab ?? "tab";
    const sharedSegment = `${monitorId}-${dataSource}-${activeTab}`;

    if (val) {
      const itemId = val?.id ?? "id";
      return `export-${itemId}-${sharedSegment}-tab`;
    }

    return `export-monitor-${sharedSegment}-button`;
  };
  useEffect(() => {
    if (props?.dataSourceId) {
      getExportPages();
    }
  }, [props?.dataSourceId]);

  /*______________________________________________________________*/
  return (
    <>
      {showButtons ? (
        <Box className="export-monitor-box-container">
          <Box className="export-monitor-box">
            <>
              <Box className="monitor-header-actions-box">
                {handlelUserRoles("GENERAL", "VIEW_UTILITIES") &&
                handlelUserRoles("GENERAL", "CREATE_UTILITIES") ? (
                  <MonitorCreateReport monitorData={props?.monitorData} />
                ) : null}
                {handlelUserRoles("GENERAL", "VIEW_UTILITIES") &&
                handlelUserRoles("GENERAL", "CREATE_UTILITIES") &&
                !props?.monitorData?.attributes?.paused ? (
                  <MonitorCreateAlert monitorData={props?.monitorData} />
                ) : null}
              </Box>
              {handlelUserRoles("SM", "VIEW_DASHBOARD") ? (
                <MonitorLiveDashboardMenu
                  monitorLiveDashboardList={props?.monitorLiveDashboardList}
                  urlParams={urlParams}
                  monitorDetails={monitorDetails}
                  monitorLiveDashboardListLoading={
                    props?.monitorLiveDashboardListLoading
                  }
                />
              ) : null}
              <Divider
                orientation="vertical"
                className="monitor-header-actions-divider"
              />
              {props?.showFilterBtn || !!reduxFilterParamsCount ? (
                <>
                  {!isEmptyArray(props?.filterFields) &&
                  !isEmptyArray(inApplicableFiltersArray) &&
                  props?.showFilterBtn ? (
                    <Box className="monitor-inapplicable-filters">
                      <Tooltip
                        title={
                          <div className="monitor-inapplicable-tooltip">
                            {CheckValueLocale(
                              "not_applicable_filters",
                              "",
                              {},
                              intl,
                            )}
                            <ul>{toolTipText}</ul>
                          </div>
                        }
                        arrow
                        placement="bottom"
                      >
                        <Typography variant={"h7"}>
                          <HelpIcon />
                          {CheckValueLocale(
                            "inapplicable_filters",
                            "",
                            { num: inApplicableFiltersArray?.length },
                            intl,
                          )}
                        </Typography>
                      </Tooltip>
                    </Box>
                  ) : null}
                  <Filter
                    {...props}
                    id="monitors-list-filter"
                    inApplicableFiltersArray={inApplicableFiltersArray}
                    monitorActiveTabFilterID={monitorActiveTabId}
                    isSocialGlobalFilters
                  />
                </>
              ) : null}
              <DateRangePicker
                {...props}
                hasOnlyDatePicker={
                  !(props?.showFilterBtn || !!reduxFilterParamsCount)
                }
                openCustomDateLeft={true}
                monitorActiveTabId="monitor-header-date-range-picker"
                datePickerPosition="monitorheader-datepicker-position"
                id="date-range-picker-monitor"
              />
            </>
          </Box>
        </Box>
      ) : null}
      <Box className="view-monitor-detail">
        <Box className="monitor-header-box">
          <Box>
            <BreadCrumb id="breadcrumb-style-monitors" links={links} />
            <PageHeader title={header} withBreadCrumb={true} />
          </Box>

          {showButtons && showExportButton ? (
            <>
              <SharedExportButton
                hideExcelExport={hideExcelExport}
                hidePPTExport={hidePPTExport}
                isDataLoading={!props?.checkAllDataExist || isDataLoadingBE}
                dataLoadedButEmpty={!props?.checkDataIsEmpty}
                defaultExportType={!hidePPTExport ? "ppt" : "excel"}
                defaultExportLanguage={"en"}
                savedFileName={savedFileName} // for excel export
                excelSheetDataInfo={{
                  excelSheetData: props?.excelSheetData,
                  pageName: "social_listening",
                  activeTabName: props?.activeTab,
                  dataSourceName: props?.monitorDataSource,
                  monitorType: props?.monitorType,
                  themesOptions: props?.themesOptions,
                }}
                handlePPTExport={exportPPT}
                handleExcelBEExport={
                  supportedBackendExcelTabs?.includes(props?.activeTab)
                    ? handleExportExcel
                    : null
                }
                hideExportArabic={hideArabicExport}
                pagesData={{
                  showSelectPagesAlert: isAnalyticsPage,
                  selectPages: pptPages,
                  showSelectPagesBox: true,
                  handleSelectPageChange: handlePageChange,
                }}
                logExcelExportProcess={logExcelExportProcess}
                handleExportButtonId={handleExportMonitorButtonId}
              />
            </>
          ) : null}
        </Box>
        <Divider id="monitors-divider" />

        {openSnackBar ? (
          <SnackBar
            open={openSnackBar}
            severity={snackBarData?.severity}
            message={CheckValueLocale(snackBarData?.message, "", {}, intl)}
            title={CheckValueLocale(snackBarData?.title, "", {}, intl)}
            handleClose={() => setOpenSnackBar(false)}
          />
        ) : null}
      </Box>
    </>
  );
};
export default MonitorHeader;
