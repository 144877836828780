import { useMutation } from "@tanstack/react-query";
import { MainMonitorsPage } from "services/api/mainMonitorsPage";

export const useGetQuickInspectData = () => {
  return useMutation({
    mutationFn: ({ monitor_id, monitorDataSource, queryData }) => {
      return MainMonitorsPage.getQuickInspectData({
        monitor_id,
        monitorDataSource,
        queryData,
      });
    },
  });
};
