import { Box } from "@mui/system";
import DateRangePicker from "shared/dateRangePicker/dateRangePicker";
import NavigationSidebar from "../../navigationSidebar/navigationContent";
import { useState, useEffect } from "react";
import SwitchMenuList from "../../navigationSidebar/switchNavigationContent";
import { handlelUserRoles } from "utils/helpers";
import Filter from "shared/general-filter/filter";

export const EngagementAnalyticsHeader = (props) => {
  const [openNavigationMenu, setOpenNavigationMenu] = useState(false);
  const [navigationMenuStatus, setNavigationMenuStatus] = useState("analytics");
  const [haveMoreThanOne, setHaveMoreThanOne] = useState(false);

  useEffect(() => {
    setHaveMoreThanOne(false);
    if (handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS")) {
      setHaveMoreThanOne(true);
    } else {
      let checkArr = [];

      handlelUserRoles("ENGAGEMENT_ACCESS", "PUBLISH") &&
        checkArr.push("PUBLISH");

      handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") && checkArr.push("INBOX");

      handlelUserRoles("ENGAGEMENT_ACCESS", "ENGAGEMENT_ANALYTICS") &&
        checkArr.push("ENGAGEMENT_ANALYTICS");

      var activeProducts = JSON.parse(
        window.localStorage.activeProducts || null,
      );
      let aiAgentActive = activeProducts?.find(
        (product) => product?.name == "AI_AGENT",
      );

      handlelUserRoles("AI_AGENT", "VIEW_AI_AGENT_SETTINGS") &&
        handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") &&
        aiAgentActive &&
        checkArr.push("AI_AGENT");
      if (checkArr?.length > 1) {
        setHaveMoreThanOne(true);
      }
    }
  }, []);

  return (
    <Box className="engagement-analytics-header">
      <Box className="engagement-analytics-header-container">
        <Box
          className="engagement-analytics-header-dropdown"
          onClick={() => haveMoreThanOne && setOpenNavigationMenu(true)}
        >
          <NavigationSidebar
            openNavigationMenu={openNavigationMenu}
            navigationMenuStatus={navigationMenuStatus}
            setNavigationMenuStatus={setNavigationMenuStatus}
            notOpened={!haveMoreThanOne}
          />
        </Box>
        <SwitchMenuList
          openNavigationMenu={openNavigationMenu}
          setOpenNavigationMenu={setOpenNavigationMenu}
          navigationMenuStatus={"analytics"}
          setNavigationMenuStatus={setNavigationMenuStatus}
          className="engagement-analytics-header-switch-menu"
        />
        <Box className="engagement-analytics-header-btns">
          <Filter
            {...props}
            filterButtonID="engagement-analytics-filter-btn"
            disabled={false}
            isEngagementAnalytics={true}
            notShowWhatsappDatasource
          />
          <DateRangePicker
            applyFilter={props?.applyFilter}
            startDate={props?.startDate}
            endDate={props?.endDate}
            openCustomDateLeft={true}
            datePickerPosition="insights-datepicker-position"
            isActive={props?.isActive}
            from="channelAnalytics"
            id="channel-analytics-insights-date-picker-btn"
            monitorActiveTabId="channel-analytics-insights-date-range-picker"
            filterFields={props?.filterFields}
            getSelectedDate={props?.getSelectedDate}
          />
        </Box>
      </Box>
    </Box>
  );
};
