import Services from "./Services";

var Login = {};

Login.userLogin = (queryData) => {
  return Services.postData(
    `api/v5/auth/user/login`,
    queryData,
    undefined,
    undefined,
    { platform: "web" },
    true,
  );
};

Login.userlogout = (queryData) => {
  return Services.deleteData(
    "api/v5/auth/user/logout",
    queryData,
    undefined,
    undefined,
    { platform: "web" },
  );
};

Login.userForgetPassword = (queryData) => {
  return Services.getData("api/v3/auth/user/user_forget_password", queryData);
};

Login.userResetPassword = (queryData) => {
  return Services.putData("api/v3/auth/user/user_reset_password", queryData);
};
Login.activateSignUp = (queryData) => {
  return Services.postData(
    "api/v3/company/user_managements/activate_sign_up",
    queryData,
  );
};
//login hubspot
Login.userLoginHubspot = (queryData) => {
  return Services.postData("api/v3/auth/user/hubspot/login", queryData);
};

Login.refreshToken = (queryData) => {
  return Services.postData(
    "api/v5/auth/user/refresh_token",
    queryData,
    undefined,
    undefined,
    undefined,
    true,
  );
};
export default Login;
