import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, getSocialIcon } from "utils/helpers";
import { useState } from "react";
import "./advancedPermissions.scss";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DefaultUser from "images/shared-images/user-management-default.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import Tooltip from "@mui/material/Tooltip";
import { typeFormatMonitor } from "../../addNewUser/sharedFunction/inviteUserAdapter";

export const AdvancedPermissions = ({ collapseNumber, selectedAccessData }) => {
  const intl = useIntl();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const productNameMapping = {
    ENGAGEMENT_TABS: "ENGAGEMENT_TABS_v2",
    ENGAGEMENT_ACCESS: "ENGAGEMENT_ACCESS_v2",
  };
  const viewTable = (data, isAccount) => {
    return (
      <Box className="view-table-countainer">
        {data?.map((row, index) => {
          return (
            <Box
              key={index}
              className={`view-table-row ${isAccount ? "account" : "engag"}`}
            >
              {row?.map((cell, x) => {
                return (
                  <Box
                    key={x}
                    className={`view-table-cell ${!x ? "first-cell" : cell}`}
                  >
                    {!x ? (
                      isAccount ? (
                        <Box className="view-table-cell-account">
                          <Box className="account-acc-img">
                            <img
                              src={cell?.image_url || DefaultUser}
                              onError={(e) => {
                                e.target.src = DefaultUser;
                              }}
                            />
                            <Box
                              className={`account-acc-data-source ${cell?.data_source}`}
                            >
                              {getSocialIcon(cell?.data_source?.toUpperCase())}
                            </Box>
                          </Box>
                          <Box className="account-acc-data-details">
                            <Box dir="ltr">
                              {cell?.name?.length > 30 ? (
                                <Tooltip
                                  title={cell?.name}
                                  arrow
                                  placement="top"
                                >
                                  {`${cell?.name?.substring(0, 30)}...`}
                                </Tooltip>
                              ) : (
                                cell?.name
                              )}
                            </Box>
                            <Box className="account-acc-data-handle" dir="ltr">
                              {cell?.screen_name?.length > 30 ? (
                                <Tooltip
                                  title={`@${cell?.screen_name}`}
                                  arrow
                                  placement="top"
                                >
                                  {`@${cell?.screen_name?.substring(0, 30)}...`}
                                </Tooltip>
                              ) : (
                                `@${cell?.screen_name}`
                              )}
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        CheckValueLocale(
                          productNameMapping[cell] || cell,
                          "",
                          {},
                          intl,
                        )
                      )
                    ) : (
                      <Box className="checked-cell">
                        <FontAwesomeIcon icon={faCheck} />
                        {CheckValueLocale(
                          productNameMapping[cell] || cell,
                          "",
                          {},
                          intl,
                        )}
                      </Box>
                    )}
                  </Box>
                );
              })}
            </Box>
          );
        })}
      </Box>
    );
  };

  const viewCard = (data) => {
    return (
      <Box className="monitors-acc-cell">
        <Box className="monitors-acc-cell-name" dir="ltr">
          {data?.name?.length > 30 ? (
            <Tooltip title={data?.name} arrow placement="top">
              {`${data?.name?.substring(0, 30)}...`}
            </Tooltip>
          ) : (
            data?.name
          )}
        </Box>
        <Box className="monitors-acc-details">
          <Box className="monitors-acc-dott"></Box>
          <Box className="monitors-acc-type">
            {CheckValueLocale("cxm_type", "", {}, intl)}
          </Box>
          <Box className="monitors-acc-type-name">
            {CheckValueLocale(data?.type, "", {}, intl)}
          </Box>
        </Box>
        <Box className="monitors-acc-details">
          <Box className="monitors-acc-dott"></Box>
          <Box className="monitors-acc-type">
            {CheckValueLocale("cxm_data_source", "", {}, intl)}
          </Box>
          <Box className="monitors-acc-data-source">
            {data?.data_source?.map((item) => (
              <Box key={item} className={item}>
                {getSocialIcon(item?.toUpperCase())}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box className="main-view-user">
      <Box className="main-edit-user-card">
        <Typography className="edit-sec-tit">
          {CheckValueLocale("adv_perm", "", {}, intl)}
        </Typography>
        <Box className="edit-sec-container">
          {selectedAccessData?.engagement_features?.length ? (
            <Box className="sec-container">
              <Typography className="sec-title">
                {CheckValueLocale("engagement_features_v2", "", {}, intl)}
              </Typography>
              {viewTable(selectedAccessData?.engagement_features)}
            </Box>
          ) : null}
          {collapseNumber?.map((item, index) => {
            return (
              <Box className="sec-container" key={index}>
                <Typography className="sec-title space-top">
                  {CheckValueLocale(`${item}_tit`, "", {}, intl)}
                </Typography>
                <Box className="collapse-container">
                  <Accordion
                    expanded={expanded === item}
                    onChange={handleChange(item)}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className="collapse-title">
                        {CheckValueLocale(
                          `${item}_num`,
                          "",
                          { num: selectedAccessData[item]?.length },
                          intl,
                        )}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {item == "accounts_access" ? (
                        viewTable(selectedAccessData?.accounts_access, true)
                      ) : (
                        <Box className="collapse-cards-cont">
                          {selectedAccessData[item]?.map((el) => {
                            let dataObj = {
                              name: el?.name,
                              type:
                                item == "monitors_access"
                                  ? typeFormatMonitor(el?.monitor_type_name)
                                  : el?.is_dm
                                    ? "dm_cxm"
                                    : "public_cxm",
                              data_source: el?.data_sources,
                            };
                            return viewCard(dataObj);
                          })}
                        </Box>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
