import { useQuery } from "@tanstack/react-query";
import { MainMonitorsPage } from "services/api/mainMonitorsPage";

export const useGetCredentials = (
  monitorDataSource,
  monitorId,
  productId,
  sectionName,
  pageNumber,
  tweetId,
  accountUserId,
) => {
  return useQuery({
    queryKey: ["authorProfileData"],
    queryFn: () =>
      MainMonitorsPage.getAuthorProfileData(monitorDataSource, monitorId, {
        product_id: productId,
        section_name: sectionName,
        page_number: pageNumber,
        tweet_id: tweetId,
        account_user_id: accountUserId,
      }),

    select: (data) => data?.data,
  });
};
