import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  Tooltip,
  FormHelperText,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import "./page-view.scss";
import CircularLoading from "components/circularProgress";
import { CheckValueLocale, getTimeZone } from "utils/helpers";
//components
import TweetOverView from "./components/TweetOverview";
import AudienceAnalysis from "./components/AudienceAnalysis";
import VolumeOverTime from "./components/oldVolumeOvertime";
import EngagersAnalysis from "./components/EngagersAnalysis";
import EngagersList from "./components/EngagersList";
import CommnetsSentimentAnalysis from "./components/CommnetsSentimentAnalysis";
import PieChartsTweet from "./components/PieChartsTweet";
import SentimentOfReplies from "./components/SentimentOfReplies";
import RepliesList from "./components/RepliesList";
import SingleTweetController from "services/controllers/SingleTweetController";
import Snackbar from "components/snackBar";
import ExportBtn from "pages/Monitors/Components/exportButton/exportButton";
import PopupModal from "components/popupModal";
import CompaniesController from "services/controllers/companiesController";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateRangePicker from "shared/dateRangePicker/dateRangePicker";
import { LoadingButton } from "@mui/lab";
import NotFoundNotification from "pages/alerts/components/notFound/notFoundNotification";
import SharedExportButton from "shared/exportButton/sharedExportButton";

const SingleTweetAnalysis = () => {
  const intl = useIntl();
  const urlParams = useParams();
  const { id, monitor_id, screen_name } = urlParams;
  const [loading, setLoading] = useState(true);
  const [getSingleTweets, setGetSingleTweets] = useState();
  const [alertMsgUpdated, setAlertMsgUpdated] = useState("");
  const [showAlertUpdated, setShowAlertUpdated] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [exportAlertTitle, setExportAlertTitle] = useState("");
  const [startDate, setStartDate] = useState(
    moment()?.subtract(6, "d")?.startOf("day")?.unix(),
  );
  const [endDate, setEndDate] = useState(moment()?.unix());
  const timeZone = getTimeZone();
  const dropdownLabel = ["language"];
  /*---------------------------------------------------------*/
  /* 
 - limit --> social_remaining_quota
 - used --> quota_consumer
 - type --> 1 [Single Analytics] , 2 [Data insights] 
 */
  const [postsUsedLimit, setPostsUsedLimit] = useState({
    used: 0,
    limit: 0,
    type: 0,
  });
  const [stats, setStats] = useState({
    anchorEl: null,
    openCreateModal: false,
  });
  const [quotaCount, setQuotaCount] = useState("");
  const [updateAnalysisError, setUpdateAnalysisError] = useState(false);
  const [formFieldErrors, setFormFieldErrors] = useState({
    quotaLimit: "",
  });
  const [singleTweetTimeZone, setSingleTweetTimeZone] = useState("");
  const [singleTweetLoading, setSingleTweetLoading] = useState(false);
  const [enterFirstTime, setEnterFirstTime] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isDataPPTLoading, setIsDataPPTLoading] = useState(false);

  let singleTweetCreateTime = moment(
    getSingleTweets?.single_tweet?.created_at,
  )?.format("DD MMM, YY");
  /*---------------------------------------------------------*/

  useEffect(() => {
    setLoading(true);
    SingleTweetController.getAnalysis(id, monitor_id).then((result) => {
      if (result?.status === 404) setIsDeleted(true);
      if (result !== undefined) {
        if (result?.data?.data) {
          setGetSingleTweets(result?.data?.data);
          setIsUpdated(result?.data?.data?.is_update);
          setSingleTweetTimeZone(
            result?.data?.data?.single_tweet?.timestamp_ms,
          );
        }
      }
      setLoading(false);
    });
  }, []);
  const typeValues = ["ppt"];

  const updateAnalysis = () => {
    SingleTweetController.createAnalysisSingleTweet(
      monitor_id,
      id,
      screen_name,
      true,
      parseInt(quotaCount),
      startDate,
      endDate,
    ).then((response) => {
      if (response?.data?.data) {
        setStats({ ...stats, openCreateModal: false });
        const estimated_time =
          response?.data?.data?.estimated_time > 60 ? "hours" : "minutes";
        setAlertMsgUpdated("update_analysis_success_" + estimated_time);
        setIsUpdated(true);
        setShowAlertUpdated(true);
      } else {
        setStats({ ...stats, openCreateModal: false });
        setAlertMsgUpdated("failed_error");
        setShowAlertUpdated(true);
      }
    });
  };
  const handlePPTExport = ({ language, handleClose }) => {
    setIsDataPPTLoading(true);
    SingleTweetController.getExportPPT(
      id,
      monitor_id,
      language,
      localStorage?.sm_id,
      true,
    ).then((result) => {
      if (result?.data?.status === 200) {
        setAlertMsgUpdated("ppt_success");
        setExportAlertTitle("ppt_title");
        setIsUpdated(true);
        setShowAlertUpdated(true);
      } else {
        setAlertMsgUpdated("try_again");
        setExportAlertTitle("failed_error");
        setShowAlertUpdated(true);
      }
      setIsDataPPTLoading(false);
      handleClose();
    });
  };

  /*---------------------------------------------------------*/
  const getCompanySingleTweetsQuota = (
    tweetID,
    monitorID,
    productID,
    tweetTime,
    screenName,
    startDate,
    endDate,
  ) => {
    if (!enterFirstTime) setSingleTweetLoading(true);
    CompaniesController.getCompanySingleTweetsQuota(
      tweetID,
      monitorID,
      productID,
      tweetTime,
      screenName,
      startDate,
      endDate,
    ).then((result) => {
      var smQuota = result?.data;

      setPostsUsedLimit({
        limit: smQuota?.data?.social_remaining_quota,
        used: smQuota?.data?.quota_consumer,
        type: 1,
      });
      setEnterFirstTime(false);
      setSingleTweetLoading(false);
    });
  };

  /*---------------------------------------------------------*/
  const inputValidator = (inputValue) => {
    const value = /^\d*\d?((10)|(0))\.?((0)|(00))?$/;
    return value?.test(inputValue);
  };

  const handleOpenCreateModal = () => {
    setStats({
      ...stats,
      openCreateModal: true,
      modalTitle: `${CheckValueLocale("single_tweet_analysis", "", {}, intl)}`,
      anchorEl: null,
    });
  };
  const handleCloseCreateModal = () => {
    setStats({ ...stats, openCreateModal: false });
    setQuotaCount("");

    setFormFieldErrors({
      quotaLimit: "",
    });
  };

  const applyFilter = (startDate, endDate) => {
    let product_id = window?.localStorage?.sm_id;
    setStartDate(startDate);
    setEndDate(endDate);
    getCompanySingleTweetsQuota(
      id,
      monitor_id,
      product_id,
      singleTweetTimeZone,
      screen_name,
      startDate,
      endDate,
    );
  };

  const handlequotaCount = (e) => {
    let value = e?.target?.value;
    setQuotaCount(value);
    let updateAnalysisErrorMsg = "";

    setUpdateAnalysisError(false);

    if (Number(value) === 0) {
      updateAnalysisErrorMsg = "deep_insight_ten_multiple";
    } else if (postsUsedLimit?.limit < value && postsUsedLimit?.type === 2) {
      updateAnalysisErrorMsg = "quota_out";
    } else if (!inputValidator(value)) {
      updateAnalysisErrorMsg = "deep_insight_ten_multiple";
    } else if (postsUsedLimit?.limit === 0 && postsUsedLimit?.type === 2) {
      updateAnalysisErrorMsg = "deep_insight_limit_zero";
    } else if (value > 200 && postsUsedLimit?.type === 2) {
      updateAnalysisErrorMsg = "deep_insight_over_200";
    }

    setFormFieldErrors({
      ...formFieldErrors,
      quotaLimit: updateAnalysisErrorMsg,
    });
  };

  const modalBody = (
    <Box className="deep-insights-modal">
      <Box className="deep-posts-limit">
        <Box component="span">
          {CheckValueLocale("single_tweet_popup_desc", "", {}, intl)}
        </Box>
      </Box>

      <Box className="deep-posts-remainig">
        <Box component="span">
          {CheckValueLocale(
            "deep_insights_remainig_post",
            "",
            {
              remaining: (
                <Box
                  component="span"
                  className={
                    singleTweetLoading ? "deep-number-loader" : "deep-number"
                  }
                >
                  {postsUsedLimit?.limit?.toLocaleString() ?? 0}
                </Box>
              ),
            },
            intl,
          )}
          <Tooltip
            title={
              <Box className="deep-insight-tooltip">
                {CheckValueLocale("deep_tweet_tooltip_quota", "", {}, intl)}
              </Box>
            }
            arrow
            placement={window?.localStorage?.lang !== "en" ? "left" : "right"}
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  padding: "10px",
                  width: 250,
                  lineHeight: 1.5,
                },
              },
            }}
          >
            <Box component="span">
              <FontAwesomeIcon icon={faExclamationCircle} />
            </Box>
          </Tooltip>
        </Box>
      </Box>

      <TextField
        variant="outlined"
        placeholder={CheckValueLocale("EX:30", "", {}, intl)}
        onChange={handlequotaCount}
        className="single-tweet-input"
        value={quotaCount}
        disabled={singleTweetLoading}
        type="number"
        min="10"
        error={
          formFieldErrors?.quotaLimit || updateAnalysisError ? true : false
        }
      />

      {formFieldErrors?.quotaLimit || updateAnalysisError ? (
        <FormHelperText error className="deep-insight-validation">
          {CheckValueLocale(
            updateAnalysisError
              ? "custom_domain_validation"
              : formFieldErrors?.quotaLimit,
            "",
            {},
            intl,
          )}
        </FormHelperText>
      ) : (
        <FormHelperText className="deep-insight-validation" />
      )}

      <Box className="text-field create-historical-calendar">
        <InputLabel className="create-historical-label">
          {CheckValueLocale("dates_range", "", {}, intl)}
        </InputLabel>
        <Box className="post-publish-date">
          {CheckValueLocale("post_publish_date", "", {}, intl)}
          <div className="single-date">{singleTweetCreateTime}</div>
        </Box>
        <DateRangePicker
          disablePast
          startDate={startDate}
          endDate={endDate}
          oldestDate={moment?.unix(1142899200)?.format("YYYY/MM/DD")} // this number required to not allow user to get data less than 2006
          openCustomDateLeft={true}
          isActive={!singleTweetLoading}
          applyFilter={applyFilter}
          datePickerPosition="create-historical-calendar-datepicker-position"
          className="single-tweet-input"
          id="date-range-picker-single-tweet-notification-center"
        />
      </Box>

      {singleTweetLoading ? (
        <Box className="single-tweet-quota">
          <CircularProgress />
        </Box>
      ) : (
        <Box className="single-tweet-note">
          <FormattedMessage
            id="single_tweet_qouta"
            values={{
              tweetNum: (
                <strong>{postsUsedLimit?.used?.toLocaleString() ?? 0}</strong>
              ),
            }}
          />
        </Box>
      )}

      <Box className="step-footer deep-insight">
        <Button
          variant="contained"
          onClick={() => handleCloseCreateModal()}
          className="cancel-btn"
          id="cancel-btn-single-tweet-notification-center"
        >
          {CheckValueLocale("cancel", "", {}, intl)}
        </Button>

        {!singleTweetLoading ? (
          <Button
            variant="contained"
            onClick={() => updateAnalysis()}
            className="next-btn"
            id="request-btn-single-tweet-notification-center"
            disabled={
              quotaCount <= 0 ||
              (postsUsedLimit?.type === 2 &&
                (quotaCount === "" || quotaCount < 10)) ||
              formFieldErrors?.quotaLimit !== "" ||
              postsUsedLimit?.limit <= 0
            }
          >
            {CheckValueLocale("request", "", {}, intl)}
          </Button>
        ) : (
          <LoadingButton
            loading={true}
            disabled={true}
            loadingPosition="center"
            className="single-tweet-loader-btn"
            disableRipple
          ></LoadingButton>
        )}
      </Box>
    </Box>
  );
  /*---------------------------------------------------------*/

  return (
    <Container maxWidth="xl">
      {loading ? (
        <CircularLoading />
      ) : isDeleted ? (
        <NotFoundNotification />
      ) : (
        <Box mt={5} mb={5} className="single-tweet-analysis-box alert-ppt">
          {showAlertUpdated ? (
            <Snackbar
              open={showAlertUpdated ? true : false}
              handleClose={() => {
                setShowAlertUpdated(false);
              }}
              severity={isUpdated ? "success" : "error"}
              title={
                exportAlertTitle
                  ? CheckValueLocale(exportAlertTitle, "", {}, intl)
                  : ""
              }
              message={
                isUpdated ? (
                  CheckValueLocale(alertMsgUpdated, "", {}, intl)
                ) : (
                  <div>
                    <span
                      style={
                        exportAlertTitle === ""
                          ? {
                              fontWeight: "502",
                              fontSize: "18px",
                            }
                          : null
                      }
                    >
                      {alertMsgUpdated
                        ? CheckValueLocale(alertMsgUpdated, "", {}, intl)
                        : ""}
                    </span>
                  </div>
                )
              }
            />
          ) : (
            ""
          )}
          <PopupModal
            title={stats?.modalTitle}
            body={modalBody}
            open={stats?.openCreateModal}
            close={handleCloseCreateModal}
            warning="warning"
            classeName="create-historical-popup"
          />

          <Box className="header-box">
            <Typography variant="h6" className="header-title">
              {CheckValueLocale("single_tweet_analysis", "", {}, intl)}
              <Button
                variant="contained"
                className="beta-btn"
                id="notification-single-tweet-analysis-beta-btn"
              >
                {CheckValueLocale("beta", "", {}, intl)}
              </Button>
            </Typography>
            <Box className="header-left">
              {/* hide it as backend not ready yet */}
              {/* <Button variant="outlined" className="export-btn">
                {intl.formatMessage({ id: "export" })}
              </Button> */}

              <SharedExportButton
                hideExcelExport
                defaultExportType="ppt"
                exportMenuId="export-single-tweet-menu"
                handlePPTExport={handlePPTExport}
                isDataLoading={isDataPPTLoading}
              />
              <Button
                variant="contained"
                className="update-analysis-btn"
                startIcon={<UpdateIcon />}
                onClick={handleOpenCreateModal}
                disabled={isUpdated}
                id="notification-single-tweet-analysis-update-btn"
              >
                {CheckValueLocale("update_analysis", "", {}, intl)}
              </Button>
            </Box>
          </Box>
          <Typography variant="h6" className="time-text">
            {intl.formatMessage(
              { id: "last_update_time" },
              {
                fromnow: moment(getSingleTweets?.analysis_updated_at)?.fromNow(
                  true,
                ),
                datetime: moment(getSingleTweets?.analysis_updated_at)?.format(
                  "DD/MM/YYYY, HH:mm A",
                ),
                timeZone: timeZone > 0 ? "+" + timeZone : timeZone,
              },
            )}
          </Typography>
          <TweetOverView
            singleTweet={getSingleTweets?.single_tweet}
            timeZone={timeZone}
          />
          <Box className="audience-analysis-box">
            <Typography variant="h6" className="audience-analysis-box-title">
              {CheckValueLocale("audience_analysis", "", {}, intl)}
            </Typography>
            <AudienceAnalysis audienceAnalysis={getSingleTweets} />

            <VolumeOverTime
              cumulative_over_time={getSingleTweets?.cumulative_over_time}
              engagers_over_time={getSingleTweets?.engagers_over_time}
              interaction_over_time={getSingleTweets?.interaction_over_time}
              noncumulative_over_time={getSingleTweets?.noncumulative_over_time}
              tweet_datetime={getSingleTweets?.single_tweet?.created_at}
              analysis_created_at={getSingleTweets?.analysis_updated_at}
              intl={intl}
            />
            <EngagersAnalysis engagersAnalysis={getSingleTweets} />

            <Grid container spacing={2.5} sx={{ mt: "0px" }}>
              <EngagersList
                engagerListTitle={"top_engagers"}
                users={getSingleTweets?.all_engagers}
              />
              <EngagersList
                engagerListTitle={"verified_engagers"}
                users={getSingleTweets?.verified_engagers}
              />
            </Grid>
            <Grid container spacing={2.5} sx={{ mt: "0px" }}>
              <CommnetsSentimentAnalysis
                overallSentiment_label={
                  getSingleTweets?.AI_Stats?.overallSentiment_label
                }
              />
              <PieChartsTweet
                AI_Stats={getSingleTweets?.AI_Stats}
                AI_Stats_retweeters={getSingleTweets?.AI_Stats_retweeters}
                location_stats={getSingleTweets?.location_stats}
                location_stats_retweeters={
                  getSingleTweets?.location_stats_retweeters
                }
              />
            </Grid>

            <SentimentOfReplies
              tweet_datetime={getSingleTweets?.single_tweet?.created_at}
              analysis_created_at={getSingleTweets?.analysis_updated_at}
              top_replies={getSingleTweets?.top_replies}
              intl={intl}
            />
            <RepliesList
              topReplies={getSingleTweets?.top_replies}
              unSortedTopReplies={getSingleTweets?.top_replies?.slice()}
              timeZone={timeZone}
            />
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default SingleTweetAnalysis;
