import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Box, Tooltip, Divider, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import Filter from "../../../shared/reduxComponents/filters/channel-analytics/filter";
import DateRangePicker from "../../../shared/reduxComponents/dateRangePicker/dateRangePicker.js";
import BreadCrumb from "components/breadCrumb.js";
import PageHeader from "components/pageHeader.js";
import {
  handlelUserRoles,
  CheckValueLocale,
  handleFiltersParamsIds,
  isEmptyArray,
  isEmptyObj,
  getSocialIcon,
} from "utils/helpers/index.js";
import Luci from "pages/luci";
import { useSelector, useDispatch } from "react-redux";
import { useThemesOptions } from "components/sentimentCategoriesThemes/hooks/useThemesOptions";
import { reduxThemesOptions } from "utils/redux/features/ChannelAnalytics/channelAnalyticsSlice";
import HelpIcon from "@mui/icons-material/Help";
import { getSpecificChartsIcons } from "./insightsCommonFunctions";
import SharedExportButton from "shared/exportButton/sharedExportButton";
import moment from "moment";
import InsightsController from "services/controllers/insightsController";
import { useParams } from "react-router-dom";
import SnackBar from "components/snackBar";

const InsightsPageHeader = (props) => {
  const {
    assignActiveFilters,
    startDate,
    endDate,
    sectionName,
    dataSourceId,
    luciFeature,
    overAllChannels,
    isInteractionsPage,
    title,
    dataLoaded,
    dataEmpty,
    filterFields,
    showExportBtn,
    isExportLoaded,
    excelSheetData,
    monitorDataSource,
    datasourceName,
    datasourcePath,
    preLoaderTrackerDataSources,
    isAggregatePageCA,
  } = props;
  const intl = useIntl();
  const dispatch = useDispatch();

  let { companyCanCreateChannel } = props;
  const [isHovered, setIsHovered] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false); // to show snackbar
  const [snackBarData, setSnackBarData] = useState({
    // snackbar data
    message: "",
    severity: "",
    title: "",
  });
  const [isDataPPTLoading, setIsDataPPTLoading] = useState(false);
  const urlParams = useParams();
  const dataSourceIdCA = urlParams?.data_source_id;
  /* Start Redux Declarations */
  const reduxFilterParams = useSelector(
    (state) => state?.channelAnalytics?.filterParams,
  );
  const reduxFilterDropDownParams = useSelector(
    (state) => state?.channelAnalytics?.filterDropDownParams,
  );
  const reduxFilterDropDownParamsToDisplay = useSelector(
    (state) => state?.channelAnalytics?.filterDropDown,
  );
  const reduxThemesList = useSelector(
    (state) => state?.channelAnalytics?.themesOptions,
  );

  const reduxFilterStartDateUxCA = useSelector(
    (state) => state?.channelAnalytics?.startDateUnix,
  );

  const reduxFilterEndDateUxCA = useSelector(
    (state) => state?.channelAnalytics?.endDateUnix,
  );
  /* End Redux Declarations */

  const { data: themesOptions } = useThemesOptions({ locale: intl?.locale });

  const savedFileName = `${datasourceName}-${
    reduxFilterStartDateUxCA && reduxFilterEndDateUxCA
      ? `${moment.unix(reduxFilterStartDateUxCA).utc().format("DD/MM/YYYY")} - ${moment
          .unix(reduxFilterEndDateUxCA)
          .utc()
          .format("DD/MM/YYYY")}`
      : ""
  }`;

  useEffect(() => {
    if (themesOptions) {
      dispatch(reduxThemesOptions([...themesOptions]));
    }
  }, [themesOptions]);

  let pagesTitlesArray = [
    "interactions",
    "analytics_title",
    "Social Media",
    "chats",
  ];

  let pagesWithBreadCrumb = !pagesTitlesArray?.includes(props?.title);

  useEffect(() => {
    if (filterFields?.length !== 0) {
      props?.applyFilter(
        startDate,
        endDate,
        reduxFilterDropDownParamsToDisplay,
        undefined,
        undefined,
        true,
      );
    }
  }, [filterFields]);

  const hasParentId =
    filterFields?.parent_id !== undefined || filterFields?.parent_id !== null;

  // inApplicable filter tooltip
  const obj = {
    ...filterFields?.by_interactions,
    ...filterFields?.by_user,
    parent_id: hasParentId ? filterFields?.parent_id : undefined,
  };

  let inApplicableFilters = {};

  let finalArr = { ...obj };
  let dmListArr = [];

  obj?.dm_list?.map((dmList) => {
    dmListArr.push(`${dmList?.id}-${dmList?.name}`);
  });

  finalArr = { ...finalArr, dm_list: dmListArr };

  let monitorListArr = [];

  obj?.monitor_list?.map((monitorList) => {
    monitorListArr?.push(`${monitorList?.id}-${monitorList?.name}`);
  });

  finalArr = { ...finalArr, monitor_list: monitorListArr };

  let waitingListArr = [];

  obj?.waiting_filter?.map((waitingList) => {
    waitingListArr?.push(`${waitingList?.name}`);
  });
  const withCommentsArr =
    monitorDataSource === "GMB" ||
    (monitorDataSource === "GMB" && datasourcePath === "interactions/GMB")
      ? { with_comment: [] }
      : {};

  // const isTwitterDataSource = ["twitter_public", "analytics"].includes(
  //   monitorDataSource,
  // );

  const isTwitterDataSource = monitorDataSource === "twitter_public";

  const withParentId = isTwitterDataSource ? { parent_id: [] } : {};

  finalArr = {
    ...finalArr,
    waiting_filter: waitingListArr,
    ...withCommentsArr,
    ...withParentId,
  };

  Object.keys(reduxFilterDropDownParams)?.forEach((key) => {
    if (
      Array.isArray(reduxFilterDropDownParams[key]) &&
      Array.isArray(finalArr[key])
    ) {
      let differentValues;

      if (key === "waiting_filter") {
        differentValues = reduxFilterDropDownParams[key]?.filter(
          (value) =>
            !finalArr[key].some((otherValue) =>
              value?.toString()?.includes("more than 30 mins") ||
              value?.toString()?.includes("More than 30 mins")
                ? value?.toString()?.toLowerCase() ===
                  otherValue?.toString()?.toLowerCase()
                : value?.toString()?.toLowerCase() ===
                  otherValue
                    ?.slice(otherValue.indexOf("("))
                    .toString()
                    ?.toLowerCase(),
            ),
        );
      } else if (key === "with_comment") {
        // don't consider with_comment for inapplicable filters
        differentValues = [];
      } else {
        differentValues = reduxFilterDropDownParams[key]?.filter(
          (value) =>
            !finalArr[key].some((otherValue) =>
              key === "sub_themes_filter"
                ? value?.toString() === otherValue?.value?.toString()
                : value?.toString()?.toLowerCase() ===
                  otherValue?.toString()?.toLowerCase(),
            ),
        );
      }
      inApplicableFilters[key] = differentValues;
    } else if (key === "parent_id") {
      if (!isTwitterDataSource) {
        const value = reduxFilterDropDownParams[key];
        inApplicableFilters[key] = !!value ? [value] : [];
      } else {
        inApplicableFilters[key] = [];
      }
    } else {
      inApplicableFilters[key] = reduxFilterDropDownParams[key];
    }
  });

  let inApplicableFiltersArray = Object?.entries(inApplicableFilters)?.map(
    (key) => {
      return {
        name: key[0],
        value: Array.isArray(key[1]) ? key[1] : [key[1]],
      };
    },
  );

  let toolTipText = !isEmptyArray(inApplicableFiltersArray)
    ? inApplicableFiltersArray?.map((item, index) => {
        return (
          !isEmptyArray(item?.value) && (
            <li key={index}>
              {`${CheckValueLocale(item?.name, "", {}, intl)}: ${
                Array.isArray(item?.value)
                  ? item?.name === "sub_themes_filter"
                    ? reduxThemesList
                        ?.filter((theme) =>
                          item?.value?.includes(
                            theme?.sub_theme_id?.toString(),
                          ),
                        )
                        ?.map((theme) => theme?.sub_theme_name)
                        ?.join(", ")
                    : CheckValueLocale(item?.value?.join(", "), "", {}, intl)
                  : (item?.value?.replace(",", ", ") ?? null)
              }`}
            </li>
          )
        );
      })
    : null;

  let isEmpty = (a) => Array.isArray(a) && a.every(isEmpty);
  let inApplicableFiltersArrayLength =
    !isEmptyArray(inApplicableFiltersArray) &&
    inApplicableFiltersArray?.map(
      (item) => !isEmpty(item?.value) && item?.name,
    );

  const handleCAPPtExport = ({ language, handleClose }) => {
    setIsDataPPTLoading(true);
    InsightsController.getPublicExportPPT(
      reduxFilterStartDateUxCA,
      reduxFilterEndDateUxCA,
      handleFiltersParamsIds(reduxFilterDropDownParamsToDisplay, filterFields),
      true,
      window.localStorage.cxm_id,
      dataSourceIdCA,
      language,
      true,
      datasourcePath,
    ).then((data) => {
      if (data?.data?.status == 200) {
        setSnackBarData({
          severity: "success",
          message: "ppt_success",
          title: "ppt_title",
        });
      } else {
        setSnackBarData({
          severity: "error",
          message: "wrong_request_parameter",
          title: "try_again",
        });
      }
      setOpenSnackBar(true);
      setIsDataPPTLoading(false);
      handleClose();
    });
  };

  return (
    <div>
      <Luci
        isInactive={
          props.title == "genesys" || props.title == "genesys_interactions"
            ? true
            : false
        }
        isCAPage
        pageParams={{
          filterParams: reduxFilterDropDownParamsToDisplay,
          startDate: startDate,
          endDate: endDate,
          sectionName: sectionName,
          productId: window.localStorage.cxm_id,
          luciFeature: luciFeature,
          dataSourceId: dataSourceId,
          monitorName: title,
          monitorProduct: "channel_analytics",
          isChannelAnalytics: true,
          overAllChannels: overAllChannels,
          channelAnayticsName: isInteractionsPage
            ? "interactions"
            : "analytics",
          dataLoaded: dataLoaded,
          dataEmpty: dataEmpty,
          luciFiltersCA: handleFiltersParamsIds(
            assignActiveFilters,
            filterFields,
          ),
        }}
      />
      <Box className="export-monitor-box-container">
        <Box className="export-monitor-box analytics-header">
          {!isEmptyObj(filterFields) &&
          !isEmpty(inApplicableFiltersArray) &&
          inApplicableFiltersArrayLength?.filter(Boolean)?.length &&
          props?.showFiltersBtn ? (
            <Box className="monitor-inapplicable-filters">
              <Tooltip
                title={
                  <div className="monitor-inapplicable-tooltip">
                    {CheckValueLocale("not_applicable_filters", "", {}, intl)}
                    <ul>{toolTipText}</ul>
                  </div>
                }
                showCheckbox={props?.showCheckbox}
                filterButtonID={`channel-analytics-insights-${
                  props?.title ?? "title"
                }`}
                arrow
                placement="bottom"
              >
                <Typography variant={"h7"}>
                  <HelpIcon />
                  {CheckValueLocale(
                    "inapplicable_filters",
                    "",
                    {
                      num: inApplicableFiltersArrayLength?.filter(Boolean)
                        ?.length,
                    },
                    intl,
                  )}
                </Typography>
              </Tooltip>
            </Box>
          ) : null}

          {props?.showFiltersBtn ? (
            <Filter
              filterFields={props?.filterFields}
              applyFilter={props?.applyFilter}
              startDate={props?.startDate}
              endDate={props?.endDate}
              setFilterParamsMainPage={props?.setFilterParamsMainPage}
              allActiveFilters={props?.allActiveFilters}
              isActive={props?.isActive}
              socialMediaFilter={
                window?.location?.href?.endsWith("/insights/social-media/") ||
                window?.location?.href?.endsWith("/interactions/social-media/")
                  ? true
                  : false
              }
              showCheckbox={props?.showCheckbox}
              id="channel-analytics-insights-filter-btn"
              filterButtonID={`channel-analytics-insights-${
                props?.title ?? "title"
              }-filter-btn`}
              inApplicableFiltersArray={inApplicableFiltersArray}
              isChannelAnalyticsGlobalFilters
              inApplicableFiltersArrayLength={inApplicableFiltersArrayLength}
              themesOptions={themesOptions}
            />
          ) : null}
          <Divider orientation="vertical" />
          {props?.showDatePicker ? (
            <DateRangePicker
              applyFilter={props?.applyFilter}
              startDate={props?.startDate}
              endDate={props?.endDate}
              openCustomDateLeft={true}
              datePickerPosition="insights-datepicker-position"
              isActive={props?.isActive}
              from="channelAnalytics"
              id="channel-analytics-insights-date-picker-btn"
              monitorActiveTabId="channel-analytics-insights-date-range-picker"
              filterFields={props?.filterFields}
              isReduxCA
            />
          ) : null}
        </Box>
      </Box>

      <Box className="channel-analytics-title divider-margin ca-export-header">
        <div className="create-new-channel">
          <div>
            {pagesWithBreadCrumb ? (
              <BreadCrumb links={props?.breadCrumbLinks} />
            ) : null}
          </div>
          <div className="btn-ca-breadcrumb">
            <PageHeader
              title={props?.title}
              withBreadCrumb={pagesWithBreadCrumb}
              boxXS={4}
            />
            {handlelUserRoles("CXM", "CREATE_CHANNEL") &&
            props?.showNewChannelBtn ? (
              <div
                className="create-channel-button"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <Tooltip
                  title={CheckValueLocale(
                    "new_channel_btn_tooltip",
                    "",
                    {},
                    intl,
                  )}
                  placement="bottom"
                  arrow
                  id="manage-channel-btn-tooltip"
                  open={!companyCanCreateChannel && isHovered}
                >
                  <Button
                    startIcon={<Add />}
                    onClick={() => {
                      window.location.href = "/manage-channels/add-channel";
                    }}
                    className={`new__channel__filter ${
                      !companyCanCreateChannel && isHovered
                        ? "new-channel-filter-disabled-hover"
                        : ""
                    }`}
                    disabled={
                      !handlelUserRoles("GENERAL", "VIEW_COMPANY_AUTH") ||
                      !handlelUserRoles("GENERAL", "VIEW_USER_AUTH") ||
                      !companyCanCreateChannel
                    }
                    id="channel-analytics-insights-add-new-channel-btn"
                  >
                    {CheckValueLocale("new_channel_insights", "", {}, intl)}
                  </Button>
                </Tooltip>
              </div>
            ) : null}
          </div>
        </div>
        {showExportBtn ? (
          <Box className="export-btn-CA-container">
            <SharedExportButton
              defaultExportType="ppt"
              isDataLoading={!isExportLoaded || isDataPPTLoading}
              hideExcelExport={
                !JSON.parse(window?.localStorage?.info_attributes)
                  ?.allow_posts_export
              }
              exportButtonId="channel-analytics-export-btn"
              savedFileName={savedFileName}
              excelSheetDataInfo={{
                excelSheetData,
                pageName: "channel_analytics",
                activeTabName: monitorDataSource,
                themesOptions,
                reduxThemesList,
              }}
              handlePPTExport={handleCAPPtExport}
            />
          </Box>
        ) : null}

        {openSnackBar ? (
          <SnackBar
            open={openSnackBar}
            severity={snackBarData?.severity}
            message={CheckValueLocale(snackBarData?.message, "", {}, intl)}
            title={CheckValueLocale(snackBarData?.title, "", {}, intl)}
            handleClose={() => setOpenSnackBar(false)}
          />
        ) : null}
      </Box>
      {/* in aggreagte pages in CA will display icons above */}
      {preLoaderTrackerDataSources?.length && isAggregatePageCA ? (
        <Box className="insights-data-sources-icons">
          {getSpecificChartsIcons(preLoaderTrackerDataSources)?.map((el) => {
            return getSocialIcon(el?.toUpperCase());
          })}
        </Box>
      ) : null}
      <Box className="divider-margin">
        <Divider />
      </Box>
    </div>
  );
};
export default InsightsPageHeader;
