import Services from "services/api/Services";
import NotificationsController from "services/controllers/notificationsController";
import UserAccount from "services/controllers/userAccountController";
import { normalizeHashtag } from "utils/helpers";

// Download notification [ XLSX TYPE ]
const handelxlsxDownload = async (
  id,
  getDownloadableExcel,
  setSnackbar,
  navigate,
  isRedirection,
) => {
  getDownloadableExcel(
    { id }, // Notification ID
    {
      onSuccess: (data) => {
        if (data?.data?.length) {
          data?.data?.forEach((file) => {
            if (!file?.encryption) {
              window?.open(file?.file_body);
            } else if (file) {
              let contentType = "application/vnd.ms-excel";
              let blob1 = b64toBlob(file?.file_body, contentType);
              var a = document.createElement("a");
              a.href = URL.createObjectURL(blob1);
              a.download = file?.file_name + ".xlsx"; // File name + File type
              a.click();
            }
          });

          if (isRedirection) navigate(`/notification`); // If redirected from email sent to export xlsx file
        }
      },
      onError: () => {
        return setSnackbar({
          open: true,
          severity: "error",
          title: "",
          message: "failed_error",
        });
      },
    },
  );
};

const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  let byteCharacters = atob(b64Data);
  let byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);

    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  let blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

// Download Function
const handelDownload = (id, navigate, setOpenSnackBar) => {
  NotificationsController.getNotificationsDownloadLink(id).then((data) => {
    if (data?.data?.file_url) {
      window.open(data?.data.file_url, "_self");
      navigate(`/notification`);
    } else {
      setOpenSnackBar(true);
    }
  });
};

// Notification View Link [ when click on View button it return to view page [ reports / single tweet ] ]
const handelViewLink = (item, navigate, setSnackbar, getDownloadableExcel) => {
  const checkIfLucidyaCustomDomain = item?.custom_domain
    ? `https://${item?.custom_domain}/`
    : "https://survey.lucidya.com/";
  if (item?.encryption == true) {
    return handelxlsxDownload(item?.id, getDownloadableExcel, setSnackbar);
  } else if (item?.notification_type === "single_tweet_analysis") {
    return navigate(
      `/single_post_analysis/${item?.monitor}/${item?.tweet_id}/${item?.screen_name}`,
    );
  } else if (item?.notification_type === "nb_report") {
    return navigate(
      `/reports/newslettersreport_details/${item?.report_id}?report_data_id=${item?.report_data_id}`,
    );
  } else if (item?.notification_type === "excel_export") {
    return handelxlsxDownload(item?.id, getDownloadableExcel, setSnackbar);
  } else if (item?.notification_type === "published_survey") {
    if (item?.survey_type?.toLowerCase() === "embedded_survey") {
      if (item?.survey_link?.length) {
        return window.open(item?.survey_link, "_blank");
      }
    } else {
      if (item?.url_slug?.length) {
        return window.open(
          `${checkIfLucidyaCustomDomain}${item?.url_slug}`,
          "_blank",
        );
      }
    }
  } else if (item?.alert_type_name === "XTopTrends") {
    const normalizedHashtag = normalizeHashtag(item?.trend_keyword);
    return navigate(`/top-trends/view-trends?hashtag=${normalizedHashtag}`);
  } else {
    if (item?.product_name?.toLowerCase() == "engagements") {
      return navigate(
        `/engagements/main/inbox?alert_id=${item?.alert_data_id}`,
      );
    } else {
      return navigate(
        item?.notification_type === "alert"
          ? "/404"
          : item?.notification_type === "alert_v2" &&
              `/alerts/${
                item?.alert_type_name
              }/${item?.data_source_name?.toUpperCase()}/${item?.alert_data_id}`,
      );
    }
  }
};

export { b64toBlob, handelViewLink, handelDownload, handelxlsxDownload };
