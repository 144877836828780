import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { clear } from "utils/redux/features/SocialListening/socialListeningSlice";
import {
  Autocomplete,
  Avatar,
  Box,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { CheckValueLocale, deleteCookie } from "utils/helpers";
import LoginController from "services/controllers/loginController";
import { resetChatMessages } from "utils/redux/features/AiAgentChat/AiAgentChatSlice";
import UserAvatar from "shared/unifiedUserImage/userAvatar";
import { useCompanyList } from "pages/homepage/hooks/useCompanyList";
import { useAssignUserToCompany } from "pages/homepage/hooks/useAssignUserToCompany";

const Header = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const lucUser =
    window.localStorage.getItem("is_lucidya_account") === "1" ||
    localStorage.email?.includes("@lucidya");
  const thirdPartyFlag =
    !lucUser &&
    window.location.hostname === "cxm.lucidya.com" &&
    window.location.pathname !== "/login";

  const name = localStorage.getItem("firstName");
  const userFullName = localStorage.getItem("name");
  const avatar = localStorage.getItem("avatar");
  const [words, setWords] = useState([
    "find_gem_today",
    "ready_shape_smile",
    "dive_into_data_today",
    "crafting_connections_starts",
    "each_click_tells_story",
    "turn_today_insights",
    "empower_decisions_real",
    "make_today_exceeding",
    "join_dots_customer",
    "ready_for_deep_dive",
    "transform_customer_challange",
    "mission_choose_elevate",
    "harnees_power_fuel",
    "customer_satisfaction_art",
    "cx_champion_momets",
    "today_insights_tomorrow",
    "decode_digital_dialog",
    "ready_set_engagee",
    "elevate_counter",
    "engagement_key_unlock",
    "craft_strategies_stories",
    "listen_silent_feedback",
    "today_perfect_customer",
    "gear_analytics_action",
    "touchpoint_leep_towards",
    "spotlight_on_service",
    "customer_journey_steps",
    "make_today_advocates",
    "ready_transform_metrics",
    "step_into_customer_shoes",
    "today_mission_customer",
    "get_ready_hero_today",
    "today_forecast_chance",
    "plant_seeds_service",
    "signle_survey_today",
    "tilor_today_exprience",
    "today_page_customer",
    "back_insights_translate",
    "ready_extra_mile",
    "learning_every_click",
  ]);
  let [currentWord, setCurrentWord] = useState(words[0]);

  // Select company dropdown states
  const isLucidyaAccount = window.localStorage.getItem("is_lucidya_account");
  const company_id = window.localStorage.getItem("company_id");
  const [companyOption, setCompanyOption] = useState({ id: company_id });
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { data: companiesData } = useCompanyList({
    enabled: isLucidyaAccount === "1",
  });
  const companies = companiesData?.data || [];

  function changeWord() {
    const randomIndex = Math.floor(Math.random() * words.length);
    setCurrentWord(words[randomIndex]);
  }

  useEffect(() => {
    function changeWordHandler() {
      changeWord();
    }
    changeWordHandler();
    return () => {};
  }, []);
  let profileImage = name?.charAt(0)?.toUpperCase();

  useEffect(() => {
    dispatch(clear());
  }, []);

  useEffect(() => {
    setCompanyOption(companies?.find((comp) => comp?.id == company_id));
  }, [companies]);

  const handleSignOut = () => {
    let currentLang = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : window.navigator?.language?.split("-")[0];
    LoginController.userlogout().then((data) => {
      localStorage.clear();
      dispatch(resetChatMessages());
      deleteCookie("email");
      window.localStorage.setItem("lang", currentLang);
      //to stop connection with churnzero when user logout________________________
      if (thirdPartyFlag) {
        var churnzeroScript = document.getElementById(
          "churnzero-script-logout",
        );
        churnzeroScript.innerHTML = `ChurnZero.push(["trackEvent", "Logout"]);
      ChurnZero.push(["stop"]);`;
      }
      //__________________________________________________________________________
      window.location.href = "/login";
    });
  };

  const { mutate: assignUserToCompanyAction } = useAssignUserToCompany();

  const assignUserToCompany = (company_id) => {
    const queryData = {
      company_id,
    };
    assignUserToCompanyAction(queryData, {
      onSuccess: () => {
        window.localStorage.setItem("company_id", company_id);
        handleSignOut();
      },
    });
  };

  const handleChangeCompany = (e, value) => {
    if (value) {
      setCompanyOption(value);
      assignUserToCompany(value?.id);
    }
  };

  return (
    <Box className="header">
      <Box className="welcome-box">
        <Link to="/profile">
          {name || avatar ? (
            <UserAvatar
              fullName={userFullName}
              avatarSrc={avatar}
              sizes={{ ratio: "70px", fontSize: 24 }}
            />
          ) : (
            <Box className="user-info-avatar-placeholder"></Box>
          )}
        </Link>
        <Box className="user-content">
          <Typography component={"h1"}>
            {CheckValueLocale("welcome_back", "", {}, intl)} {" " + name}
          </Typography>

          <Typography>{CheckValueLocale(currentWord, "", {}, intl)}</Typography>
        </Box>
      </Box>

      {isLucidyaAccount === "1" ? (
        <Box className="company-select-box">
          <FormControl className="select-company">
            <Autocomplete
              id="controlled-company"
              className="company-dropdown"
              labelId="controlled-company-select-label"
              disableClearable
              autoComplete
              autoHighlight
              autoSelect={false}
              open={open}
              options={companies}
              onClose={handleClose}
              onOpen={handleOpen}
              getOptionLabel={(company) =>
                `${company?.id} - ${company?.attributes?.name}`
              }
              value={companyOption}
              onChange={handleChangeCompany}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={CheckValueLocale("company", "", {}, intl)}
                />
              )}
            />
          </FormControl>
        </Box>
      ) : null}
    </Box>
  );
};

export default Header;
