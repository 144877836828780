import translations from "utils/locale";

//monitor pages
import { account_fb_AA } from "./accountAnalysisMonitors/account-fb-AA";
import { account_ig_AA } from "./accountAnalysisMonitors/account-ig-AA";
import { account_tw_AA } from "./accountAnalysisMonitors/account-tw-AA";
import { engagements_fb_AA } from "./accountAnalysisMonitors/engagements-fb-AA";
import { engagements_ig_AA } from "./accountAnalysisMonitors/engagements-ig-AA";
import { engagements_tw_AA } from "./accountAnalysisMonitors/engagements-tw-AA";
import { engagements_fb_Keywords } from "./keywordsMonitors/engagements-fb-Keywords";
import { engagements_ig_Keywords } from "./keywordsMonitors/engagements-ig-Keywords";
import { engagements_nb_Keywords } from "./keywordsMonitors/engagements-nb-keywords";
import { engagements_tw_Keywords } from "./keywordsMonitors/engagements-tw-Keywords";
import { account_fb_MP } from "./managedMonitors/account-fb-MP";
import { account_ig_MP } from "./managedMonitors/account-ig-MP";
import { engagements_fb_MP } from "./managedMonitors/engagements-fb-MP";
import { engagements_ig_MP } from "./managedMonitors/engagements-ig-MP";

// engagements
import { engagementAnalyticsInbox } from "./enagagementAnalytics/inbox";
import { engagementAnalyticsSLAs } from "./enagagementAnalytics/slas";
import { engagementAnalyticsAgents } from "./enagagementAnalytics/agents";
import { engagementAnalyticsTeams } from "./enagagementAnalytics/teams";
import { engagementAnalyticsInsights } from "./enagagementAnalytics/insights";

//Channel Analytics pages
import { ig_CA_public } from "./channelAnayticsPages/instagramPublic/ig_CA_public";
import { fb_public } from "./channelAnayticsPages/facebookPublic/facebook-public";
import { fb_private } from "./channelAnayticsPages/facebookPrivate/facebook-private";
import { genesys_public } from "./channelAnayticsPages/callsPages/genesysPage/genesys_public";
import { twitter_public } from "./channelAnayticsPages/twitter_public";
import { tiktok_public } from "./channelAnayticsPages/tiktok_public";
import { twitter_private } from "./channelAnayticsPages/twitter_private";
import { linkedIn_public } from "./channelAnayticsPages/linkedInPublic/linkedIn_public";
import { whatsapp } from "./channelAnayticsPages/whatsapp";
import { gmail } from "./channelAnayticsPages/gmail";
import { intercom } from "./channelAnayticsPages/intercom";
import { social_media } from "./channelAnayticsPages/social_media";
import { analytics } from "./channelAnayticsPages/analytics";
import { chat } from "./channelAnayticsPages/chat";
import { googlemybusiness } from "./channelAnayticsPages/googlemybusiness";
import { handleThemesTranslations } from "utils/helpers";

const generateExcelData = (excelSheetDataInfo) => {
  const {
    pageName,
    activeTabName,
    language,
    excelSheetData,
    dataSourceName,
    monitorType,
    themesOptions,
    reduxThemesList,
  } = excelSheetDataInfo;
  var xlsLanguage = translations[language];

  const themesTranslations =
    handleThemesTranslations(
      themesOptions ? themesOptions : reduxThemesList, // To check if themesOptions is passed or not and use redux themesOptions
      language,
    ) || {};

  if (pageName === "channel_analytics") {
    switch (activeTabName) {
      case "instagram_public":
        return ig_CA_public(excelSheetData, xlsLanguage, themesTranslations);
      case "twitter_public":
        return twitter_public(excelSheetData, xlsLanguage, themesTranslations);
      case "twitter_private":
        return twitter_private(excelSheetData, xlsLanguage, themesTranslations);
      case "tiktok_public":
        return tiktok_public(excelSheetData, xlsLanguage, themesTranslations);
      case "facebook_public":
        return fb_public(excelSheetData, xlsLanguage, themesTranslations);
      case "facebook_private":
        return fb_private(excelSheetData, xlsLanguage, themesTranslations);
      case "genesys_calls":
        return genesys_public(excelSheetData, xlsLanguage, themesTranslations);
      case "Social_media":
        return social_media(excelSheetData, xlsLanguage, themesTranslations);
      case "linkedin_public":
        return linkedIn_public(excelSheetData, xlsLanguage, themesTranslations);
      case "GMB":
        return googlemybusiness(excelSheetData, xlsLanguage);
      case "whatsapp":
        return whatsapp(excelSheetData, xlsLanguage, themesTranslations);
      case "analytics":
        return analytics(excelSheetData, xlsLanguage, themesTranslations);
      case "chats":
        return chat(excelSheetData, xlsLanguage, themesTranslations);
      case "gmail":
        return gmail(excelSheetData, xlsLanguage, themesTranslations);
      case "intercom":
        return intercom(excelSheetData, xlsLanguage, themesTranslations);
    }
  } else if (pageName === "engagement_analytics") {
    switch (activeTabName) {
      case "inbox":
        return engagementAnalyticsInbox(excelSheetData, xlsLanguage);
      case "slas":
        return engagementAnalyticsSLAs(excelSheetData, xlsLanguage);
      case "agents":
        return engagementAnalyticsAgents(excelSheetData, xlsLanguage);
      case "teams":
        return engagementAnalyticsTeams(excelSheetData, xlsLanguage);
      case "Insights":
        return engagementAnalyticsInsights(excelSheetData, xlsLanguage);
    }
  } else if (pageName === "social_listening") {
    if (
      monitorType === "ACCOUNT_ANALYSIS" &&
      dataSourceName === "TWITTER" &&
      activeTabName === "account"
    ) {
      return account_tw_AA(excelSheetData, xlsLanguage);
    }
    if (
      monitorType === "ACCOUNT_ANALYSIS" &&
      dataSourceName === "TWITTER" &&
      activeTabName === "engagements"
    ) {
      return engagements_tw_AA(excelSheetData, xlsLanguage);
    }
    if (
      monitorType === "ACCOUNT_ANALYSIS" &&
      dataSourceName === "INSTAGRAM" &&
      activeTabName === "engagements"
    ) {
      return engagements_ig_AA(excelSheetData, xlsLanguage);
    }
    if (
      monitorType === "ACCOUNT_ANALYSIS" &&
      dataSourceName === "INSTAGRAM" &&
      activeTabName === "account"
    ) {
      return account_ig_AA(excelSheetData, xlsLanguage);
    }
    if (
      monitorType === "ACCOUNT_ANALYSIS" &&
      dataSourceName === "FACEBOOK" &&
      activeTabName === "account"
    ) {
      return account_fb_AA(excelSheetData, xlsLanguage);
    }
    if (
      monitorType === "ACCOUNT_ANALYSIS" &&
      dataSourceName === "FACEBOOK" &&
      activeTabName === "engagements"
    ) {
      return engagements_fb_AA(excelSheetData, xlsLanguage);
    }
    if (
      monitorType === "KEYWORD" &&
      dataSourceName === "TWITTER" &&
      activeTabName === "engagements"
    ) {
      return engagements_tw_Keywords(
        excelSheetData,
        xlsLanguage,
        themesTranslations,
      );
    }
    if (
      monitorType === "KEYWORD" &&
      dataSourceName === "INSTAGRAM" &&
      activeTabName === "engagements"
    ) {
      return engagements_ig_Keywords(
        excelSheetData,
        xlsLanguage,
        themesTranslations,
      );
    }
    if (
      monitorType === "KEYWORD" &&
      dataSourceName === "FACEBOOK" &&
      activeTabName === "engagements"
    ) {
      return engagements_fb_Keywords(
        excelSheetData,
        xlsLanguage,
        themesTranslations,
      );
    }
    if (
      monitorType === "MANAGED_ACCOUNT" &&
      dataSourceName === "INSTAGRAM" &&
      activeTabName === "account"
    ) {
      return account_ig_MP(excelSheetData, xlsLanguage);
    }
    if (
      monitorType === "MANAGED_ACCOUNT" &&
      dataSourceName === "INSTAGRAM" &&
      activeTabName === "engagements"
    ) {
      return engagements_ig_MP(excelSheetData, xlsLanguage);
    }
    if (
      monitorType === "KEYWORD" &&
      dataSourceName === "nb" &&
      activeTabName === "engagements"
    ) {
      return engagements_nb_Keywords(
        excelSheetData,
        xlsLanguage,
        themesTranslations,
      );
    }
    if (
      monitorType === "MANAGED_PAGE" &&
      dataSourceName === "FACEBOOK" &&
      activeTabName === "account"
    ) {
      return account_fb_MP(excelSheetData, xlsLanguage);
    }
    if (
      monitorType === "MANAGED_PAGE" &&
      dataSourceName === "FACEBOOK" &&
      activeTabName === "engagements"
    ) {
      return engagements_fb_MP(excelSheetData, xlsLanguage);
    }
  }
};

export { generateExcelData };
