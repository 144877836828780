import { useEffect } from "react";
import { useIntl } from "react-intl";
import { Container, Box } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import AlertHeader from "./components/header/AlertHeader";
import AlertList from "./components/list/AlertList";
import { CheckValueLocale } from "utils/helpers";
import CircularLoading from "components/circularProgress";
import AlertForm from "./components/alertForm/alertForm";
import AlertsFunctions from "./components/alertsFormFunctions/alertsFunctions";
import SnackBar from "components/snackBar";

const AlertsPage = () => {
  const intl = useIntl();

  const {
    loading,
    page,
    numberOfPages,
    alertTypeNames,
    getAlerts,
    getAlertsTypes,
    selectedTypes,
    alerts,
    handleChangePage,
    openForm,
    setOpenForm,
    openFormType,
    setOpenFormType,
    handleCloseForm,
    handleOpenForm,
    alertName,
    setAlertName,
    formFieldErrors,
    setFormFieldErrors,
    handleInputChange,
    productType,
    setProductType,
    getAlertData,
    alertsInfo,
    datasourceType,
    setDatasourceType,
    alertType,
    setAlertType,
    getFilteredData,
    alertRateType,
    setAlertRateType,
    selectedMonitors,
    setSelectedMonitors,
    handleChangeMultipleSelection,
    monitorsList,
    setMonitorsList,
    getAlertsMonitors,
    recieveAlertsHourly,
    setRecieveAlertsHourly,
    handleRecieveAlertsHourly,
    alertThresholdType,
    setAlertThresholdType,
    handleChangeAlertsFields,
    thresholdManualValue,
    setThresholdManualValue,
    getComanyEmails,
    companyUsers,
    setCompanyUsers,
    selectedEmails,
    setSelectedEmails,
    loginUserData,
    emailsEnabled,
    setEmailsEnabled,
    keywordsFocused,
    setKeywordsFocused,
    alertKeywords,
    setAlertKeywords,
    selectedKeywords,
    setSelectedKeywords,
    handleKeywordChange,
    handleKeywords,
    handleDeleteKeyword,
    checkDisableCreateAlert,
    handelPostUsers,
    postUsers,
    handlePostsUsersChange,
    selectedPostUsers,
    handleDeleteInputField,
    setSelectedPostUsers,
    setPostUsers,
    handleFormSubmit,
    showSnackBar,
    setShowSnackBar,
    showAlertActionMessage,
    resetFormData,
    handleTypeSelection,
    alertTypeLocale,
    getAlertsLoading,
    isManualAlert,
    isHourlyType,
    alertThresold,
    isCreateAlert,
    alertsSources,
    alertsTypes,
    alertRates,
    isNegavtivePostsType,
    showAdavancedKeywords,
    getPostsTypeTitle,
    activeAlertType,
    handleMinimumNoFollowrs,
    minimumNoFollowers,
    isInfluncerType,
    getAlertsUpdateData,
    alertEditData,
    getEditAlertsLoading,
    setMiniumimNoFollowers,
    setPage,
    setShowAlertActionMessage,
    isInstagramDatasource,
    splitDataSourceType,
    filteredAlertsData,
    monitorsLoading,
    handleChangeAlertsMaximum,
    maxmNumberEmailsValue,
    setMaxmNumberEmailsValue,
    alertMaxEmails,
    setAlertMaxEmails,
    maxmNumValue,
    setMaxmNumValue,
    informerMaxEmails,
    setInformerMaxEmails,
    isViralTweetType,
    isInstantType,
    minimumNoInteractions,
    setMiniumimNoInteractions,
    handleMinimumNoInteractions,
    alertWebhookInfo,
    enableWebhooks,
    setEnableWebhooks,
    getApisLoading,
    lucidyaApisList,
    keyLocationList,
    apiKey,
    setApiKey,
    method,
    setMethod,
    url,
    setUrl,
    verificationKey,
    setVerificationKey,
    authVarName,
    setAuthVarName,
    handleAuthVarName,
    authLocation,
    setAuthLocation,
    handleAuthLocationChange,
    testWebhooksResult,
    setTestWebhooksResult,
    testWebhooksLoading,
    handleTestUrl,
    checkDisableTestUrl,
    deleteWebhooksLoading,
    handleDeleteWebhooks,
    handleEnableDisableWebhooks,
    enableWebhooksLoading,
    disableWebhooksLoading,
    handleVerificatinKeyChange,
    isSortableLoading,
    tableColumns,
    alertsConversationTypes,
    alertConversationType,
    setAlertConversationType,
    getAlertMonitorsEngagements,
    selectedSources,
    setSelectedSources,
    sourcesListEngagements,
    sourcesLoadingEngagements,
    handleChangeSourcesEngagements,
    handleSourcesIds,
    fillterSearch,
    setFillterSearch,
    buttonLoading,
    getCountriesList,
    countriesList,
    selectedCountries,
    setSelectedCountries,
    handleXTrendKeywordsSubmit,
    handleXTrendKeywordsChange,
    selectedXTrendKeywords,
    xTrendKeywords,
    handleDeleteXTrendKeyword,
    setSelectedXTrendKeywords,
    setCountriesList,
    setXTrendKeywords,
  } = AlertsFunctions();

  useEffect(() => {
    getAlerts();
    getAlertsTypes();
  }, []);

  const childProps = {
    openForm,
    setOpenForm,
    openFormType,
    setOpenFormType,
    handleCloseForm,
    handleOpenForm,
    alertTypeNames,
    selectedTypes,
    handleTypeSelection,
    alertTypeLocale,
    alertName,
    setAlertName,
    formFieldErrors,
    setFormFieldErrors,
    handleInputChange,
    productType,
    setProductType,
    alertsInfo,
    datasourceType,
    setDatasourceType,
    alertType,
    setAlertType,
    getFilteredData,
    alertRateType,
    setAlertRateType,
    selectedMonitors,
    setSelectedMonitors,
    handleChangeMultipleSelection,
    monitorsList,
    setMonitorsList,
    getAlertsMonitors,
    recieveAlertsHourly,
    setRecieveAlertsHourly,
    handleRecieveAlertsHourly,
    alertThresholdType,
    setAlertThresholdType,
    thresholdManualValue,
    setThresholdManualValue,
    handleChangeAlertsFields,
    getComanyEmails,
    companyUsers,
    setCompanyUsers,
    selectedEmails,
    setSelectedEmails,
    loginUserData,
    emailsEnabled,
    setEmailsEnabled,
    keywordsFocused,
    setKeywordsFocused,
    alertKeywords,
    setAlertKeywords,
    selectedKeywords,
    setSelectedKeywords,
    handleKeywordChange,
    handleKeywords,
    handleDeleteKeyword,
    checkDisableCreateAlert,
    handelPostUsers,
    postUsers,
    handlePostsUsersChange,
    selectedPostUsers,
    handleDeleteInputField,
    setSelectedPostUsers,
    setPostUsers,
    handleFormSubmit,
    resetFormData,
    alerts,
    handleChangePage,
    getAlertData,
    getAlertsLoading,
    isManualAlert,
    isHourlyType,
    showAdavancedKeywords,
    alertThresold,
    isCreateAlert,
    alertsSources,
    alertsTypes,
    alertRates,
    isNegavtivePostsType,
    getPostsTypeTitle,
    activeAlertType,
    handleMinimumNoFollowrs,
    minimumNoFollowers,
    isInfluncerType,
    getAlertsUpdateData,
    alertEditData,
    getEditAlertsLoading,
    setMiniumimNoFollowers,
    page,
    setPage,
    setShowAlertActionMessage,
    setShowSnackBar,
    getAlerts,
    isInstagramDatasource,
    splitDataSourceType,
    filteredAlertsData,
    monitorsLoading,
    handleChangeAlertsMaximum,
    maxmNumberEmailsValue,
    setMaxmNumberEmailsValue,
    alertMaxEmails,
    setAlertMaxEmails,
    maxmNumValue,
    setMaxmNumValue,
    informerMaxEmails,
    setInformerMaxEmails,
    isViralTweetType,
    isInstantType,
    minimumNoInteractions,
    setMiniumimNoInteractions,
    handleMinimumNoInteractions,
    alertWebhookInfo,
    enableWebhooks,
    setEnableWebhooks,
    getApisLoading,
    lucidyaApisList,
    keyLocationList,
    apiKey,
    setApiKey,
    method,
    setMethod,
    url,
    setUrl,
    verificationKey,
    setVerificationKey,
    authVarName,
    setAuthVarName,
    handleAuthVarName,
    authLocation,
    setAuthLocation,
    handleAuthLocationChange,
    testWebhooksResult,
    setTestWebhooksResult,
    testWebhooksLoading,
    handleTestUrl,
    checkDisableTestUrl,
    deleteWebhooksLoading,
    handleDeleteWebhooks,
    handleEnableDisableWebhooks,
    enableWebhooksLoading,
    disableWebhooksLoading,
    handleVerificatinKeyChange,
    alertsConversationTypes,
    alertConversationType,
    setAlertConversationType,
    getAlertMonitorsEngagements,
    selectedSources,
    setSelectedSources,
    sourcesListEngagements,
    sourcesLoadingEngagements,
    handleChangeSourcesEngagements,
    handleSourcesIds,
    fillterSearch,
    setFillterSearch,
    buttonLoading,
    getCountriesList,
    countriesList,
    selectedCountries,
    setSelectedCountries,
    handleXTrendKeywordsSubmit,
    handleXTrendKeywordsChange,
    selectedXTrendKeywords,
    xTrendKeywords,
    handleDeleteXTrendKeyword,
    setSelectedXTrendKeywords,
    setCountriesList,
    setXTrendKeywords,
  };
  return (
    <Container maxWidth="xl">
      {openForm ? <AlertForm {...childProps} /> : null}
      {loading ? (
        <CircularLoading />
      ) : (
        <Box mt={5} mb={5} className={"alerts-countainer"}>
          <AlertHeader {...childProps} />
          <AlertList
            {...childProps}
            tableHead={tableColumns}
            isSortableLoading={isSortableLoading}
          />
          {numberOfPages > 1 && !isSortableLoading ? (
            <Pagination
              onChange={handleChangePage}
              page={page}
              className="pagination-monitor-list alert-pagination-list"
              count={numberOfPages}
              variant="outlined"
            />
          ) : null}
        </Box>
      )}
      <SnackBar
        open={showSnackBar}
        severity={showAlertActionMessage?.severity}
        message={CheckValueLocale(
          showAlertActionMessage?.message,
          "",
          {},
          intl,
        )}
        title={showAlertActionMessage?.title}
        handleClose={() => {
          setShowSnackBar(false);
        }}
      />
    </Container>
  );
};

export default AlertsPage;
