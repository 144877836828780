import React, { useEffect, useState } from "react";
import { MonitorContainer } from "../../../../Components/sidebar/monitor.styles";
import AuthorsBody from "./authorProfileBody";
import { convertXPlatformToTwitter, isEmptyObj } from "utils/helpers";
import { connectWithRabbitMQ } from "pages/channelAnalytics/InsightsPage/insightsCommonFunctions";
import { useParams } from "react-router-dom";
import { useGetCompanyDataQuota } from "pages/Monitors/hooks/useGetCompanyDataQuota";
import { useGetCredentials } from "pages/Monitors/hooks/useGetCredentials";

/*---------------------------------------------------------*/
const AuthorsProfilePage = (props) => {
  const urlParams = useParams();
  const monitorId = urlParams.monitorId;
  const monitorActiveTab = urlParams.activeTab;
  const monitorMonitorDataSource = convertXPlatformToTwitter(
    urlParams.monitorDataSource,
  );
  const monitorTweetId = urlParams.tweetId;
  const monitorUserId = urlParams.userId;

  /*---------------------------------------------------------*/

  const [response, setResponse] = useState([]);
  const mqttConnectionClosed = React.useRef(false);
  const clientMQTT = React.useRef();
  const [preLoaderTrackerDataSources, setPreLoaderTrackerDataSources] =
    useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [resetAllFields, setResetAllFields] = useState(0);
  /*---------------------------------------------------------*/
  const [postsUsedLimit, setPostsUsedLimit] = useState({
    used: 0,
    limit: 0,
  });
  /*---------------------------------------------------------*/
  const [deepInsightsLoading, setDeepInsightsLoading] = useState(false);
  const [enterFirstTime, setEnterFirstTime] = useState(false);
  /*---------------------------------------------------------*/
  //Handle Pagination with [ Posts Page , Comments & Mentions Page ]
  const changePagination = (pageNumber) => {
    setPageNumber(pageNumber);
    setResetAllFields((prev) => prev + 1);
  };

  /*---------------------------------------------------------*/

  const { data: credentialsDataResponse } = useGetCredentials(
    monitorMonitorDataSource,
    monitorId,
    window.localStorage.sm_id,
    monitorActiveTab,
    1,
    monitorTweetId,
    monitorUserId,
  );
  const credentialsData = credentialsDataResponse?.data;
  const analyzeProfile = credentialsData?.analyze_profile_enabled;
  const isProfileAnalyzed = credentialsData?.is_analyzed;

  useEffect(() => {
    if (credentialsData && !isEmptyObj(credentialsData)) {
      setPreLoaderTrackerDataSources([parseInt(monitorId)]);
      connectWithRabbitMQ(
        mqttConnectionClosed,
        clientMQTT,
        credentialsData,
        setResponse,
      );
    } else {
      mqttConnectionClosed.current = true;
    }
  }, [credentialsData]);

  /*---------------------------------------------------------*/
  const { mutate: getCompanyDataQuotaAction } = useGetCompanyDataQuota();

  const getCompanyDataQuota = (startDate, endDate) => {
    if (!enterFirstTime) setDeepInsightsLoading(true);

    getCompanyDataQuotaAction(
      { startDate, endDate },
      {
        onSuccess: (result) => {
          var smQuota = result?.data?.platforms;
          for (let i = 0; i < smQuota?.length; i++) {
            if (smQuota[i]?.name === "SM") {
              setPostsUsedLimit({
                limit:
                  smQuota[i]?.quota?.posts_limit?.limit -
                  smQuota[i]?.quota?.posts_limit?.used,
                used: smQuota[i]?.quota?.posts_limit?.used,
                type: 2,
              });
            }
          }
        },
        onSettled: () => {
          setDeepInsightsLoading(false);
          setEnterFirstTime(false);
        },
      },
    );
  };

  /*---------------------------------------------------------*/

  return (
    <MonitorContainer maxWidth="xl" className="monitor-container">
      <AuthorsBody
        response={response}
        preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        resetAllFields={resetAllFields}
        changePagination={changePagination}
        pageNumber={pageNumber}
        analyzeProfile={analyzeProfile}
        monitorData={props.monitorData}
        singleTweetAnalysis={props.singleTweetAnalysis}
        postsUsedLimit={postsUsedLimit}
        isProfileAnalyzed={isProfileAnalyzed}
        getCompanyDataQuota={getCompanyDataQuota}
        deepInsightsLoading={deepInsightsLoading}
        setDeepInsightsLoading={setDeepInsightsLoading}
      />
    </MonitorContainer>
  );
};

export default AuthorsProfilePage;
