import { useMutation } from "@tanstack/react-query";
import { FiltersServices } from "services/api/filterServices";

export const useGetMonitorFiltersFileds = () => {
  return useMutation({
    mutationFn: ({ section, monitor_id, source, product_id, monitor_type }) => {
      const queryData = {
        params: {
          section,
          monitor_id,
          product_id,
          monitor_type,
        },
      };
      return FiltersServices.getMonitorFiltersFileds({ source, queryData });
    },
  });
};
