import { useState, useEffect, useContext } from "react";
import { Box, Button, SwipeableDrawer } from "@mui/material";
import Mylogo from "../../images/luci-images/logoLuci.svg";
import MylogoLoading from "../../images/luci-images/loader.svg";
import LuciSideBar from "./luciSideBar";
import "./main.scss";
import FailedAnswerPopup from "./components/ListQuestions/components/failedAnswer/components/failedAnswerPopup";
import SuccessAnswerPopup from "./components/ListQuestions/components/successAnswer/components/successAnswerPopup";
import LuciFunctions from "./components/luciFunctions/luciFunction";
import LuciPolicy from "./components/luciPolicy/luciPolicy";

//Firebase Notification
import {
  messaging,
  onMessageListener,
} from "firebaseNotifications/firebaseConfig";
import { getToken } from "firebase/messaging";
import FirebaseFCMController from "services/controllers/firebaseFCM";
import UserContext from "context/audienceContext";
import NoData from "./components/noData/noData";

const Luci = (props) => {
  const {
    isInactive,
    pageParams,
    isBenchmark,
    isCAPage,
    isSurvey,
    individualSurvey,
    setIndividualSurvey,
    page,
    setPage,
    openLuci,
    setOpenLuci,
    mainQuestionNotification,
    setMainQuestionNotification,
  } = props;
  const ctx = useContext(UserContext);

  const {
    getLuciStaticQuestions,
    luciStaticQuestions,
    questionsLoading,
    isUserThinking,
    createLuciQuestion,
    activeQuestion,
    getActiveQuestionName,
    setActiveQuestion,
    freeQuestionId,
    currentToken,
    setCurrentToken,
    showBusinessFormPopup,
    energyTokens,
    setEenergyTokens,
    isCreateQuestionLoading,
    showConfirmationPopup,
    setShowConfirmationPopup,
    showLuciAnswers,
    setShowLuciAnswers,
    questionIdAnswer,
    questionNameLocale,
    handleCreateFreeQuestions,
    setShowBusinessFormPopup,
    getBusinessDetails,
    formData,
    hasCompanyOption,
    setQuestionIdAnswer,
    handleRequestClick,
    showRequests,
    setShowRequests,
    getLuciCurrentTokens,
    currentTokensInactive,
    settingsOpen,
    handleOpenSettings,
    handleCloseSettings,
  } = LuciFunctions();

  const policy = window?.localStorage?.is_policy_active;

  const [state, setState] = useState({
    left: false,
    right: false,
  });

  const [showHeaderFilters, setShowHeaderFilters] = useState(false);
  const [showReceviedPopup, setShowReceviedPopup] = useState(
    localStorage.getItem("firebaseSnackBar"),
  );

  const [showSnackbarShow, setSnackbarShow] = useState(false);
  const [snackbarToggle, setSnackbarToggle] = useState(false);

  //Firebase State
  const [questionNotification, setQuestionNotification] = useState({
    title: "",
    body: "",
    status: "",
    questionId: "",
  });
  const [firebaseFCMToken, setFirebaseFCMToken] = useState("");
  const [isLuciPolicy, setIsLuciPolicy] = useState(policy);
  let sideBarDirection = window.localStorage.lang !== "ar" ? "right" : "left";
  let isLuciAppear = window?.localStorage?.luci_appear;

  useEffect(() => {
    if (
      mainQuestionNotification?.summaryWidget === "false" &&
      mainQuestionNotification?.questionId
    ) {
      setQuestionNotification({
        questionId: mainQuestionNotification?.questionId,
        status: mainQuestionNotification?.status, // [ done - failed ]
      });
    }
  }, [mainQuestionNotification]);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (openLuci) setOpenLuci(false);
    setState({ ...state, [sideBarDirection]: open });
    setShowConfirmationPopup(false);
    setShowHeaderFilters(false);
    setShowRequests(false);
    setShowLuciAnswers(false);
    setShowBusinessFormPopup(false);
    if (open && !isInactive) {
      getBusinessDetails();
      getLuciStaticQuestions(pageParams);
    }
    if (open && isInactive) getLuciCurrentTokens();

    localStorage.setItem("firebaseSnackBar", false);
    setShowReceviedPopup("false");
    setSnackbarToggle(!snackbarToggle);
  };

  useEffect(() => {
    setShowReceviedPopup(localStorage.getItem("firebaseSnackBar"));
  }, [window.location.pathname, questionNotification?.status]);

  // Firebase MessageListener [ foreground message ]
  onMessageListener()
    .then((payload) => {
      if (window.location.host !== "cxm.lucidya.com") {
        console.log(payload);
        console.log(payload?.data?.is_success);
      }
      mainQuestionNotification
        ? setMainQuestionNotification({
            questionId: payload?.data?.question_id,
            status: payload?.data?.is_success, // [ done - failed ]
            summaryWidget: payload?.data?.auto_summary_widget,
            sectionName: payload?.data?.section_name,
            monitorId: payload?.data?.monitor_id,
          })
        : setQuestionNotification({
            questionId: payload?.data?.question_id,
            status: payload?.data?.is_success, // [ done - failed ]
          });
      localStorage.setItem("firebaseSnackBar", true);
      setShowReceviedPopup(true);
      setSnackbarShow(true);
    })
    .catch((err) => console.log("failed: ", err));

  useEffect(() => {
    if (questionNotification.status !== "") {
      ctx.luciData = {
        questionId: questionNotification?.questionId,
        status: questionNotification?.status, // [ done - failed ]
      };
    }
    setQuestionIdAnswer(questionNotification?.questionId);
  }, [questionNotification]);

  // Check request permision [Firebase] [Background message]
  async function requestPermission() {
    const broadcast = new BroadcastChannel("background-message");
    broadcast.onmessage = (event) => {
      if (window.location.host !== "cxm.lucidya.com") {
        console.log("message from service-worker:", event.data);
      }
      mainQuestionNotification
        ? setMainQuestionNotification({
            questionId: event?.data?.data?.question_id,
            status: event?.data?.data?.is_success, // [ done - failed ]
            summaryWidget: event?.data?.data?.auto_summary_widget,
            sectionName: event?.data?.data?.section_name,
            monitorId: event?.data?.data?.monitor_id,
          })
        : setQuestionNotification({
            questionId: event?.data?.data?.question_id,
            status: event?.data?.data?.is_success, // [ done - failed ]
          });
      localStorage.setItem("firebaseSnackBar", true);
      setShowReceviedPopup(true);
      setSnackbarShow(true);
    };
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      //Generate Token
      const token = await getToken(messaging, {
        vapidKey:
          "BDdPuRP9GZNo8kJjCXsrQ_ky19somcqw60jLVk8SViEY0X4lmdZ8goaZRmF4G-GkoodiSvSeD3Xqp-qoE_0KW34", //default Vapid for app
      });
      setFirebaseFCMToken(token);
    } else if (
      permission === "denied" &&
      window.location.host !== "cxm.lucidya.com"
    ) {
      console.log("You denied for this notification");
    }
  }

  useEffect(() => {
    // Req user for notification permission
    requestPermission();
  }, []);
  useEffect(() => {
    setIsLuciPolicy(policy);
  }, [policy]);
  useEffect(() => {
    if (firebaseFCMToken.length) {
      FirebaseFCMController.userTokenFCM(firebaseFCMToken).then((result) => {});
    }
  }, [firebaseFCMToken]);
  // useUserTokenFCM({ fcmToken: firebaseFCMToken });

  useEffect(() => {
    if (openLuci) {
      toggleDrawer(true)(null);
    }
  }, [openLuci]);

  let questionStatus = questionNotification?.status
    ? questionNotification?.status
    : ctx?.luciData.status;
  let questionIdNotification = questionNotification?.questionId
    ? questionNotification?.questionId
    : ctx?.luciData?.question_id;

  const list = () => (
    <Box
      sx={{
        width:
          sideBarDirection === "top" || sideBarDirection === "bottom"
            ? "auto"
            : 480,
      }}
      role="presentation"
    >
      {isLuciPolicy == "false" ? (
        <LuciPolicy
          toggleClose={toggleDrawer(true)}
          closeSideBar={toggleDrawer(false)}
          setIsLuciPolicy={setIsLuciPolicy}
          handleOpenSettings={handleOpenSettings}
        />
      ) : settingsOpen ? (
        <LuciSideBar
          close={toggleDrawer(false)}
          currentTokensInactive={currentTokensInactive}
          settingsOpen={settingsOpen}
          handleOpenSettings={handleOpenSettings}
          handleCloseSettings={handleCloseSettings}
          pageParams={pageParams}
          getLuciStaticQuestions={getLuciStaticQuestions}
        />
      ) : !isInactive &&
        !pageParams?.dataLoaded &&
        !showRequests &&
        !showLuciAnswers ? (
        <NoData
          toggleClose={toggleDrawer(true)}
          closeSideBar={toggleDrawer(false)}
          tokens={currentToken}
          isEmpty={false}
          productName={pageParams?.monitorProduct}
          setShowRequests={setShowRequests}
          handleRequestClick={handleRequestClick}
          handleOpenSettings={handleOpenSettings}
        />
      ) : !isInactive &&
        pageParams?.dataEmpty &&
        !showRequests &&
        !showLuciAnswers ? (
        <NoData
          toggleClose={toggleDrawer(true)}
          closeSideBar={toggleDrawer(false)}
          tokens={currentToken}
          isEmpty={true}
          productName={pageParams?.monitorProduct}
          setShowRequests={setShowRequests}
          handleRequestClick={handleRequestClick}
          handleOpenSettings={handleOpenSettings}
          isBenchmark={isBenchmark}
          requestedData={pageParams?.requestedData}
        />
      ) : (
        <LuciSideBar
          close={toggleDrawer(false)}
          showConfirmationPopup={showConfirmationPopup}
          setShowConfirmationPopup={setShowConfirmationPopup}
          filterParams={pageParams?.filterParams}
          startDate={pageParams?.startDate}
          endDate={pageParams?.endDate}
          showHeaderFilters={showHeaderFilters}
          setShowHeaderFilters={setShowHeaderFilters}
          isInactive={isInactive}
          pageParams={pageParams}
          showRequests={showRequests}
          setShowRequests={setShowRequests}
          luciStaticQuestions={luciStaticQuestions}
          questionsLoading={questionsLoading}
          isUserThinking={isUserThinking}
          createLuciQuestion={createLuciQuestion}
          activeQuestion={activeQuestion}
          setActiveQuestion={setActiveQuestion}
          getActiveQuestionName={getActiveQuestionName}
          freeQuestionId={freeQuestionId}
          currentToken={currentToken}
          setCurrentToken={setCurrentToken}
          energyTokens={energyTokens}
          setEenergyTokens={setEenergyTokens}
          isCreateQuestionLoading={isCreateQuestionLoading}
          showLuciAnswers={showLuciAnswers}
          setShowLuciAnswers={setShowLuciAnswers}
          questionNameLocale={questionNameLocale}
          handleCreateFreeQuestions={handleCreateFreeQuestions}
          notificationStatus={questionStatus}
          notificationquestionId={questionIdNotification}
          showBusinessFormPopup={showBusinessFormPopup}
          setShowBusinessFormPopup={setShowBusinessFormPopup}
          formData={formData}
          questionIdAnswer={questionIdAnswer}
          isCAPage={isCAPage}
          setQuestionIdAnswer={setQuestionIdAnswer}
          handleRequestClick={handleRequestClick}
          setSnackbarShow={setSnackbarShow}
          showSnackbarShow={showSnackbarShow}
          getLuciStaticQuestions={getLuciStaticQuestions}
          currentTokensInactive={currentTokensInactive}
          questionStatusNotification={questionNotification?.status}
          questionNotificationId={questionNotification?.questionId}
          isSurvey={isSurvey}
          individualSurvey={individualSurvey}
          setIndividualSurvey={setIndividualSurvey}
          page={page}
          setPage={setPage}
          isBenchmark={isBenchmark}
          settingsOpen={settingsOpen}
          handleOpenSettings={handleOpenSettings}
          handleCloseSettings={handleCloseSettings}
        />
      )}
    </Box>
  );
  let luciprocessing = !isInactive && !pageParams?.dataLoaded;
  return isLuciAppear == "true" ? (
    <>
      <Box className="logo-luci">
        <Button
          onClick={toggleDrawer(true)}
          className={
            isInactive === false
              ? "luci-image loader-effect"
              : "disabled-btn loader-effect"
          }
        >
          <img src={Mylogo} alt="" />
        </Button>

        {luciprocessing ? (
          <img src={MylogoLoading} className="luci-processing" />
        ) : null}
      </Box>

      {(showReceviedPopup !== "false" && questionStatus == "failed") ===
      true ? (
        <FailedAnswerPopup />
      ) : null}

      {(showReceviedPopup !== "false" && questionStatus == "done") === true ? (
        <SuccessAnswerPopup />
      ) : null}

      <SwipeableDrawer
        anchor={sideBarDirection}
        open={state[sideBarDirection]}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        className="side-bar"
      >
        {list(sideBarDirection)}
      </SwipeableDrawer>
    </>
  ) : null;
};

export default Luci;
