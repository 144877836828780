import { api } from "./newServices";

const getMonitors = (queryData) => {
  return api.get("api/v4/monitors", queryData);
};

const resumeOrPauseMonitors = (monitorId) => {
  return api.put(`api/v3/monitors/${monitorId}`);
};

const deleteMonitor = (monitorId) => {
  return api.delete(`api/v3/monitors/${monitorId}`);
};

const channels = (queryData) => {
  return api.get("api/v4/monitors", queryData);
};

const getCompanyList = () => {
  return api.get("api/v3/company/companies_list");
};

const assignUserToCompany = (queryData) => {
  return api.post(
    "api/v3/company/user_managements/assign_user_to_company",
    queryData,
  );
};

const getMonitorInfo = ({ queryData, monitorId }) => {
  return api.get(`api/v3/monitors/${monitorId}`, queryData);
};

const getSocialMediaMonitorData = ({
  monitorDataSource,
  monitor_id,
  queryData,
}) => {
  return api.get(
    `api/v3/social_listening/${monitorDataSource?.toLowerCase()}/${monitor_id}`,
    queryData,
  );
};

const getQuickInspectData = ({ monitorDataSource, monitor_id, queryData }) => {
  return api.get(
    `api/v3/social_listening/${monitorDataSource?.toLowerCase()}/${monitor_id}/quick_inspect`,
    queryData,
  );
};

const getAllawedMonitors = ({ queryData }) => {
  return api.get("api/v4/engagements/allowed_reply_monitors", queryData);
};

const getAuthorProfileData = (monitorDataSource, monitor_id, queryData) => {
  return api.get(
    `api/v3/social_listening/${monitorDataSource.toLowerCase()}/${monitor_id}/engager_profile`,
    { params: queryData },
  );
};

const getDeepInsightData = (monitorDataSource, monitor_id, queryData) => {
  return api.get(
    `api/v3/social_listening/${monitorDataSource.toLowerCase()}/${monitor_id}/deep_insight`,
    { params: queryData },
  );
};

const getTopicsMonitors = (queryData) => {
  return api.get("api/v3/company/topics_monitors", { params: queryData });
};

const getTopicsForMonitor = (monitor_id, queryData = {}) => {
  return api.get(`api/v3/monitors/${monitor_id}/topics`, { params: queryData });
};

const deleteTopicsFromMonitor = (monitor_id, queryData) => {
  return api.delete(`api/v3/monitors/${monitor_id}/topics`, {
    data: queryData,
  });
};

const getTopicsUsage = (queryData = {}) => {
  return api.get("api/v3/company/topics_usage", { params: queryData });
};

const testKeywords = (queryData) => {
  return api.get("api/v3/monitors/keywords/matching", { params: queryData });
};

export const MainMonitorsPage = {
  getMonitors,
  resumeOrPauseMonitors,
  deleteMonitor,
  channels,
  getCompanyList,
  assignUserToCompany,
  getMonitorInfo,
  getSocialMediaMonitorData,
  getQuickInspectData,
  getAllawedMonitors,
  getAuthorProfileData,
  getDeepInsightData,
  getTopicsMonitors,
  getTopicsForMonitor,
  deleteTopicsFromMonitor,
  getTopicsUsage,
  testKeywords,
};
