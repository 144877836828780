import moment from "moment";
import { isEmptyArray } from "utils/helpers";
import {
  handleAuthorActivity,
  handleXlsData,
  handleSortedCase,
} from "../excelCommonFn";
import { handleShowRemainingTime } from "./inbox";
import { displayRemainingTime } from "pages/engagements/components/engagementAnalytics/sharedFunctions/datesUtils";

export const engagementAnalyticsAgents = (response, lang) => {
  if (isEmptyArray(response)) {
    return [];
  } else {
    let agentPerformance = [],
      engagAgentsOverView = [],
      agentsDistribution = [],
      hitsAndMisses = [],
      agentInboxPerformance = [],
      allSheetData = [];

    const typesMapper = {
      time_to_complete: "avgs_time_to_complete",
      next_response_time: "avgs_next_response_time",
      first_response_time: "avgs_first_response_time",
    };

    Object.entries(response)?.forEach(([key, value]) => {
      switch (key) {
        case "agentInboxPerformance":
          value?.assigned?.map((item, i) => {
            agentInboxPerformance.push({
              [lang.date_quick]: moment
                .unix(item?.name)
                .utc()
                .format("YYYY-MM-DD"),
              [lang.assigned]: item?.value,
              [lang.completed]: value?.completed?.[i]?.value,
            });
          });
          allSheetData.push([
            agentInboxPerformance,
            lang?.agent_inbox_performance,
          ]);
          break;
        case "agentPerformance":
          const { data: agentsStats, agents } = value;
          agentPerformance = handleAgentPerformanceExcelPage(
            agentsStats,
            agents,
            lang,
          );
          allSheetData.push([
            agentPerformance,
            lang?.agent_tab_performance_title,
          ]);
          break;
        case "engagOverView":
          Object.keys(value)?.map((i) => {
            let overviewItemLang =
              i == "total_reopened" ? lang["reopens_engag"] : lang[i];
            let num = value[i];
            if (i === "avg_engag") {
              num = handleShowRemainingTime(
                displayRemainingTime(+(value?.[i] || 0)),
                lang,
              );
            }
            engagAgentsOverView.push({
              [lang.engag_metric]: overviewItemLang,
              [lang.xlsx_value]: num ? num : 0,
            });
          });
          allSheetData.push([
            engagAgentsOverView,
            lang.xlsx_engag_agents_overview,
          ]);
          break;
        case "agentsDistribution":
          value?.map((i) => {
            agentsDistribution?.push({
              [lang?.agents_disribution_xlsx_name]: i?.name,
              [lang?.agents_disribution_xlsx_val]: i?.value,
            });
          });
          allSheetData.push([agentsDistribution, lang?.agents_disribution]);
          break;

        case "hitsAndMisses":
          Object.entries(value)?.map(([key, i]) => {
            const type = typesMapper[key];
            hitsAndMisses.push({
              [lang.slas_hits_and_misses_table_type]: lang[type]
                ? lang[type]
                : type,
              [lang.avg_slas_table_column]: handleShowRemainingTime(
                displayRemainingTime(i?.avgTime ? i?.avgTime : 0),
                lang,
              ),
              [lang.hits]: i?.hits,
              [lang.misses]: i?.misses,
            });
          });
          allSheetData.push([
            hitsAndMisses,
            lang?.hits_misses_activity_sheet_title,
          ]);
          break;
        default:
          break;
      }
    });
    return handleXlsData(allSheetData);
  }
};

const handleAgentPerformanceExcelPage = (agentsPerformance, agents, lang) => {
  const data = [];
  agentsPerformance?.map((item, i) => {
    const agent = agents?.find((agent) => +agent?.id === +item?.agent_id);
    // if agent is not found we will skip this iteration since we can't find the agent
    if (!agent) return;
    data.push({
      [lang?.agent_performance_grouped_agents_column]: agent?.name,
      [lang?.agent_performance_frt]: handleShowRemainingTime(item?.frt, lang),
      [lang?.agent_performance_nrt]: handleShowRemainingTime(item?.nrt, lang),
      [lang?.agent_performance_ttc]: handleShowRemainingTime(item?.ttc, lang),
      [lang?.agent_performance_hits]: item?.hits_count,
      [lang?.agent_performance_misses]: item?.misses_count,
      [lang?.agent_performance_compliance_rate]: item?.compliance_rate,
      [lang?.avg_engag]: handleShowRemainingTime(item?.avg_handle, lang),
      [lang?.agent_performance_manual_assign]: item?.manual_assign,
      [lang?.agent_performance_auto_assign]: item?.auto_assign,
    });
  });
  return data;
};
