import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import "moment-timezone";

import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import {
  Typography,
  Box,
  Container,
  Tabs,
  Tab,
  Tooltip,
  Zoom,
  Button,
} from "@mui/material";
import CircularLoading from "components/circularProgress";
import xPlatform from "images/social-icons/x-platform-icon.svg";
import "./top-hashtags.scss";
import TopHashtagsFilter from "./TopHashtagsFilter";
import TopHashtagDaily from "./TopHashtagDaily";
import TopHashtagHourly from "./TopHashtagHourly";
import TopHashtagOverall from "./TopHashtagOverall";
import TopHashtagsController from "services/controllers/topHashtagsController";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import PageHeader from "components/pageHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import LucButton from "shared/lucButton/lucButton";
import { faBellPlus } from "@fortawesome/pro-regular-svg-icons";
import AlertForm from "pages/alerts/components/alertForm/alertForm";
import AlertsFunctions from "pages/alerts/components/alertsFormFunctions/alertsFunctions";
import SnackBar from "components/snackBar";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(97, 97, 97, 0.9)",
  },

  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    fontSize: "12px",
    lineHeight: "14px",
    padding: "4px 8px",
    fontWeight: "500",
  },
}));

const noDataTextTooltip = (intl) => {
  return (
    <BootstrapTooltip
      title={CheckValueLocale("no_data_text_tooltip", "", {}, intl)}
      arrow
      TransitionComponent={Zoom}
      placement="top"
    >
      <Button variant="text" className="no-data-btn" disableRipple>
        {CheckValueLocale("no_data_text", "", {}, intl)}
      </Button>
    </BootstrapTooltip>
  );
};

const TopHashtags = () => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [locations, setLocations] = useState([]);
  const [removeSearch, setRemoveSearch] = useState(true);
  const [clearSearchFlag, setClearSearchFlag] = useState(false);
  const [appliedFilter, setAppliedFilter] = useState({
    location: "23424938",
    hashtag: "",
    startDate: moment()?.tz("utc", true).subtract(7, "d").startOf("day").unix(),
    endDate: moment()?.tz("utc", true).unix(),
    period: "",
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    let newStartDate,
      newEndDate,
      newPeriod = 0;
    if (newValue === 1) {
      newStartDate = moment()?.tz("utc", true).startOf("day").unix();
      newEndDate = moment()?.tz("utc", true).endOf("day").unix();
      newPeriod = 0;
    } else if (newValue === 2) {
      newPeriod = 4;
    } else {
      newStartDate = moment()
        .tz("utc", true)
        .subtract(7, "d")
        .startOf("day")
        .unix();
      newEndDate = moment().tz("utc", true).unix();
      newPeriod = "";
    }
    setAppliedFilter((val) => ({
      ...val,
      period: newPeriod,
      startDate: newStartDate,
      endDate: newEndDate,
    }));
  };

  const handeAppliedFilters = (data) => {
    setAppliedFilter({ ...data });
  };

  const getLocationList = () => {
    TopHashtagsController.getLocation().then((res) => {
      res.data && setLocations(res?.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getLocationList();
  }, []);

  var tableDescriptionTitle =
    tabValue === 0
      ? "overall_insights_hashtags"
      : tabValue === 1
        ? "daily_insights_hashtags"
        : tabValue === 2
          ? "hourly_insights_hashtags"
          : "";

  const alertUtils = AlertsFunctions();
  const {
    alertsInfo,
    openForm,
    setProductType,
    alertsSources,
    setDatasourceType,
    alertsTypes,
    setAlertType,
    showSnackBar,
    setShowSnackBar,
    showAlertActionMessage,
  } = alertUtils;

  useEffect(() => {
    if (openForm) {
      if (alertsInfo?.length > 0) {
        alertsInfo?.map((product) => {
          if (product?.name == "SM") setProductType(product?.id);
        });
      }
      if (alertsSources?.length > 0) {
        alertsSources?.map((source) => {
          if (source?.name === "TWITTER")
            setDatasourceType(
              `${source?.id}-${source?.name}-${source?.channel_type_id}`,
            );
        });
      }
      if (alertsTypes?.length > 0) {
        alertsTypes?.map((type) => {
          if (type?.name === "X Top Trends") {
            setAlertType(type?.id);
          }
        });
      }
    }
  }, [openForm, alertsInfo, alertsSources, alertsTypes]);

  const handleCreateAlert = () => {
    alertUtils?.resetFormData();
    alertUtils?.handleOpenForm("addAlert");
    alertUtils?.getAlertData();
    alertUtils?.getComanyEmails();
  };

  const canCreateAlert = handlelUserRoles("GENERAL", "VIEW_UTILITIES");

  return (
    <Container maxWidth="xl">
      {loading ? (
        <CircularLoading />
      ) : (
        <>
          <Box className="top-trends-header-wrapper">
            <Box
              className="top-trend-header-container"
              id="main-top-trend-header"
            >
              <img src={xPlatform} alt="x-platform-logo" />
              <PageHeader title="top_trends" />
              <Tooltip
                id="top-trend-title-tooltip"
                title={CheckValueLocale(
                  "tops_trends_are_updated",
                  "",
                  {},
                  intl,
                )}
                arrow
                placement={intl?.locale === "en" ? "right" : "left"}
              >
                <Box className="tooltip-top-trend">
                  <FontAwesomeIcon icon={faInfo} />
                </Box>
              </Tooltip>
            </Box>
            {canCreateAlert ? (
              <LucButton
                onClick={handleCreateAlert}
                startIcon={<FontAwesomeIcon icon={faBellPlus} />}
              >
                {CheckValueLocale("create_top_trends_alert", "", {}, intl)}
              </LucButton>
            ) : null}
          </Box>
          <Box className="top-hashtags-view">
            <Box className="top-hashtags-tab">
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label={CheckValueLocale("overall", "", {}, intl)}
                  {...a11yProps(0)}
                />
                <Tab
                  label={CheckValueLocale("daily", "", {}, intl)}
                  {...a11yProps(1)}
                />
                <Tab
                  label={CheckValueLocale("hourly", "", {}, intl)}
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>
            <TopHashtagsFilter
              intl={intl}
              tabNumber={tabValue}
              locations={locations}
              handeAppliedFilters={handeAppliedFilters}
              tableDescriptionTitle={tableDescriptionTitle}
              appliedFilterStartDate={appliedFilter.startDate}
              appliedFilterEndDate={appliedFilter.endDate}
              appliedFilterPeriod={appliedFilter.period}
              tabValue={tabValue}
              removeSearch={removeSearch}
              setRemoveSearch={setRemoveSearch}
              clearSearchFlag={clearSearchFlag}
              setClearSearchFlag={setClearSearchFlag}
              setAppliedFilter={setAppliedFilter}
            />
          </Box>
          <TabPanel value={tabValue} index={0}>
            <TopHashtagOverall
              intl={intl}
              noDataTextTooltip={noDataTextTooltip}
              appliedFilter={appliedFilter}
              setRemoveSearch={setRemoveSearch}
              setClearSearchFlag={setClearSearchFlag}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <TopHashtagDaily
              intl={intl}
              noDataTextTooltip={noDataTextTooltip}
              appliedFilter={appliedFilter}
              setRemoveSearch={setRemoveSearch}
              setClearSearchFlag={setClearSearchFlag}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <TopHashtagHourly
              intl={intl}
              noDataTextTooltip={noDataTextTooltip}
              appliedFilter={appliedFilter}
              setRemoveSearch={setRemoveSearch}
              setClearSearchFlag={setClearSearchFlag}
            />
          </TabPanel>
        </>
      )}
      {openForm ? <AlertForm {...alertUtils} isFromTopTrends /> : null}
      <SnackBar
        open={showSnackBar}
        severity={showAlertActionMessage?.severity}
        message={CheckValueLocale(
          "create_alert_success_message",
          "",
          {},
          intl,
        )}
        title={showAlertActionMessage?.title}
        handleClose={() => {
          setShowSnackBar(false);
        }}
      />
    </Container>
  );
};
export default TopHashtags;
