import React, { useEffect } from "react";
// Import routing components
import {
  Route,
  useLocation,
  useNavigate,
  Navigate,
  Routes,
  Outlet,
} from "react-router-dom";
import {
  monitorRoutes,
  interactionsRoutes,
  manageChannelsRoutes,
  insightsRoutes,
  accountListsRoutes,
  reportsRoutes,
  audienceRoutes,
  surveyRoutes,
  notificationRoutes,
  dashboardRoutes,
  settingsRoutes,
  alertsRoutes,
} from "./component/pathes";
import MainPage from "pages/MainPage/mainPage.js";
import Login from "pages/loginPage/loginPage";
import ProfilePage from "pages/profile/profilePage";

import DesignSystem from "pages/DesignSystem/DesignSystem";
import SingleTweetAnalysis from "pages/notifications/SingleTweetAnalysis/index";
import TopHashtags from "pages/socialListing/TopHashtags/index.js";
import HashtagDetails from "pages/socialListing/TopHashtags/HashtagDetails/index.js";

import NotFoundPage from "pages/not-found/NotFoundPage.js";
import InsightsSideMenu from "pages/channelAnalytics/InsightsPage/insightsSideMenu/insightsSideMenu";
import ResetPasswordForm from "pages/loginPage/loginPage";
import NewUserForm from "pages/loginPage/loginPage";
import { handleActiveFeature, handlelUserRoles } from "utils/helpers";
import PrivateRoute from "../../shared/privateRoute/privateRoute";
import FaqsPage from "pages/faqs";
import MainMonitorPage from "pages/Monitors/mainMonitorPage";
import MonitorSettings from "pages/socialListing/MonitorsOptions/monitorSettings/monitorSettings";
import { Userpilot } from "userpilot";

//Genesys Pages
import UserAccount from "services/controllers/userAccountController";
//explore page
import ExplorePage from "pages/socialListing/ExplorePage";
import EngagementInbox from "pages/engagements";
import EngagementPublish from "pages/engagements/components/publish";
import ChatInteractionsPage from "pages/channelAnalytics/InsightsPage/chatPage/ChatInteractionsPage/ChatInteractionsPage";
import MainSetup from "./../../pages/engagements/components/setup-flow/setup/mainSetup";
import UserGuide from "pages/engagements/components/publish/componenets/userGuide";
import HomePage from "pages/homepage";
import MainEdit from "pages/engagements/components/setup-flow/edit/mainEdit";
import GuidelinesButtons from "shared/lucButton/guidelinesButtons";

import EngagementAnalytics from "pages/engagements/components/engagementAnalytics";
import AiAgents from "pages/engagements/components/aiAgents/aiAgents";

//live dashboard page
import CreateLiveDashboard from "pages/createLiveDashboard";
import LiveDashboard from "pages/liveDashboard";
import { clearAiAgent } from "utils/redux/features/Engagements/EngagementsSlice";
import { useDispatch } from "react-redux";
import ManageSubscription from "pages/ManageSubscription";
import LiveDashboardPublic from "pages/liveDashboard/liveDashboardPublic";
import ThemesPage from "pages/themes";
import { Box } from "@mui/material";
import Settings from "pages/settings";
import Services from "services/api/Services.js";

const MainRoutes = (props) => {
  const dispatch = useDispatch();
  const isAuthenticated = localStorage.getItem("user_token") ? true : false;
  if (Services.showUserPilot) {
    Userpilot.initialize("NX-610ca6c3");
  }
  Userpilot.identify(localStorage.user_id, {
    page_url: window.location.href,
    page_title: window.location.href,
    id: localStorage.user_id,
    email: localStorage.email,
    app_language: localStorage.lang,
    name: localStorage.name,
    created_at: localStorage.created_at,
    locale_code: localStorage.lang,
  });
  var meta = { URL: window.location.href };
  Userpilot.track("Page Event", meta);
  Userpilot.reload();
  const location = useLocation();
  const navigate = useNavigate();

  let url = window.location.search;
  if (url.includes("?redirect_url") && localStorage.getItem("user_token")) {
    UserAccount.createUserHutspot().then((result) => {
      if (result?.data) {
        window.open(result?.data?.url, "_self");
      }
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll position to top
  }, [location.pathname]);

  useEffect(() => {
    const pathNameVar = location?.pathname;
    if (pathNameVar?.includes("TWITTER")) {
      navigate(pathNameVar?.replace("TWITTER", "X_PLATFORM"));
    } else if (pathNameVar?.includes("twitter")) {
      navigate(pathNameVar?.replace("twitter", "x-platform"));
    } else if (pathNameVar?.includes("tweet")) {
      navigate(pathNameVar?.replace("tweet", "post"));
    }
    if (pathNameVar.includes("/live-dashboard")) {
      if (pathNameVar.includes("/live-dashboard/public")) {
        props?.setIsLiveDashboardPublic(true);
      } else {
        props?.setIsLiveDashboardPublic(false);
      }
      props?.setIsLiveDashboard(true);
    } else {
      props?.setIsLiveDashboard(false);
      props?.setIsLiveDashboardPublic(false);
    }
  }, [location]);

  useEffect(() => {
    if (
      !(
        location?.pathname == "/engagements/main" ||
        location?.pathname == "/engagements/main/inbox"
      )
    ) {
      dispatch(clearAiAgent());
    }
  }, [location?.pathname]);

  var activeProducts = JSON.parse(window.localStorage.activeProducts || null);
  let aiAgentActive = activeProducts?.find(
    (product) => product?.name == "AI_AGENT",
  );
  const interactionPaths = [
    "/interactions/chat/whatsapp/",
    "/interactions/chat/intercom/",
    "/interactions/ratings/google-my-business/",
    "/interactions/social-media/x-platform-public/",
    "/interactions/social-media/x-platform-private/",
    "/interactions/social-media/facebook-private/",
    "/interactions/social-media/facebook-public/",
    "/interactions/social-media/instagram-private/",
    "/interactions/social-media/instagram-public/",
    "/interactions/email/gmail/",
    "/interactions/social-media/",
    "/interactions/social-media/linkedin-channel/",
    "/interactions/calls/genesys/",
  ];

  const shouldShowSideMenu = interactionPaths?.some((path) =>
    location?.pathname?.includes(path),
  );

  return (
    <>
      {location.pathname === "/login" ||
      location.pathname === "/" ||
      location.pathname.includes("/reset_password") ||
      location.pathname.includes("/invitation_login") ||
      location.pathname.includes("/live-dashboard") ||
      location.pathname.includes("/manage-subscription") ? null : (
        <MainPage switchLang={props?.switchLang} lang={props?.lang} />
      )}

      <Routes>
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <Navigate to="/home_page" />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/live-dashboard/public/:monitorType/:monitorId/:userDashboardId"
          element={<LiveDashboardPublic />}
        />
        <Route
          path="/login"
          element={<Login switchLang={props?.switchLang} lang={props?.lang} />}
        />
        <Route
          path="/home_page"
          element={
            isAuthenticated ? (
              <HomePage lang={props.lang} />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route path="/reset_password/:token" element={<ResetPasswordForm />} />
        <Route
          path="/invitation_login/:user_email/:invitation_token/*"
          element={<NewUserForm />}
        />
        {/* Monitor Pages */}
        <Route
          path="/social"
          element={isAuthenticated ? <Outlet /> : <Navigate to="/login" />}
        >
          {monitorRoutes?.map(({ path, component: Component }) => (
            <Route
              key={path}
              path={path}
              element={
                <PrivateRoute
                  element={<Component />}
                  isAuthenticated={isAuthenticated}
                />
              }
            />
          ))}
        </Route>
        {/* Monitor Pages */}
        <Route
          path="/monitor/:monitorType/:monitorDataSource/:activeTab/:monitorId"
          element={isAuthenticated ? <Outlet /> : <Navigate to="/login" />}
        >
          <Route
            path=""
            element={
              <PrivateRoute
                element={<MainMonitorPage />}
                isAuthenticated={isAuthenticated}
              />
            }
          />
          <Route
            path="author-profile/:tweetId/:userId"
            element={
              <PrivateRoute
                element={<MainMonitorPage />}
                isAuthenticated={isAuthenticated}
              />
            }
          />
          <Route
            path="deep-insights/:userName/:userId"
            element={
              <PrivateRoute
                element={<MainMonitorPage />}
                isAuthenticated={isAuthenticated}
              />
            }
          />
          <Route
            path="*"
            element={
              <PrivateRoute
                element={<NotFoundPage />}
                isAuthenticated={isAuthenticated}
              />
            }
          />
        </Route>
        {/* Monitors settings */}
        <Route
          path="/monitor/monitor-settings"
          element={isAuthenticated ? <Outlet /> : <Navigate to="/" />}
        >
          <Route
            path=":monitorId"
            element={
              <PrivateRoute
                element={<MonitorSettings />}
                isAuthenticated={isAuthenticated}
              />
            }
          />
          <Route
            path="*"
            element={
              <PrivateRoute
                element={<NotFoundPage />}
                isAuthenticated={isAuthenticated}
              />
            }
          />
        </Route>
        {/* Manage channels */}
        <Route
          path="/manage-channels"
          element={isAuthenticated ? <Outlet /> : <Navigate to="/" />}
        >
          {manageChannelsRoutes?.map(({ path, component: Component }) => (
            <Route
              key={path}
              path={path}
              element={
                <PrivateRoute
                  element={<Component />}
                  isAuthenticated={isAuthenticated}
                />
              }
            />
          ))}
        </Route>
        {/* Top trends */}
        <Route
          path="/top-trends"
          element={isAuthenticated ? <Outlet /> : <Navigate to="/" />}
        >
          <Route path="" element={<TopHashtags />} />
          <Route path="view-trends" element={<HashtagDetails />} />
        </Route>
        {/* channelAnalyticsRoutes */}
        <Route
          path="/insights"
          element={
            isAuthenticated ? (
              <>
                <Outlet />
                <InsightsSideMenu />
              </>
            ) : (
              <Navigate to="/" />
            )
          }
        >
          {insightsRoutes?.map(({ path, component: Component }) => (
            <Route
              key={path}
              path={path}
              element={
                <PrivateRoute
                  element={<Component />}
                  isAuthenticated={isAuthenticated}
                />
              }
            />
          ))}
        </Route>
        {/* AlertsRoutes */}
        <Route
          path="/alerts"
          element={isAuthenticated ? <Outlet /> : <Navigate to="/" />}
        >
          {alertsRoutes?.map(({ path, component: Component }) => (
            <Route
              key={path}
              path={path}
              element={
                <PrivateRoute
                  element={<Component />}
                  isAuthenticated={isAuthenticated}
                />
              }
            />
          ))}
        </Route>
        {/* interactions */}
        <Route
          path="/interactions"
          element={
            isAuthenticated ? (
              <Box>
                <Outlet /> {shouldShowSideMenu ? <InsightsSideMenu /> : null}
              </Box>
            ) : (
              <Navigate to="/" />
            )
          }
        >
          {interactionsRoutes?.map(({ path, component: Component }) => (
            <Route
              key={path}
              path={path}
              element={
                <PrivateRoute
                  element={<Component />}
                  isAuthenticated={isAuthenticated}
                />
              }
            />
          ))}
        </Route>

        {/* EngagementRoutes */}
        <Route
          path="/engagements"
          element={isAuthenticated ? <Outlet /> : <Navigate to="/" />}
        >
          <Route
            path="main/inbox"
            element={
              handlelUserRoles("ENGAGEMENTS", "ACCESS_ENGAGEMENT_SYSTEM") &&
              (handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
                handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") ||
                handlelUserRoles("ENGAGEMENT_ACCESS", "PUBLISH")) ? (
                <EngagementInbox />
              ) : (
                <NotFoundPage />
              )
            }
          />
          <Route
            path="main/publish"
            element={
              handlelUserRoles("ENGAGEMENTS", "ACCESS_ENGAGEMENT_SYSTEM") &&
              (handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
                handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") ||
                handlelUserRoles("ENGAGEMENT_ACCESS", "PUBLISH")) ? (
                <EngagementPublish />
              ) : (
                <NotFoundPage />
              )
            }
          />
          <Route
            path="analytics"
            element={
              handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
              handlelUserRoles("ENGAGEMENT_ACCESS", "ENGAGEMENT_ANALYTICS") ? (
                <EngagementAnalytics />
              ) : (
                <NotFoundPage />
              )
            }
          />
          <Route
            path="analytics/:tab"
            element={
              handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
              handlelUserRoles("ENGAGEMENT_ACCESS", "ENGAGEMENT_ANALYTICS") ? (
                <EngagementAnalytics />
              ) : (
                <NotFoundPage />
              )
            }
          />
          <Route
            path="setup"
            element={
              handlelUserRoles("ENGAGEMENTS", "ENGAGEMENT_SETTINGS") &&
              (handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
                handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") ||
                handlelUserRoles("ENGAGEMENT_ACCESS", "PUBLISH") ||
                handlelUserRoles(
                  "ENGAGEMENT_ACCESS",
                  "ENGAGEMENT_ANALYTICS",
                )) ? (
                <MainSetup />
              ) : (
                <NotFoundPage />
              )
            }
          />
          <Route
            path="edit"
            element={
              handlelUserRoles("ENGAGEMENTS", "ENGAGEMENT_SETTINGS") &&
              (handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
                handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") ||
                handlelUserRoles("ENGAGEMENT_ACCESS", "PUBLISH") ||
                handlelUserRoles(
                  "ENGAGEMENT_ACCESS",
                  "ENGAGEMENT_ANALYTICS",
                )) ? (
                <MainEdit />
              ) : (
                <NotFoundPage />
              )
            }
          />
          <Route
            path="ai-agents"
            element={
              handlelUserRoles("AI_AGENT", "VIEW_AI_AGENT_SETTINGS") &&
              handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") &&
              aiAgentActive ? (
                <AiAgents />
              ) : (
                <NotFoundPage />
              )
            }
          />
          <Route path="publish/user_guide" element={<UserGuide />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
        {/* AccountListRoutes */}
        <Route
          path="/accounts-list"
          element={isAuthenticated ? <Outlet /> : <Navigate to="/" />}
        >
          {accountListsRoutes?.map(({ path, component: Component }) => (
            <Route
              key={path}
              path={path}
              element={
                <PrivateRoute
                  element={<Component />}
                  isAuthenticated={isAuthenticated}
                />
              }
            />
          ))}
        </Route>
        {/* ReportRoutes */}
        <Route
          path="/reports"
          element={isAuthenticated ? <Outlet /> : <Navigate to="/" />}
        >
          {reportsRoutes?.map(({ path, component: Component }) => (
            <Route
              key={path}
              path={path}
              element={
                <PrivateRoute
                  element={<Component />}
                  isAuthenticated={isAuthenticated}
                />
              }
            />
          ))}
        </Route>
        {/* AudienceRoutes */}
        <Route
          path="/audience"
          element={isAuthenticated ? <Outlet /> : <Navigate to="/" />}
        >
          {audienceRoutes?.map(({ path, component: Component }) => (
            <Route
              key={path}
              path={path}
              element={
                <PrivateRoute
                  element={<Component />}
                  isAuthenticated={isAuthenticated}
                />
              }
            />
          ))}
        </Route>
        {/* SurveyRoutes */}
        <Route
          path="/survey"
          element={isAuthenticated ? <Outlet /> : <Navigate to="/" />}
        >
          {surveyRoutes?.map(({ path, component: Component }) => (
            <Route
              key={path}
              path={path}
              element={
                <PrivateRoute
                  element={<Component />}
                  isAuthenticated={isAuthenticated}
                />
              }
            />
          ))}
        </Route>
        {/* NotificationRoutes */}
        <Route
          path="/notification"
          element={isAuthenticated ? <Outlet /> : <Navigate to="/" />}
        >
          {notificationRoutes?.map(({ path, component: Component }) => (
            <Route
              key={path}
              path={path}
              element={
                <PrivateRoute
                  element={<Component />}
                  isAuthenticated={isAuthenticated}
                />
              }
            />
          ))}
        </Route>
        {/* FaqsRoutes */}
        <Route
          path="/faqs"
          element={<FaqsPage />}
          isAuthenticated={isAuthenticated}
        />
        {/* DashboardRoutes */}
        <Route
          path="/dashboard"
          element={
            isAuthenticated ||
            (handlelUserRoles("SM", "VIEW_DASHBOARD_LIST") &&
              handleActiveFeature("SM", "is_dashboards_enabled") &&
              handleActiveFeature("SM", "is_sl_custom_dashboards_enabled") &&
              handleActiveFeature(
                "CXM",
                "is_cxm_custom_dashboards_enabled",
              )) ? (
              <>
                {location?.pathname == "/dashboard" ? (
                  <Navigate to="/dashboard/dashboard_list" />
                ) : (
                  <Outlet />
                )}
              </>
            ) : (
              <Navigate to="/" />
            )
          }
        >
          {dashboardRoutes?.map(({ path, component: Component }) => (
            <Route
              key={path}
              path={path}
              element={
                <PrivateRoute
                  element={<Component />}
                  isAuthenticated={isAuthenticated}
                />
              }
            />
          ))}
        </Route>
        {/* Custom Themes Page */}
        <Route
          path={"/themes"}
          element={
            <PrivateRoute
              element={
                handlelUserRoles("GENERAL", "VIEW_SUBTHEME") ? (
                  <ThemesPage />
                ) : (
                  <NotFoundPage />
                )
              }
              isAuthenticated={isAuthenticated}
            />
          }
        />

        {/* Profile Page */}
        <Route
          path="/profile"
          element={<ProfilePage />}
          isAuthenticated={isAuthenticated}
        />

        {/* SettingsRoutes we handel all setting route at <Settings /> component*/}
        <Route
          path="/settings/*"
          element={isAuthenticated ? <Settings /> : <Navigate to="/" />}
        />

        <Route
          path="/single_post_analysis/:monitor_id/:id/:screen_name"
          element={
            <PrivateRoute
              element={<SingleTweetAnalysis />}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path="/design-system"
          element={
            <PrivateRoute
              element={<DesignSystem />}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route path="/guidelinsButtons" element={<GuidelinesButtons />} />
        <Route
          path="/edit-live-dashboard/:monitorType/:monitorId/:userDashboardId"
          element={
            <PrivateRoute
              element={<CreateLiveDashboard />}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path="/create-live-dashboard/:monitorType/:monitorId"
          element={
            <PrivateRoute
              element={<CreateLiveDashboard />}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path="/live-dashboard/private/:monitorType/:monitorId/:userDashboardId"
          element={
            <PrivateRoute
              element={<LiveDashboard />}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path="/manage-subscription"
          element={
            <PrivateRoute
              element={<ManageSubscription />}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path="*"
          element={
            <PrivateRoute
              element={<NotFoundPage />}
              isAuthenticated={isAuthenticated}
            />
          }
        />
      </Routes>
    </>
  );
};

export default MainRoutes;
