import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Container, Pagination } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { useReportsList } from "./hooks/useReportsList";
import { useApplyReportsFilters } from "./hooks/useApplyReportsFilters";
import { useReportProducts } from "pages/reports/pages/create/hooks/useReportProduct";
import { useReportMonitorTypes } from "pages/reports/pages/create/hooks/useReportMonitorTypes";
import { useReportsFilterDatasources } from "./hooks/useReportsFilterDatasources";
import { useReportUsers } from "pages/reports/pages/create/hooks/useReportUsers";
import { ReportListContext } from "../../context/reportContext";
import CircularLoading from "components/circularProgress";
import SnackBar from "components/snackBar";
import ReportsListHeader from "./components/reportsListHeader";
import ReportsListTable from "./components/reportsListTable/reportsListTable";
import "./style.scss";

const ReportsList = () => {
  const intl = useIntl();

  const location = useLocation();

  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [clickedFilter, setClickedFilter] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({
    product_ids: [],
    monitor_type_ids: [],
    data_source_ids: [],
    frequencies: [],
    user_ids: [],
    statuses: [],
  });

  const [reportsData, setReportsData] = useState({});
  const [snackbar, setSnackbar] = useState({});

  const {
    data: reportsListData,
    isLoading: reportsListLoading,
    error: reportsListError,
  } = useReportsList({
    enabled: !clickedFilter,
    queryData: { page: page || 1, search: search || "" },
  });

  useEffect(() => {
    if (!!reportsListData) setReportsData(reportsListData);
  }, [reportsListData]);

  /** ---------------------------- 
     * Get available products
     ---------------------------- */
  const {
    data: availableProducts,
    isLoading: availableProductsLoading,
    isError: availableProductsError,
  } = useReportProducts();

  /** ------------------------------------------------
         * Get available monitor types "Social Listening"
        ------------------------------------------------- */
  const {
    data: availableMonitorTypes,
    isLoading: availableMonitorTypesLoading,
    isError: availableMonitorTypesError,
  } = useReportMonitorTypes({
    enabled: true,
    productId: availableProducts?.namesToIds?.["SM"],
  });

  /** ------------------------------
  * Get available data sources  
    --------------------------- */
  const {
    data: availableDatasources,
    isLoading: availableDatasourcesLoading,
    isError: availableDatasourcesError,
  } = useReportsFilterDatasources();

  /** -----------------------
   * Get available users
   * ----------------------- */
  const {
    data: availableUsers,
    isLoading: availableUsersLoading,
    isError: availableUsersError,
  } = useReportUsers();

  const { mutate: applyFilter, isPending: applyFilterLoading } =
    useApplyReportsFilters();

  const handleApplyFilters = async (e, specificPage) => {
    setClickedFilter(true);
    setSearch("");

    const filters = {};
    Object.entries(appliedFilters)?.forEach(([key, value]) => {
      if (Array.isArray(value) && value.length > 0)
        filters[key] = value?.join(",");
      else filters[key] = "";
    });

    await applyFilter(
      { queryData: { page: specificPage ? specificPage : 1, ...filters } },
      {
        onSuccess: (data) => {
          setReportsData(data?.data || {});
        },
        onError: () => {
          setSnackbar({
            open: true,
            severity: "error",
            title: "something_went_wrong",
            message: "try_again",
          });
        },
      },
    );
  };

  const handleChangePagination = (e, value) => {
    setPage(value);
    if (clickedFilter) handleApplyFilters(e, value);
  };

  useEffect(() => {
    const createReport = location?.state?.create_report;
    const editReport = location?.state?.edit_report;
    if (!!createReport) {
      setSnackbar({
        open: true,
        severity: createReport === "success" ? "success" : "error",
        title: "",
        message:
          createReport === "success"
            ? "report_added_success"
            : "report_added_fail",
      });
      return navigate("/reports/list", { replace: true });
    } else if (!!editReport) {
      setSnackbar({
        open: true,
        severity: editReport?.status === "success" ? "success" : "error",
        title: "",
        message:
          editReport?.status === "success"
            ? "report_edit_success"
            : "report_edit_fail",
        reportName: editReport?.name,
      });
      return navigate("/reports/list", { replace: true });
    }
  }, [location?.state]);

  useEffect(() => {
    if (
      reportsListError ||
      availableProductsError ||
      availableMonitorTypesError ||
      availableDatasourcesError ||
      availableUsersError
    ) {
      setSnackbar({
        open: true,
        severity: "error",
        title: "something_went_wrong",
        message: "try_again",
      });
    }
  }, [
    reportsListError,
    availableProductsError,
    availableMonitorTypesError,
    availableDatasourcesError,
    availableUsersError,
  ]);

  const contextProps = {
    setPage,
    search,
    setSearch,
    setClickedFilter,
    appliedFilters,
    setAppliedFilters,
    handleApplyFilters,
    setSnackbar,
    tableData: reportsData?.data || [],
    availableProducts,
    availableMonitorTypes,
    availableDatasources,
    availableUsers,
    availableProductsLoading,
    availableMonitorTypesLoading,
    availableDatasourcesLoading,
    availableUsersLoading,
    setSnackbar,
  };

  return (
    <ReportListContext.Provider value={contextProps || {}}>
      <Container maxWidth="xl">
        {reportsListLoading || applyFilterLoading ? (
          <CircularLoading />
        ) : (
          <Box>
            <ReportsListHeader />
            <ReportsListTable />
            {reportsData?.pagination?.pages > 1 ? (
              <Pagination
                variant="outlined"
                className="pagination-monitor-list pagination-report-list"
                count={reportsData?.pagination?.pages}
                page={reportsData?.pagination?.page || 1}
                onChange={handleChangePagination}
              />
            ) : null}
          </Box>
        )}
        {snackbar?.open ? (
          <SnackBar
            open={snackbar?.open}
            severity={snackbar?.severity}
            title={CheckValueLocale(snackbar?.title, "", {}, intl)}
            message={CheckValueLocale(
              snackbar?.message,
              "",
              { reportName: snackbar?.reportName },
              intl,
            )}
            handleClose={() => setSnackbar({})}
          />
        ) : null}
      </Container>
    </ReportListContext.Provider>
  );
};

export default ReportsList;
