import React, { useEffect, useState } from "react";
import moment from "moment";
import { useParams, useLocation } from "react-router-dom";
import DashboardSideBarAndHeader from "./components/dashboardHeader/dashboardSideBarAndHeader";
import DashboardFunctions from "../dashboardFunctions";
//dashboard pages
import DashboardPages from "./aggDashboardPages/index";
import FiltersFunctions from "../dashboardMainPages/components/filters/filtersFunctions";
import { getActiveParams } from "../../../shared/general-filter/activeFilters/activeFilters";
import PopupModal from "components/popupModal";
import { CheckValueLocale } from "utils/helpers/index.js";
import dashboardController from "services/controllers/dashboardController";
import { isEmptyObj } from "utils/helpers";
import { connectWithRabbitMQ } from "services/controllers/rabbitMQResponce";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";
import SnackBar from "components/snackBar";
import { useIntl } from "react-intl";

const MainDashboardPage = () => {
  const intl = useIntl();
  const urlParams = useParams();
  const location = useLocation();
  const dashboardLink = location?.state?.dashboardLinkFlag;

  const [singleTweetAnalysis, setSingleTweetAnalysis] = useState(false);
  const [dynamicDataLoader, setDynamicDataLoader] = useState(true);
  const mqttConnectionClosed = React.useRef(false);
  const clientMQTT = React.useRef();
  const [response, setResponse] = useState([]);

  const [insightsID, setInsightsID] = useState([]);
  const [insightsResponse, setInsightsResponse] = useState([]);
  const [dates, setDates] = useState([]);

  const [startDate, setStartDate] = useState(
    moment().subtract(6, "d").startOf("day").unix(),
  );
  const [endDate, setEndDate] = useState(moment().unix());

  const { changeFilterParamsToString } = CommonFunctions();

  const {
    setActiveTab,
    activeTab,
    dashboardDynamicData,
    widgetsName,
    getDashboardWidgetsName,
    setDashboardDynamicData,
    setWidgetsName,
  } = DashboardFunctions();

  const [showFilterBtn, setShowFilterBtn] = useState(true);
  const [resetAllFields, setResetAllFields] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [totalPageTw, setTotalPageTw] = useState(0);
  const [totalPageFb, setTotalPageFb] = useState(0);
  const [totalPageIg, setTotalPageIg] = useState(0);
  const [totalPageNb, setTotalPageNb] = useState(0);
  const [maxData, setMaxData] = useState(0);
  const [activePostsTab, setIsActivePostsTab] = useState("");
  const { dashboardActiveTab, dashboardID, monitorType } = urlParams;
  const isPublicAccount = monitorType === "ACCOUNT_ANALYSIS";

  useEffect(() => {
    setInsightsResponse(
      localStorage.getItem("moreInsightsResp") !== null
        ? JSON.parse(localStorage.getItem("moreInsightsResp"))
        : [],
    );
    setInsightsID(
      localStorage.getItem("moreInsightsIds") !== null
        ? JSON.parse(localStorage.getItem("moreInsightsIds"))
        : [],
    );
    setDates(
      localStorage.getItem("moreInsightsDates") !== null
        ? JSON.parse(localStorage.getItem("moreInsightsDates"))
        : [],
    );
  }, []);

  // Function for dashboard page
  const getDashboardsData = (
    startDate,
    endDate,
    activeTab,
    id,
    filterParams,
    page,
  ) => {
    if (page === 1 || page === undefined) setTotalPage(0);
    setDynamicDataLoader(true);
    setResponse([]);
    setWidgetsName([]);
    dashboardController
      .getDashboardData(
        activeTab,
        id,
        window.localStorage.sm_id,
        startDate,
        endDate,
        filterParams &&
          //format FilterParams to be string instead of array and remove empty array params
          changeFilterParamsToString(filterParams),
        page,
      )
      .then((res) => {
        setSingleTweetAnalysis(res?.data?.data?.single_tweet_analysis_enabled);
        if (!isEmptyObj(res?.data?.data)) {
          setDynamicDataLoader(false);
          setDashboardDynamicData(res?.data?.data);
          connectWithRabbitMQ(
            mqttConnectionClosed,
            clientMQTT,
            res?.data?.data,
            setResponse,
          );
        } else {
          mqttConnectionClosed.current = true;
        }
      });
  };
  // _______________________________________________________________
  useEffect(() => {
    if (
      activeTab !== "" ||
      (dashboardActiveTab !== "" &&
        dashboardLink !== undefined &&
        dashboardLink)
    ) {
      applyFilter(startDate, endDate, undefined, 1);
    }
    setIsActivePostsTab("");
    setTotalPage(0);
    setMaxData(0);
  }, [activeTab, dashboardActiveTab]);

  // useEffect to hide filter, add condition with OR, to hide___________________________
  useEffect(() => {
    setShowFilterBtn(true);
    if (
      (monitorType !== "KEYWORD" &&
        (dashboardActiveTab === "account_page" ||
          dashboardActiveTab === "posts_page")) ||
      (monitorType === "KEYWORD" &&
        dashboardActiveTab === "comments_mentions_page")
    ) {
      setShowFilterBtn(false);
    } else {
      getFiltersFileds();
    }
  }, [dashboardActiveTab]);

  useEffect(() => {
    dashboardDynamicData?.metrics_values?.top_posts_comments?.data_sources &&
      !activePostsTab &&
      setIsActivePostsTab(
        [
          ...dashboardDynamicData?.metrics_values?.top_posts_comments
            ?.data_sources,
        ][0],
      );
  }, [dashboardDynamicData]);

  const applyFilter = (
    startDate,
    endDate,
    activeFilterParams,
    pageNumber,
    isAppliedFilter,
  ) => {
    var selectedFilterParams = //this is to connect filter params with date change and vise versa
      activeFilterParams !== undefined
        ? activeFilterParams
        : !isAppliedFilter
          ? assignActiveFilters
          : filterParams;
    setPageNumber(1);
    setResetAllFields((prev) => prev + 1);
    setStartDate(startDate);
    setEndDate(endDate);
    getDashboardsData(
      startDate,
      endDate,
      dashboardActiveTab,
      dashboardID,
      selectedFilterParams,
      pageNumber,
    );
    setAssignActiveFilters(selectedFilterParams);
  };

  const changePagination = (pageNumber, totalPage, maximumData) => {
    if (totalPage !== undefined) {
      setTotalPage(totalPage);
      setMaxData(maximumData);
    }
    setPageNumber(pageNumber);
    applyFilter(startDate, endDate, undefined, pageNumber); //sending filters as undefined is handled inside applyfilter fn
  };

  const {
    modalBody,
    filterName,
    getSavedFilterData,
    handleShowSaveAndApplyModal,
    handleClearFilterParams,
    filterParams,
    setFilterParams,
    filterFields,
    getFiltersFileds,
    savedFilters,
    setSavedFilterId,
    savedFilterId,
    setShowDeleteFilterModal,
    setAssignActiveFilters,
    assignActiveFilters,
    setFilterName,
    showSaveAndApplyModal,
    handleCloseApplySaveFilter,
    handleApplySaveFilter,
    showDeleteFilterModal,
    handleCloseDeleteFilterModal,
    handleDeleteFilter,
    handleCloseSnackBar,
    snackBarDetails,
    showSnackBar,
  } = FiltersFunctions(
    dashboardID,
    dashboardActiveTab,
    startDate,
    endDate,
    applyFilter,
  );
  const unixDate = {
    start: startDate,
    end: endDate,
  };

  const childProps = {
    applyFilter,
    singleTweetAnalysis,
    handleShowSaveAndApplyModal,
    resetAllFields,
    startDate,
    endDate,
    filterFields,
    assignActiveFilters, //showing active selected filters on top of the page
    allActiveFilters: getActiveParams(assignActiveFilters, filterFields), //showing active selected filters on top of the page
    showFilterBtn,
    savedFilters,
    getSavedFilterData,
    setShowDeleteFilterModal,
    handleClearFilterParams,
    filterParams,
    setFilterParams,
    setSavedFilterId,
    savedFilterId,
    setAssignActiveFilters,
    monitorType,
    activeTab,
    setActiveTab,
    setFilterName,
    filterName,
    dashboardDynamicData,
    dashboardActiveTab,
    dashboardID,
    widgetsName,
    getDashboardWidgetsName,
    dynamicDataLoader,
    insightsID,
    insightsResponse,
    dates,
    response,
    pageNumber,
    setPageNumber,
    changePagination,
    setTotalPage,
    totalPage,
    setMaxData,
    maxData,
    totalPageTw,
    setTotalPageTw,
    totalPageFb,
    setTotalPageFb,
    totalPageIg,
    setTotalPageIg,
    totalPageNb,
    setTotalPageNb,
    activePostsTab,
    setIsActivePostsTab,
    isPublicAccount,
    unixDate,
  };

  return (
    <div>
      <DashboardSideBarAndHeader {...childProps} />
      <DashboardPages {...childProps} />
      {showSaveAndApplyModal && (
        <PopupModal
          title={CheckValueLocale(
            "dashboard_save_filter_and_apply",
            "",
            {},
            intl,
          )}
          body={modalBody}
          leftBtn={CheckValueLocale("dashboard_cancel", "", {}, intl)}
          rightBtn={CheckValueLocale(
            "dashboard_save_filter_and_apply",
            "",
            {},
            intl,
          )}
          open={showSaveAndApplyModal}
          close={handleCloseApplySaveFilter}
          accept={() => handleApplySaveFilter(savedFilterId)}
          addClasses="model-buttons"
          classeName="save-apply-modal"
          modalStyle="save-apply-modal-body"
        />
      )}
      {/* ________________________________________________________________________________________ */}

      {showDeleteFilterModal && (
        <PopupModal
          title={CheckValueLocale(
            "dashboard_delete_saved_filter",
            "",
            {},
            intl,
          )}
          body={CheckValueLocale(
            "dashboard_delete_filter_confirmation",
            "",
            {
              filterName: filterName,
            },
            intl,
          )}
          leftBtn={CheckValueLocale("dashboard_cancel", "", {}, intl)}
          rightBtn={CheckValueLocale("dashboard_delete_filter", "", {}, intl)}
          open={showDeleteFilterModal}
          close={handleCloseDeleteFilterModal}
          accept={() => handleDeleteFilter(savedFilterId)}
          addClasses="model-buttons"
          classeName="save-apply-modal"
          modalStyle="save-apply-modal-body"
          warning={true}
        />
      )}

      {showSnackBar && (
        <SnackBar
          open={showSnackBar}
          handleClose={handleCloseSnackBar}
          severity={snackBarDetails.severity}
          message={snackBarDetails.message}
          title={snackBarDetails.title}
        />
      )}
    </div>
  );
};

export default MainDashboardPage;
