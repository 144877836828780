import { useMutation } from "@tanstack/react-query";
import { MainMonitorsPage } from "services/api/mainMonitorsPage";

export const useSocialMediaMonitorData = () => {
  return useMutation({
    mutationFn: ({ monitor_id, monitorDataSource, queryData }) =>
      MainMonitorsPage.getSocialMediaMonitorData({
        monitor_id,
        monitorDataSource,
        queryData,
      }),
  });
};
