import { useEffect } from "react";
import { useIntl } from "react-intl";
import {
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  OutlinedInput,
  ListItemText,
  InputAdornment,
} from "@mui/material";

import {
  CheckValueLocale,
  getSocialIcon,
  retrieveActiveProductsArray,
  truncate,
} from "utils/helpers";
import FormHelperError from "./formHelperError";
import CircularDottedLoading from "components/circularDottedLoading/circularLoading";
import { XTopTrendsFormSection } from "./xTopTrendsFormSection";

const MainAlertsFields = ({
  alertName,
  formFieldErrors,
  handleInputChange,
  productType,
  setProductType,
  alertsInfo,
  datasourceType,
  setDatasourceType,
  alertType,
  setAlertType,
  alertRateType,
  setAlertRateType,
  selectedMonitors,
  handleChangeMultipleSelection,
  monitorsList,
  recieveAlertsHourly,
  handleRecieveAlertsHourly,
  alertThresholdType,
  setAlertThresholdType,
  thresholdManualValue,
  handleChangeAlertsFields,
  isNegavtivePostsType,
  alertsSources,
  alertsTypes,
  alertRates,
  MenuProps,
  alertThresold,
  alertTypeLocale,
  isManualAlert,
  isHourlyType,
  getPostsTypeTitle,
  activeAlertType,
  handleMinimumNoFollowrs,
  minimumNoFollowers,
  isInfluncerType,
  isCreateAlert,
  splitDataSourceType,
  filteredAlertsData,
  activeProductName,
  monitorsLoading,
  isViralTweetType,
  isInstantType,
  minimumNoInteractions,
  handleMinimumNoInteractions,
  alertsConversationTypes,
  alertConversationType,
  setAlertConversationType,
  selectedSources,
  setSelectedSources,
  sourcesListEngagements,
  sourcesLoadingEngagements,
  handleChangeSourcesEngagements,
  isMonitorAction,
  monitorData,
  countriesList = [],
  selectedCountries,
  setSelectedCountries,
  handleXTrendKeywordsSubmit,
  handleXTrendKeywordsChange,
  selectedXTrendKeywords,
  xTrendKeywords,
  setKeywordsFocused,
  handleInputMouseDown,
  keywordsFocused,
  handleDeleteXTrendKeyword,
  setSelectedXTrendKeywords,
  setCountriesList,
  setXTrendKeywords,
  isFromTopTrends,
}) => {
  const intl = useIntl();

  let showAllSourcesTiltle =
    activeProductName === "cxm"
      ? "alerts_all_channels"
      : activeProductName === "survey"
        ? "alerts_all_surveys"
        : "alerts_all_monitor";

  //comming soon in create alert
  let datasourcesExclued = ["instagram[dm]"];
  let checkCommingSoonDatasources = (datasourceName) => {
    return datasourcesExclued?.includes(datasourceName);
  };
  let isSMEnabled = retrieveActiveProductsArray()?.includes("SM");
  let isCxmEnabled = retrieveActiveProductsArray()?.includes("CXM");
  const getDatasourceLocale = (datasourceValue) => {
    return checkCommingSoonDatasources(datasourceValue)
      ? `${datasourceValue}_comming_soon_alerts`
      : datasourceValue === "googlemybusiness"
        ? `${datasourceValue}_alerts`
        : datasourceValue;
  };

  const getConverstionTypesVals = () => {
    let bothIds = [];
    alertsConversationTypes?.map((el) => {
      bothIds.push(el?.id);
    });
    let converstionTypes = [];
    if (alertsConversationTypes?.length) {
      converstionTypes = [
        {
          id: bothIds?.toString(),
          name: "both_posts_dm",
        },
        ...alertsConversationTypes,
      ];
    }

    return converstionTypes;
  };
  const filteredArrayConversationType = getConverstionTypesVals()?.filter(
    (item) => item.id == alertConversationType,
  );
  let filteredNameConversation =
    filteredArrayConversationType?.length &&
    filteredArrayConversationType[0]?.name?.toLowerCase();
  let channelsAlertsEngagements = sourcesListEngagements?.channels;
  let monitorsAlertsEngagements = sourcesListEngagements?.monitors;
  let isMonitorsChannelsEnabled =
    monitorsAlertsEngagements &&
    channelsAlertsEngagements &&
    !monitorsAlertsEngagements?.length &&
    !channelsAlertsEngagements?.length &&
    filteredNameConversation != "direct message" &&
    isSMEnabled &&
    isCxmEnabled;

  const isXTopTrendsSelected = activeAlertType === "x top trends";

  return (
    <>
      <Grid item xs={6}>
        <Box className="input-form-control">
          <InputLabel className="input-title-label-field">
            {CheckValueLocale("alert_form_title", "", {}, intl)}
            <Typography component="span" className="required">
              *
            </Typography>
          </InputLabel>
          <TextField
            fullWidth
            id="fullWidth"
            className="alert-title-field alert-text-field-item"
            placeholder={CheckValueLocale("alert_title", "", {}, intl)}
            error={formFieldErrors?.name ? true : false}
            value={alertName}
            onChange={(e) => handleInputChange(e)}
            autoFocus
          />
        </Box>
        <FormHelperError value={formFieldErrors?.name} />
      </Grid>
      <Grid item xs={6}>
        <Box className="input-form-control">
          <InputLabel className="input-title-label-field">
            {CheckValueLocale("alert_product_type_title", "", {}, intl)}
            <Typography component="span" className="required">
              *
            </Typography>
          </InputLabel>
          <Select
            value={productType}
            onChange={(e) => {
              setProductType(e.target?.value);
              setDatasourceType("");
              setAlertType("");
              setAlertRateType("");
              setAlertConversationType("");
              setCountriesList([]);
              setSelectedCountries([]);
              setXTrendKeywords("");
              setSelectedXTrendKeywords([]);
            }}
            fullWidth
            className="alert-form-select alert-text-field-item"
            labelId="alert-product-select-label"
            id="alert-product-simple-select"
            displayEmpty
            disabled={
              !alertsInfo?.length ||
              !isCreateAlert ||
              isMonitorAction ||
              isFromTopTrends
            }
          >
            <MenuItem disabled value="" className="alert-field-info">
              {CheckValueLocale("select_placeholder", "", {}, intl)}
            </MenuItem>
            {alertsInfo?.length &&
              alertsInfo?.map((val) => (
                <MenuItem
                  className="alert-form-field"
                  key={`product-type-${val?.key}`}
                  value={val?.id}
                  id={`alert-product-type-${val?.name ?? "alert-info"}`}
                >
                  {CheckValueLocale(val?.name, "", {}, intl)}
                </MenuItem>
              ))}
          </Select>
        </Box>
      </Grid>
      {activeProductName !== "survey" && activeProductName !== "engagements" ? (
        <Grid item xs={6}>
          <Box className="input-form-control">
            <InputLabel className="input-title-label-field">
              {CheckValueLocale("alert_datasource_type_title", "", {}, intl)}
              <Typography component="span" className="required">
                *
              </Typography>
            </InputLabel>
            <Select
              value={datasourceType}
              onChange={(e) => {
                setDatasourceType(e.target.value);
                setAlertType("");
                setAlertRateType("");
              }}
              fullWidth
              className="alert-form-select alert-text-field-item"
              labelId="alert-datasource-type-select-label"
              id="alert-datasource-type-simple-select"
              displayEmpty
              disabled={
                productType === "" ||
                !alertsSources?.length ||
                !isCreateAlert ||
                isFromTopTrends ||
                (isMonitorAction &&
                  monitorData?.attributes?.data_sources?.length === 1)
                  ? true
                  : false
              }
            >
              <MenuItem disabled value="" className="alert-field-info">
                {CheckValueLocale("select_placeholder", "", {}, intl)}
              </MenuItem>
              {alertsSources
                ?.filter((source) => {
                  if (isMonitorAction)
                    return monitorData?.attributes?.data_sources?.includes(
                      source?.name,
                    );
                  else return true;
                })
                ?.map((val) => (
                  <MenuItem
                    className="alert-form-field"
                    key={`datasource-type-${val?.id}`}
                    value={`${val?.id}-${val?.name}-${val?.channel_type_id}`}
                    id={`alert-datasource-type-${val?.name ?? "alert-source"}`}
                    disabled={checkCommingSoonDatasources(
                      val?.name?.toLowerCase(),
                    )}
                  >
                    {CheckValueLocale(
                      getDatasourceLocale(val?.name?.toLowerCase()),
                      "",
                      {},
                      intl,
                    )}
                  </MenuItem>
                ))}
            </Select>
          </Box>
        </Grid>
      ) : null}

      {activeProductName !== "survey" ? (
        <Grid item xs={6}>
          <Box className="input-form-control">
            <InputLabel className="input-title-label-field">
              {CheckValueLocale("alert_form_type", "", {}, intl)}
              <Typography component="span" className="required">
                *
              </Typography>
            </InputLabel>
            <Select
              value={alertType}
              onChange={(e) => {
                setAlertType(e.target.value);
                setAlertRateType("");
                setCountriesList([]);
                setSelectedCountries([]);
                setXTrendKeywords("");
                setSelectedXTrendKeywords([]);
              }}
              fullWidth
              className="alert-form-select alert-text-field-item"
              labelId="alert-type-select-label"
              id="alert-type-simple-select"
              displayEmpty
              disabled={
                datasourceType === "" || !alertsTypes?.length
                  ? true
                  : false || !isCreateAlert || isFromTopTrends
              }
            >
              {/* TODO ALERT TYPE WILL SHOW HERE */}
              <MenuItem disabled value="" className="alert-field-info">
                {CheckValueLocale("select_placeholder", "", {}, intl)}
              </MenuItem>
              {alertsTypes?.map((val) => (
                <MenuItem
                  className="alert-form-field"
                  key={`alert-type-${val?.id}`}
                  value={val?.id}
                  id={`alert-type-${val?.name ?? "alert-type"}`}
                >
                  {CheckValueLocale(
                    alertTypeLocale(
                      val?.name,
                      splitDataSourceType()?.name?.toLowerCase(),
                    ),
                    "",
                    {},
                    intl,
                  )}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>
      ) : null}

      {isXTopTrendsSelected ? (
        <XTopTrendsFormSection
          countriesList={countriesList}
          selectedCountries={selectedCountries}
          setSelectedCountries={setSelectedCountries}
          xTrendKeywords={xTrendKeywords}
          handleXTrendKeywordsSubmit={handleXTrendKeywordsSubmit}
          setKeywordsFocused={setKeywordsFocused}
          handleXTrendKeywordsChange={handleXTrendKeywordsChange}
          handleInputMouseDown={handleInputMouseDown}
          keywordsFocused={keywordsFocused}
          isCreateAlert={isCreateAlert}
          selectedXTrendKeywords={selectedXTrendKeywords}
          handleDeleteXTrendKeyword={handleDeleteXTrendKeyword}
          setSelectedXTrendKeywords={setSelectedXTrendKeywords}
        />
      ) : null}
      {/* in case engagements*/}
      {activeProductName === "engagements" ? (
        <Grid item xs={6}>
          <Box className="input-form-control">
            <InputLabel className="input-title-label-field">
              {CheckValueLocale("alert_converstion_form_type", "", {}, intl)}
              <Typography component="span" className="required">
                *
              </Typography>
            </InputLabel>
            <Select
              value={alertConversationType}
              onChange={(e) => {
                setAlertConversationType(e.target.value);
              }}
              fullWidth
              className="alert-form-select alert-text-field-item"
              labelId="alert-type-select-label"
              id="alert-type-simple-select"
              displayEmpty
              disabled={
                alertType === "" || !alertsConversationTypes?.length
                  ? true
                  : false || !isCreateAlert
              }
            >
              <MenuItem disabled value="" className="alert-field-info">
                {CheckValueLocale("select_conversation_type", "", {}, intl)}
              </MenuItem>

              {getConverstionTypesVals()?.map((val) => (
                <MenuItem
                  className="alert-form-field"
                  key={`alert-type-${val?.id}`}
                  value={val?.id}
                  id={`alert-type-${val?.name ?? "alert-type"}`}
                >
                  {CheckValueLocale(
                    `${val?.name?.toLowerCase()}_alertv2`,
                    "",
                    {},
                    intl,
                  )}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>
      ) : null}

      {activeProductName !== "survey" &&
      activeProductName !== "engagements" &&
      !isXTopTrendsSelected ? (
        <Grid item xs={6}>
          <Box className="input-form-control">
            <InputLabel className="input-title-label-field">
              {CheckValueLocale("alert_rate_title", "", {}, intl)}
              <Typography component="span" className="required">
                *
              </Typography>
            </InputLabel>
            <Select
              value={alertRateType}
              onChange={(e) => setAlertRateType(e.target.value)}
              fullWidth
              className="alert-form-select alert-text-field-item"
              labelId="alert-rate-type-select-label"
              id="alert-rate-type-simple-select"
              displayEmpty
              disabled={
                alertType === "" || !alertRates?.length || !isCreateAlert
                  ? true
                  : false
              }
            >
              <MenuItem disabled value="" className="alert-field-info">
                {CheckValueLocale("select_placeholder", "", {}, intl)}
              </MenuItem>
              {alertRates?.map((val) => (
                <MenuItem
                  className="alert-form-field"
                  key={`alert-type-${val}`}
                  value={val?.toLowerCase()}
                  id={`alert-rate-type-${
                    val?.toLowerCase() ?? "alert-rate-type"
                  }`}
                >
                  {CheckValueLocale(
                    `${val?.toLowerCase()}_alert`,
                    "",
                    {},
                    intl,
                  )}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>
      ) : null}
      {activeProductName === "survey" ? (
        <Grid item xs={12}>
          <Box className="input-form-control">
            <InputLabel className="input-title-label-field">
              {CheckValueLocale("alert_form_type", "", {}, intl)}
              <Typography component="span" className="required">
                *
              </Typography>
            </InputLabel>
            <TextField
              disabled
              fullWidth
              id="fullWidth"
              className="alert-title-field alert-type-survey-field alert-text-field-item"
              placeholder={CheckValueLocale("alert_select_type", "", {}, intl)}
              value={CheckValueLocale(`new_post_alerts_survey`, "", {}, intl)}
            />
          </Box>
        </Grid>
      ) : null}
      {activeProductName !== "engagements" && !isXTopTrendsSelected ? (
        <Grid item xs={activeProductName !== "survey" ? 6 : 12}>
          <Box className="input-form-control">
            <InputLabel className="input-title-label-field">
              {CheckValueLocale("alerts_sources_title", "", {}, intl)}
              <Typography component="span" className="required">
                *
              </Typography>
            </InputLabel>
            <Select
              id="monitors-select"
              fullWidth
              multiple
              value={selectedMonitors}
              onChange={(e) => handleChangeMultipleSelection(e, monitorsList)}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                return selected?.length === 0
                  ? CheckValueLocale("select_placeholder", "", {}, intl)
                  : selected?.length > 0
                    ? selected?.map((item) => item?.name)?.join(", ")
                    : selected?.map((item) => item?.name);
              }}
              MenuProps={MenuProps}
              className="form-select alert-text-field-item"
              disabled={
                alertRateType === "" ||
                (!monitorsList?.length && !monitorsLoading) ||
                !isCreateAlert ||
                isMonitorAction
                  ? true
                  : false
              }
              displayEmpty
            >
              {isMonitorAction ? (
                <MenuItem
                  className="alert-monitors-menuItem alert-form-field"
                  value={monitorData?.id}
                  checked={true}
                >
                  {monitorData?.attributes?.name}
                </MenuItem>
              ) : null}
              {monitorsLoading ? (
                <CircularDottedLoading />
              ) : (
                <MenuItem
                  className="alert-monitors-menuItem alert-form-field"
                  value={"all"}
                  checked={selectedMonitors?.length === monitorsList?.length}
                >
                  <Checkbox
                    checked={selectedMonitors?.length === monitorsList?.length}
                  />
                  <ListItemText
                    primary={CheckValueLocale(
                      showAllSourcesTiltle,
                      "",
                      {},
                      intl,
                    )}
                  />
                </MenuItem>
              )}
              {!isMonitorAction &&
                !monitorsLoading &&
                monitorsList?.map((val) => (
                  <MenuItem
                    className="alert-monitors-menuItem alert-form-field"
                    key={`monitors-${val?.id}`}
                    id={val?.id ?? "alert-monitor-list-id"}
                    value={val}
                  >
                    <Checkbox
                      checked={
                        selectedMonitors?.findIndex(
                          (item) => +item?.id === +val?.id,
                        ) >= 0
                      }
                    />
                    <ListItemText primary={val?.name} />
                  </MenuItem>
                ))}
            </Select>
          </Box>
        </Grid>
      ) : null}

      {/* in case enggements */}
      {activeProductName === "engagements" ? (
        <Grid item xs={activeProductName !== "survey" ? 6 : 12}>
          <Box className="input-form-control">
            <InputLabel className="input-title-label-field">
              {CheckValueLocale("alerts_sources_title", "", {}, intl)}:
              <Typography component="span" className="required">
                *
              </Typography>
            </InputLabel>

            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              fullWidth
              value={selectedSources}
              onChange={(e) => handleChangeSourcesEngagements(e)}
              input={<OutlinedInput />}
              className="form-select alert-text-field-item"
              disabled={
                alertConversationType === "" ||
                !isCreateAlert ||
                sourcesLoadingEngagements
                  ? true
                  : false
              }
              displayEmpty
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: { sm: 150, xs: 150, md: 220, lg: 220, xl: 220 },
                  },
                },
              }}
              renderValue={() =>
                CheckValueLocale(
                  selectedSources?.length
                    ? "num_select"
                    : "select_source_alerts",
                  "",
                  {
                    num: selectedSources?.length,
                  },
                  intl,
                )
              }
            >
              {/* in case no channels & monitors  */}
              {isMonitorsChannelsEnabled ? (
                <Box className="empty-design-monitors-engagement">
                  {CheckValueLocale(
                    "empty_monitors_channels_engagement_alerts",
                    "",
                    {},
                    intl,
                  )}
                </Box>
              ) : null}
              {monitorsAlertsEngagements &&
              filteredNameConversation != "direct message" &&
              !isMonitorsChannelsEnabled &&
              isSMEnabled ? (
                <MenuItem className="alert-monitors-menuItem alert-form-field alert-sources-menu-title">
                  {CheckValueLocale("monitors_engagements", "", {}, intl)}
                </MenuItem>
              ) : null}
              {monitorsAlertsEngagements?.length && isSMEnabled ? (
                monitorsAlertsEngagements?.map((item, i) => (
                  <MenuItem
                    key={`${item?.data?.attributes?.id}-${i}`}
                    value={item?.data?.attributes?.id}
                    id={item?.data?.attributes?.id ?? "alert-monitor-list-id"}
                    className="alert-monitors-menuItem alert-form-field alert-sources-engagement-menu-Item "
                  >
                    <Checkbox
                      checked={selectedSources?.includes(
                        item?.data?.attributes?.id,
                      )}
                    />
                    <ListItemText
                      primary={truncate(item?.data?.attributes?.name, 23)}
                    />
                    <Typography className="social-icons-alerts-engagement">
                      {item?.data?.attributes?.data_sources?.length &&
                        item?.data?.attributes?.data_sources?.map((type) => {
                          return getSocialIcon(type);
                        })}
                    </Typography>
                  </MenuItem>
                ))
              ) : monitorsAlertsEngagements &&
                filteredNameConversation != "direct message" &&
                !isMonitorsChannelsEnabled ? (
                <Box className="empty-design-monitors-engagement">
                  {CheckValueLocale(
                    "empty_monitors_engagement_alerts",
                    "",
                    {},
                    intl,
                  )}
                </Box>
              ) : null}

              {channelsAlertsEngagements &&
              (channelsAlertsEngagements?.length == 0 ||
                channelsAlertsEngagements?.length) &&
              !isMonitorsChannelsEnabled &&
              isCxmEnabled ? (
                <MenuItem className="alert-monitors-menuItem alert-form-field alert-sources-menu-title">
                  {CheckValueLocale("channels_engagements", "", {}, intl)}
                </MenuItem>
              ) : null}
              {channelsAlertsEngagements?.length && isCxmEnabled ? (
                channelsAlertsEngagements?.map((item, i) => (
                  <MenuItem
                    key={`${item?.data?.attributes?.id}-${i}`}
                    value={item?.data?.attributes?.id}
                    id={item?.data?.attributes?.id ?? "alert-monitor-list-id"}
                    className="alert-monitors-menuItem alert-form-field alert-sources-engagement-menu-Item "
                  >
                    <Checkbox
                      checked={selectedSources?.includes(
                        item?.data?.attributes?.id,
                      )}
                    />
                    <ListItemText
                      primary={truncate(item?.data?.attributes?.name, 23)}
                    />
                    <Typography className="social-icons-alerts-engagement">
                      {item?.data?.attributes?.data_sources?.length &&
                        item?.data?.attributes?.data_sources?.map((type) => {
                          return getSocialIcon(
                            item?.data?.attributes?.is_dm
                              ? `${type}_private`
                              : type,
                          );
                        })}
                    </Typography>
                  </MenuItem>
                ))
              ) : channelsAlertsEngagements &&
                !channelsAlertsEngagements?.length &&
                !isMonitorsChannelsEnabled ? (
                <Box className="empty-design-monitors-engagement">
                  {CheckValueLocale(
                    "empty_channels_engagement_alerts",
                    "",
                    {},
                    intl,
                  )}
                </Box>
              ) : null}
            </Select>
          </Box>
        </Grid>
      ) : null}

      {isInfluncerType ? (
        <Grid item xs={6}>
          <Box className="input-form-control">
            <InputLabel className="input-title-label-field">
              {CheckValueLocale("alert_form_number_followers", "", {}, intl)}
              <Typography component="span" className="required">
                *
              </Typography>
            </InputLabel>
            <TextField
              type="number"
              id="outlined-basic"
              variant="outlined"
              className="alert-minimum-no-follower-field alert-text-field-item"
              value={minimumNoFollowers}
              inputProps={{ min: 2500 }}
              error={formFieldErrors?.mimimumNoFollowers !== ""}
              onChange={(e) => {
                handleMinimumNoFollowrs(e);
              }}
              disabled={
                !selectedMonitors?.length || !isCreateAlert ? true : false
              }
            />
          </Box>
          <FormHelperError value={formFieldErrors?.mimimumNoFollowers} />
        </Grid>
      ) : null}
      {/*min no of interactions in case of viral Tweet & instant type*/}
      {isViralTweetType && isInstantType ? (
        <Grid item xs={6}>
          <Box className="input-form-control">
            <InputLabel className="input-title-label-field">
              {CheckValueLocale(
                "alert_form_min_number_interactions",
                "",
                {},
                intl,
              )}
              <Typography component="span" className="required">
                *
              </Typography>
            </InputLabel>
            <TextField
              type="number"
              id="outlined-basic"
              variant="outlined"
              className="alert-minimum-no-follower-field alert-text-field-item"
              value={minimumNoInteractions}
              inputProps={{ min: 1 }}
              error={
                formFieldErrors?.mimimumNoInteractions !== "" ||
                minimumNoInteractions?.length == 0 ||
                minimumNoInteractions == 0
              }
              onChange={(e) => {
                handleMinimumNoInteractions(e);
              }}
              disabled={
                !selectedMonitors?.length || !isCreateAlert ? true : false
              }
            />
          </Box>
          <FormHelperError value={formFieldErrors?.mimimumNoInteractions} />
        </Grid>
      ) : null}

      {isHourlyType ? (
        <Grid item xs={6}>
          <Box className="input-form-control">
            <InputLabel className="input-title-label-field">
              {CheckValueLocale("alerts_recieve_every_title", "", {}, intl)}
              <Typography component="span" className="required">
                *
              </Typography>
            </InputLabel>
            <Box className="recieve-alert-field-container">
              <TextField
                type="number"
                id="outlined-basic"
                className="alert-recieve-every-field alert-text-field-item"
                variant="outlined"
                value={recieveAlertsHourly}
                inputProps={{ min: 1, max: 24 }}
                error={formFieldErrors?.recieveAlertsHourly !== ""}
                onChange={(e) => {
                  handleRecieveAlertsHourly(e);
                }}
                disabled={
                  !selectedMonitors?.length || !isCreateAlert ? true : false
                }
              />
              <Typography component="span">
                {CheckValueLocale("hours", "", {}, intl)}
              </Typography>
            </Box>
          </Box>
          <FormHelperError value={formFieldErrors?.recieveAlertsHourly} />
        </Grid>
      ) : null}

      {isHourlyType && alertThresold?.length ? (
        <Grid item xs={12} className="alert-threshold-container">
          <FormControl fullWidth>
            <FormLabel
              id="alert-threshold-radio-buttons-group-label"
              className="threshold-option-label"
            >
              {CheckValueLocale("alert_form_threshold", "", {}, intl)}:
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="alert-threshold-radio-buttons-group-label"
              name="alert-threshold-radio-buttons-group"
              onChange={(e) => setAlertThresholdType(e.target.value)}
              value={alertThresholdType}
              disabled={!isCreateAlert}
            >
              {alertThresold?.map((val) => {
                return (
                  <FormControlLabel
                    className="alert-threshold-input-value"
                    value={val?.toLowerCase()}
                    disabled={!isCreateAlert}
                    control={
                      <Radio
                        checked={val?.toLowerCase() === alertThresholdType}
                        disabled={!isCreateAlert}
                      />
                    }
                    label={CheckValueLocale(
                      `alert_${val?.toLowerCase()}`,
                      "",
                      {},
                      intl,
                    )}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
          <FormHelperError value={formFieldErrors?.threshold} />
        </Grid>
      ) : null}

      {isManualAlert ? (
        <Grid item xs={6}>
          <Box className="input-form-control">
            <InputLabel className="input-title-label-field">
              {CheckValueLocale(getPostsTypeTitle(), "", {}, intl)}
              <Typography component="span" className="required">
                *
              </Typography>
            </InputLabel>
            <FormGroup>
              <TextField
                className={`${
                  !isNegavtivePostsType ? "alert-manaul-increase-rate" : ""
                } alert-manaul-increase-rate-field alert-text-field-item`}
                variant="outlined"
                type="number"
                fullWidth
                name="alertIncreaseRate"
                InputProps={{
                  startAdornment: !isNegavtivePostsType ? (
                    <InputAdornment position="start">%</InputAdornment>
                  ) : null,
                  min: 1,
                  max: 100000,
                }}
                value={thresholdManualValue}
                onChange={(e) => handleChangeAlertsFields(e)}
                error={formFieldErrors?.thresholdManualVal ? true : false}
                disabled={!isCreateAlert}
              />
            </FormGroup>
            <FormHelperError value={formFieldErrors?.thresholdManualVal} />
          </Box>
        </Grid>
      ) : null}
    </>
  );
};
export default MainAlertsFields;
