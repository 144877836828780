import { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import "react-intl-tel-input/dist/main.css";
import { CheckValueLocale } from "utils/helpers";
import "./editUser.scss";
import { useNavigate, useParams } from "react-router-dom";
import { MaineEditCard } from "./components/MainEditCard";
import EditHeader from "./components/editHeader";
import CircularLoading from "components/circularProgress";
import { TeamsAndTabs } from "./components/teamsAndTabs";
import {
  advancedInfoTabsAdapter,
  countAdvancedTabsDataDifferences,
  countInfoTabsDataDifferences,
  inboxDataAdapter,
  isTeamsDifferent,
} from "./adapterFunctions";
import { EditUserFooter } from "./components/editUserFooter";
import _ from "lodash";
import SnackBar from "components/snackBar.js";
import { generateUserPayload } from "../addNewUser/sharedFunction/inviteUserAdapter";
import { useSaveEditUser } from "../../hooks/useSaveEditUser";
import { useGetUserView } from "../../hooks/useGetUserView";
import ProductsPermissions from "../addNewUser/components/productsPermissions/productsPermissions";
import AccessTable from "../addNewUser/components/accessTables/accessTable";
import useAddNewUserOrEdit from "../addNewUser/sharedFunction/useAddNewUserOrEdit";
import {
  initialAccTableSelectedData,
  userFormInitialValues,
} from "../addNewUser/sharedFunction/initialValuesAddNewUser";

const EditUser = () => {
  const intl = useIntl();
  const { id } = useParams();
  const [numberOfChanges, setNumberOfChanges] = useState(0);
  const [numberOfChangesTables, setNumberOfChangesTables] = useState(0);
  const [openSnackBar, setOpenSnackBar] = useState({
    open: false,
    tit: null,
    msg: null,
  });
  const originalUserData = useRef({});
  const navigate = useNavigate();

  const [isEngagAcc, setIsEngagAcc] = useState(false);
  const {
    formik,
    stepTwoData,
    setStepTwoData,
    accTableData,
    setAccTableData,
    accTableSelectedData,
    setAccTableSelectedData,
    accTableDetailsData,
    setAccTableDetailsData,
    tableCols,
    advancedTabsData,
    setAdvancedTabsData,
    infoTabsData,
    setInfoTabsData,
    tabsErrors,
    setTabsErrors,
    teamsData,
    setTeamsData,
    getTablesData,
    nextLoading,
    accEngagId,
  } = useAddNewUserOrEdit(id);

  const [accTableIntialData, setAccTableIntialData] = useState(
    _.cloneDeep(initialAccTableSelectedData),
  );

  const [stepTwoIntialData, setStepTwoIntialData] = useState({});
  const [stepOneIntialData, setStepOneIntialData] = useState(
    _.cloneDeep(userFormInitialValues),
  );

  const handleCancel = () => {
    navigate("/settings/user_managements");
  };

  //to handle Data from backend
  const {
    data: userViewInfo,
    isError: isUserViewInfoError,
    isPending: isUserViewInfoPending,
  } = useGetUserView(id);

  const [userInfoDone, setUserInfoDone] = useState(false);
  useEffect(() => {
    if (!isUserViewInfoError && !!userViewInfo) {
      formik?.setValues({
        id: id,
        name: `${userViewInfo?.included?.[0]?.attributes?.first_name?.toString()} ${userViewInfo?.included?.[0]?.attributes?.last_name?.toString()}`,
        first_name:
          userViewInfo?.included?.[0]?.attributes?.first_name?.toString(),
        last_name:
          userViewInfo?.included?.[0]?.attributes?.last_name?.toString(),
        mail: userViewInfo?.data?.attributes?.email?.toString(),
        phone:
          userViewInfo?.included?.[0]?.attributes?.phone_number?.toString(),
        job_title:
          userViewInfo?.included?.[0]?.attributes?.job_title?.toString(),
        avatar: userViewInfo?.data?.attributes?.avatar,
      });
      setStepOneIntialData({
        first_name:
          userViewInfo?.included?.[0]?.attributes?.first_name?.toString(),
        last_name:
          userViewInfo?.included?.[0]?.attributes?.last_name?.toString(),
        mail: userViewInfo?.data?.attributes?.email?.toString(),
        phone:
          userViewInfo?.included?.[0]?.attributes?.phone_number?.toString(),
        job_title:
          userViewInfo?.included?.[0]?.attributes?.job_title?.toString(),
        avatar: userViewInfo?.data?.attributes?.avatar,
      });
      const data = userViewInfo?.data?.attributes;
      const advancedTabsData = advancedInfoTabsAdapter(data);
      const inboxTabsData = inboxDataAdapter(data);
      setTeamsData({
        teamsSelected: data?.user_teams || [],
        teamsOptions: [],
      });
      setAdvancedTabsData(advancedTabsData);
      setInfoTabsData(inboxTabsData);
      originalUserData.current = {
        teams: data?.user_teams || [],
        // deep copy of data
        advancedTabsData: JSON.parse(JSON.stringify(advancedTabsData)),
        infoTabsData: JSON.parse(JSON.stringify(inboxTabsData)),
      };

      //permissions
      let productNames = {
        GENERAL: "general_setting",
        CXM: "channel_analytics",
        SM: "social_listening",
        SURVEY: "surveys",
        CDP: "audience",
        ENGAGEMENTS: "engagement",
        PUBLIC_API: "lucidya_api",
        AI_API: "lucidya_api",
        AI_AGENT: "ai_agent",
      };

      let roleMapper = {
        product_admin: "manager",
        viewer: "viwer",
        user: "moderator",
        team_leader_egag: "team_leader",
        agent_egag: "agent_egagment",
        no_access_egag: "no_acc",
      };

      let selectedObj = {
        general_setting: "moderator",
        social_listening: "moderator",
        channel_analytics: "moderator",
        surveys: "moderator",
        audience: "moderator",
        engagement: "no_acc",
        lucidya_api: "moderator",
        ai_agent: "no_acc",
      };

      userViewInfo?.data?.attributes?.permissions?.map((item) => {
        selectedObj[productNames[item?.product]] =
          roleMapper[
            item?.product == "ENGAGEMENTS" || item?.product == "AI_AGENT"
              ? `${item?.role}_egag`
              : item?.role
          ];
      });
      setStepTwoIntialData({ ...selectedObj });
      setIsEngagAcc(selectedObj["engagement"] !== "no_acc");
      setUserInfoDone(!userInfoDone);
      setStepTwoData({ ...selectedObj });

      //acc table engag
      let objIntial = {
        engagement_features: {
          engag_acc_title: [],
          engag_tabs: [],
          saved_reps_mang: [],
        },
        account_access: {},
        monitors_access: {},
        channels_access: {},
      };
      if (
        userViewInfo?.data?.attributes?.engagements_permissions
          ?.engagement_features?.length
      ) {
        userViewInfo?.data?.attributes?.engagements_permissions?.engagement_features?.map(
          (item) => {
            if (item?.feature_name === "ENGAGEMENT_ACCESS") {
              objIntial.engagement_features.engag_acc_title =
                item?.feature_permissions;
            } else if (item?.feature_name === "ENGAGEMENT_TABS") {
              objIntial.engagement_features.engag_tabs =
                item?.feature_permissions;
            } else {
              objIntial.engagement_features.saved_reps_mang =
                item?.feature_permissions;
            }
          },
        );
      }

      if (
        userViewInfo?.data?.attributes?.engagements_permissions?.accounts_access
          ?.data?.length
      ) {
        userViewInfo?.data?.attributes?.engagements_permissions?.accounts_access?.data?.map(
          (item) => {
            if (item?.attributes?.access_permissions?.length) {
              objIntial.account_access[
                `${item?.attributes?.data_source}-${item?.attributes?.account_id}`
              ] = item?.attributes?.access_permissions;
            }
          },
        );
      }

      if (
        userViewInfo?.data?.attributes?.engagements_permissions?.channels_access
          ?.data?.length
      ) {
        userViewInfo?.data?.attributes?.engagements_permissions?.channels_access?.data?.map(
          (item) => {
            objIntial.channels_access[item?.id] = ["ACCESS"];
          },
        );
      }

      if (
        userViewInfo?.data?.attributes?.engagements_permissions?.monitors_access
          ?.data?.length
      ) {
        userViewInfo?.data?.attributes?.engagements_permissions?.monitors_access?.data?.map(
          (item) => {
            objIntial.monitors_access[item?.id] = ["ACCESS"];
          },
        );
      }
      setAccTableIntialData(_.cloneDeep({ ...objIntial }));
      setAccTableSelectedData(_.cloneDeep({ ...objIntial }));
    }
  }, [userViewInfo]);

  //to call and handle data if the ENGAGEMENT is active
  useEffect(() => {
    const activeProducts = JSON.parse(window?.localStorage?.activeProducts);
    const isEngagActive = activeProducts?.filter(
      (item) => item?.name == "ENGAGEMENTS" && item?.active,
    )?.[0]?.id;
    if (isEngagActive) {
      getTablesData();
    }
  }, []);

  const links = [
    {
      name: "settings",
      url: "settings",
    },
    {
      name: "user_list",
      url: "settings/user_managements",
    },
    {
      name: `${formik?.values?.first_name} ${formik?.values?.last_name}`,
      url: `settings/user_managements/view/${id}`,
    },
  ];

  useEffect(() => {
    if (!Object.keys(originalUserData.current)?.length) return;
    try {
      let counter = 0;
      const teamsDiff = isTeamsDifferent(
        teamsData?.teamsSelected,
        originalUserData.current?.teams || [],
      );
      if (teamsDiff) counter++;
      const advancedTabsDiffCounter = countAdvancedTabsDataDifferences(
        advancedTabsData,
        originalUserData.current?.advancedTabsData,
      );
      counter += advancedTabsDiffCounter;
      const infoTabsDiffCounter = countInfoTabsDataDifferences(
        infoTabsData,
        originalUserData.current?.infoTabsData,
      );
      counter += infoTabsDiffCounter;
      setNumberOfChanges(counter);
    } catch (e) {
      // handle error
    }
  }, [teamsData, advancedTabsData, infoTabsData]);

  useEffect(() => {
    let counter = 0;
    if (formik?.values?.avatar !== stepOneIntialData?.avatar) {
      ++counter;
    }
    if (
      formik?.values?.first_name?.trim() !==
      stepOneIntialData?.first_name?.trim()
    ) {
      ++counter;
    }
    if (
      formik?.values?.last_name?.trim() !== stepOneIntialData?.last_name?.trim()
    ) {
      ++counter;
    }
    if (formik?.values?.mail !== stepOneIntialData?.mail) {
      ++counter;
    }
    if (
      formik?.values?.job_title?.trim() !== stepOneIntialData?.job_title?.trim()
    ) {
      ++counter;
    }
    if (formik?.values?.phone !== stepOneIntialData?.phone) {
      ++counter;
    }
    Object.keys(stepTwoData)?.map((item) => {
      if (stepTwoData[item] !== stepTwoIntialData[item]) {
        ++counter;
      }
    });

    if (
      accTableSelectedData?.engagement_features?.engag_acc_title?.length !==
      accTableIntialData?.engagement_features?.engag_acc_title?.length
    ) {
      ++counter;
    } else {
      let done = false;
      accTableSelectedData?.engagement_features?.engag_acc_title?.map(
        (item) => {
          if (
            !accTableIntialData?.engagement_features?.engag_acc_title?.includes(
              item,
            ) &&
            !done
          ) {
            done = true;
            ++counter;
          }
        },
      );
    }

    if (
      accTableSelectedData?.engagement_features?.saved_reps_mang?.length !==
      accTableIntialData?.engagement_features?.saved_reps_mang?.length
    ) {
      ++counter;
    } else {
      let done = false;
      accTableSelectedData?.engagement_features?.saved_reps_mang?.map(
        (item) => {
          if (
            !accTableIntialData?.engagement_features?.saved_reps_mang?.includes(
              item,
            ) &&
            !done
          ) {
            done = true;
            ++counter;
          }
        },
      );
    }
    if (
      accTableSelectedData?.engagement_features?.engag_tabs?.length !==
      accTableIntialData?.engagement_features?.engag_tabs?.length
    ) {
      ++counter;
    } else {
      let done = false;
      accTableSelectedData?.engagement_features?.engag_tabs?.map((item) => {
        if (
          !accTableIntialData?.engagement_features?.engag_tabs?.includes(
            item,
          ) &&
          !done
        ) {
          done = true;
          ++counter;
        }
      });
    }
    if (
      Object.keys(accTableSelectedData?.account_access)?.length !==
      Object.keys(accTableIntialData?.account_access)?.length
    ) {
      ++counter;
    } else {
      let done = false;
      Object.keys(accTableSelectedData?.account_access)?.map((item) => {
        if (
          accTableSelectedData?.account_access[item]?.length !==
            accTableIntialData?.account_access[item]?.length &&
          !done
        ) {
          done = true;
          ++counter;
        } else if (!done) {
          accTableSelectedData?.account_access[item]?.map((perm) => {
            if (!accTableIntialData?.account_access[item]?.includes(perm)) {
              done = true;
              ++counter;
            }
          });
        }
      });
    }

    if (
      Object.keys(accTableSelectedData?.monitors_access)?.length !==
      Object.keys(accTableIntialData?.monitors_access)?.length
    ) {
      ++counter;
    } else {
      let done = false;
      Object.keys(accTableSelectedData?.monitors_access)?.map((item) => {
        if (
          accTableSelectedData?.monitors_access[item]?.length !==
            accTableIntialData?.monitors_access[item]?.length &&
          !done
        ) {
          done = true;
          ++counter;
        } else if (!done) {
          accTableSelectedData?.monitors_access[item]?.map((perm) => {
            if (!accTableIntialData?.monitors_access[item]?.includes(perm)) {
              done = true;
              ++counter;
            }
          });
        }
      });
    }

    if (
      Object.keys(accTableSelectedData?.channels_access)?.length !==
      Object.keys(accTableIntialData?.channels_access)?.length
    ) {
      ++counter;
    } else {
      let done = false;
      Object.keys(accTableSelectedData?.channels_access)?.map((item) => {
        if (
          accTableSelectedData?.channels_access[item]?.length !==
            accTableIntialData?.channels_access[item]?.length &&
          !done
        ) {
          done = true;
          ++counter;
        } else if (!done) {
          accTableSelectedData?.channels_access[item]?.map((perm) => {
            if (!accTableIntialData?.channels_access[item]?.includes(perm)) {
              done = true;
              ++counter;
            }
          });
        }
      });
    }
    setNumberOfChangesTables(counter);
  }, [formik?.values, stepTwoData, accTableSelectedData]);

  const { mutate: habdleSaveEditUser, isPending: saveLoading } =
    useSaveEditUser();
  const saveEditUser = () => {
    let payLoadObj = generateUserPayload({
      stepTwoData,
      stepOneData: formik?.values,
      isEngagement:
        stepTwoData?.engagement && stepTwoData?.engagement !== "no_acc",
      accTableSelectedData,
      infoTabsData,
      advancedTabsData,
      teamsData,
      accEngagId,
    });

    habdleSaveEditUser(
      { payLoadObj, id },
      {
        onSuccess: () => {
          navigate(
            `/settings/user_managements/view/${id}?status=updated_successfully`,
          );
        },
        onError: () => {
          setOpenSnackBar({
            open: true,
            tit: "wrong_request_parameter",
            msg: "report_fail_try_later",
          });
        },
      },
    );
  };

  if (isUserViewInfoPending) return <CircularLoading />;
  return (
    <>
      <Box className="main-edit-user">
        <EditHeader links={links} />
        <Box className="main-edit-user-wrapper">
          {/*edite user card */}
          <MaineEditCard formik={formik} isEditUser />
          {/* teams and tabs */}
          <Box className="main-edit-user-card">
            <Box className="edit-sec-tit">
              {CheckValueLocale("prod_permissions", "", {}, intl)}
            </Box>
            <Box className="edit-sec-container">
              <ProductsPermissions
                userInfoDone={userInfoDone}
                stepTwoData={stepTwoData}
                isEngagAcc={isEngagAcc}
                setStepTwoData={setStepTwoData}
              />
            </Box>
          </Box>
          {stepTwoData?.engagement && stepTwoData?.engagement !== "no_acc" ? (
            nextLoading ? (
              <CircularLoading />
            ) : (
              <>
                <TeamsAndTabs
                  advancedTabsData={advancedTabsData}
                  setAdvancedTabsData={setAdvancedTabsData}
                  infoTabsData={infoTabsData}
                  setInfoTabsData={setInfoTabsData}
                  tabsErrors={tabsErrors}
                  setTabsErrors={setTabsErrors}
                  teamsData={teamsData}
                  setTeamsData={setTeamsData}
                />
                <Box className="main-edit-user-card">
                  <Box className="edit-sec-container">
                    <Box className="edit-sec-tit">
                      {CheckValueLocale("adv_perm", "", {}, intl)}
                    </Box>
                    {Object.keys(accTableData)?.map((table, index) => {
                      return (
                        <Box
                          key={index}
                          className={`main-acc-table-countainer ${table}`}
                          id={table}
                        >
                          {accTableData[table]?.length ? (
                            <Box className="main-table-tit">
                              {CheckValueLocale(
                                table === "engagement_features"
                                  ? "engagement_features_v2"
                                  : table,
                                "",
                                {
                                  num1: Object.keys(accTableSelectedData[table])
                                    ?.length,
                                  num2: accTableDetailsData[`${table}_counter`],
                                },
                                intl,
                              )}
                              {table == "account_access" ? (
                                <Box className="main-table-acc-prag">
                                  {CheckValueLocale(
                                    "account_select_prag",
                                    "",
                                    {},
                                    intl,
                                  )}
                                </Box>
                              ) : null}
                            </Box>
                          ) : null}
                          <AccessTable
                            name={table}
                            accTableData={accTableData}
                            accTableSelectedData={accTableSelectedData}
                            setAccTableSelectedData={setAccTableSelectedData}
                            accTableDetailsData={accTableDetailsData}
                            setAccTableDetailsData={setAccTableDetailsData}
                            setAccTableData={setAccTableData}
                            tableCols={tableCols}
                          />
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </>
            )
          ) : null}
        </Box>
      </Box>

      {numberOfChanges + numberOfChangesTables > 0 ? (
        <EditUserFooter
          handleCancel={handleCancel}
          numberOfChanges={numberOfChanges + numberOfChangesTables}
          handleSave={saveEditUser}
          isDisabled={
            !formik?.isValid ||
            (stepTwoData?.engagement &&
              stepTwoData?.engagement !== "no_acc" &&
              (tabsErrors?.topicsPreError || tabsErrors?.workgingHoursPreError))
          }
          isLoading={saveLoading}
        />
      ) : null}
      <SnackBar
        open={openSnackBar?.open}
        severity={"error"}
        message={CheckValueLocale(openSnackBar?.msg, "", {}, intl)}
        title={CheckValueLocale(openSnackBar?.tit, "", {}, intl)}
        handleClose={() => setOpenSnackBar({ open: false })}
      />
    </>
  );
};
export default EditUser;
