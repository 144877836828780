import { Box } from "@mui/material";
import NoDataDetails from "./noDetails/noDataDetails";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import PostsAndReplies from "./postsAndReplies/postsAndReplies";
import EngagementHeader from "./engagementHeader/engagementHeader";
import Chat from "./chatDm/chat";
import EngagementsController from "services/controllers/engagementsController";
import { connectWithRabbitMQ } from "services/controllers/rabbitMQResponce";
import { CheckValueLocale, deleteCookie, isEmptyObj } from "utils/helpers";
import DetailsSideBar from "../detailsSideBar/detailsSideBar";
import SnackBar from "components/snackBar";
import DetailsSideBarReply from "../detailsSideBar/detailsSideBarReply";
import AiSuggestions from "../aiSuggestions/aiSuggestions";
import languagesData from "./engagementTranslation/languages.json";
import mqttRabbitMQResponce from "services/controllers/mqttRabbitMQResponce";
import SlaDetailsSideBar from "./../detailsSideBar/sla/slaDetailsSideBar";
import { useDispatch, useSelector } from "react-redux";
import {
  slaData,
  clearSla,
} from "utils/redux/features/Engagements/EngagementsSlice.js";
import io from "socket.io-client";
import Services from "services/api/Services.js";

import { useIntl } from "react-intl";
import {
  engagementStatusMapper,
  engagementTabsActions,
  useEngagementTabs,
} from "pages/engagements/providers/engagementsTabsProvider";
import { jwtDecode } from "jwt-decode";
import LoginController from "services/controllers/loginController";
import { useNavigate } from "react-router-dom";
import { resetChatMessages } from "utils/redux/features/AiAgentChat/AiAgentChatSlice";
import { useRefreshToken } from "pages/loginPage/refreshToken/hooks/useRefreshToken";

const EngagementsDetails = ({
  setRefresh,
  setTabValue,
  setComplete,
  setTotalEngagements,
  dates,
  openSidebar,
  setOpenSidebar,
  checkAiSuggestions,
  setCheckAiSuggestions,
  isSLASideBarOpen,
  openSidebarToAssignTeam,
  setOpenSidebarToAssignTeam,
  aiAgentList,
  setOpenSnackBarAiAgent,
  setSnackBarDataAiAgent,
  setInjectedMessage,
  aiAgentId,
}) => {
  const intl = useIntl();
  const {
    cardState,
    setCardState,
    activeEngagement: selectedCard,
    handleEngagementTabs,
  } = useEngagementTabs();
  const mqttConnectionClosed = React.useRef(false);
  const clientMQTT = React.useRef();
  const [response, setResponse] = useState({});
  const [profileSideBarData, setProfileSideBarData] = useState({});
  const [preLoader, setPreLoader] = useState("");
  const [itemData, setItemData] = useState({});
  const [fetchData, setFetchData] = useState(false);
  const [fetchLogsData, setFetchLogsData] = useState(false);
  const [fetchProfileData, setFetchProfileData] = useState(true);
  const [headerData, setHeaderData] = useState();
  const [statusChanged, setStatusChanged] = useState(false);
  const [savedSuggTab, setSavedSuggTab] = useState(false);
  const [savedSuggTabDate, setSavedSuggTabDate] = useState(0);
  const [isCashedReplays, setIsCashedReplays] = useState(false);
  const [aiRepsTab, setAiRepsTab] = useState(false);
  const [aiReplaysRefresh, setAiReplaysRefresh] = useState(false);

  const [page, setPage] = useState(1);
  const [text, setText] = useState("");
  // replyBoxInputTextRef will be passed to the reply box component and reply box component will register some functions to it
  // so that we can now some information about the reply box from the parent component. e.g. where is the cursor in the reply box, if we want to focus on the reply box, ...etc
  const replyBoxInputTextRef = React.useRef({});
  const [openDetailsSideBarReplay, setOpenDetailsSideBarReplay] =
    useState(false);
  const [selectedValue, setSelectedValue] = useState({});
  const [chatData, setChatData] = useState([]);
  const [selectedCardIteractionID, setSelectedCardIteractionID] = useState(0);
  const [translationActive, setTranslationActive] = useState(false);
  const [translationSource, setTranslationSource] = useState("");
  const [translationTarget, setTranslationTarget] = useState("");
  const [stats, setStats] = useState({
    open: false,
    title: "",
    body: "",
    severity: "",
  });
  const [openDetailsSideBar, setOpenDetailsSideBar] = useState({
    open: false,
    type: "",
  });
  const [openSlaSideBar, setOpenSlaSideBar] = useState(false);
  const dispatch = useDispatch();
  const reduxSlaData = useSelector((state) => state?.engagements?.sla);
  const [isLoadingDmHistory, setIsLoadingDmHistory] = useState(false);
  const languagesArraySource = ["auto_detect", ...languagesData];
  const languagesArrayTarget = [...languagesData];
  const [slaLogs, setSlaLogs] = useState({});
  const [showAllIcon, setShowAllIcon] = useState(false);
  const slaKeyValue = `${selectedCard?.tweet_id ? selectedCard?.tweet_id : selectedCard?.id}-${selectedCard?.tracker_id}`;
  const callSingleinteraction = (pageNumber = page) => {
    if (!isEmptyObj(selectedCard)) {
      // don't show the loader
      if (pageNumber == 1) {
        setFetchData(true);
        setFetchLogsData(true);
        setFetchProfileData(true);
      }
      let queryData = {
        parent_id: selectedCard?.tweet_id || selectedCard?.id,
        product_id: window.localStorage.getItem("engagementsProductId"),
        monitor_id: selectedCard?.tracker_id,
        interaction_id: selectedCard?.tweet_id || selectedCard?.id,
        data_source_name: selectedCard?.data_source?.toUpperCase(),
        page_number: selectedCard?.isDm ? pageNumber : 1,
        user_name: selectedCard?.screen_name
          ? selectedCard?.screen_name
          : selectedCard?.user_name,
        start_date: dates?.startDate,
        routing_id:
          selectedCard?.assigning_info?.routing_id ||
          selectedCard?.sla_info?.routing_id,
        sla_id:
          selectedCard?.sla_info?.sla_id ||
          selectedCard?.assigning_info?.sla_id,
        interaction_created_at: selectedCard?.created_at,
      };
      // we will handle the pagination for all datasources dm only for now
      if (pageNumber > 1) {
        queryData.total_interactions =
          itemData?.total_interactions || undefined;
        queryData.last_message_id = chatData?.length
          ? chatData[0]?.message_id
          : undefined;
        queryData.last_message_created_at = chatData?.length
          ? chatData[0]?.message_created_at
          : undefined;
        queryData.last_created_at = chatData?.length
          ? chatData[0]?.created_at
          : undefined;
      }
      EngagementsController.getSingleInteraction({ ...queryData }).then(
        (data) => {
          if (data?.data?.password && !isEmptyObj(data?.data)) {
            // maybe need it later
            // setSavedSuggTab(data?.data?.has_saved_responses);
            // setSavedSuggTabDate(data?.data?.cached_responses_date);
            // setAiRepsTab(data?.data?.has_cached_answers );
            setHeaderData(data?.data);
            setPreLoader(data?.data?.monitor_name);
            setResponse({});
            // connectWithRabbitMQ(
            //   mqttConnectionClosed,
            //   clientMQTT,
            //   data?.data,
            //   setResponse
            // );
            mqttRabbitMQResponce(clientMQTT, data?.data, setResponse);
          } else {
            mqttConnectionClosed.current = true;
            // window.location.reload();
          }
        },
      );
    } else if (pageNumber > 1 || !isEmptyObj(itemData) || fetchData) {
      setPage(1);
      setFetchData(false);
      setItemData({});
    }
  };

  const getInteractionDmHistory = () => {
    setIsLoadingDmHistory(true);
    const queryData = {
      parent_id:
        itemData?.tweet_id || itemData?.id || itemData?.conversation_id,
      monitor_id: itemData?.tracker_id,
      client_id: itemData?.client_id,
      agent_id: itemData?.agent_id || undefined,
      product_id: window.localStorage.getItem("engagementsProductId"),
      data_source_name: itemData?.data_source?.toUpperCase(),
      last_message_id: chatData?.length ? chatData[0]?.message_id : undefined,
      last_message_created_at: chatData?.length
        ? chatData[0]?.message_created_at
        : undefined,
      last_created_at: chatData?.length ? chatData[0]?.created_at : undefined,
      interaction_created_at: selectedCard?.created_at,
    };
    EngagementsController.getInteractionDmHistory(queryData).then((data) => {
      if (data?.data?.password && !isEmptyObj(data?.data)) {
        setResponse({});

        mqttRabbitMQResponce(clientMQTT, data?.data, setResponse);
      } else {
        setIsLoadingDmHistory(false);
      }
    });
  };
  // reset all states data on change selected card
  useEffect(() => {
    setPage(1);
    setChatData([]);
    setSelectedCardIteractionID(0);
    setItemData({});
    setText("");
    setSelectedValue({});
    setOpenDetailsSideBar({
      open: false,
      type: "",
    });
    setOpenDetailsSideBarReplay(false);
    setOpenSlaSideBar(false);
    setOpenSidebar(false);
    setTranslationSource("");
    setTranslationTarget("");
    setTranslationActive(false);
    setSlaLogs({});
    callSingleinteraction(1);
    setIsLoadingDmHistory(false);
  }, [selectedCard]);

  const [retry, setRetry] = useState(0);
  // Use the refreshToken mutation from your custom hook
  const { mutate: refreshToken, isFetching } = useRefreshToken();
  const navigate = useNavigate();
  //connect to socket
  ///engagements
  const socket = useMemo(() => {
    return io(Services.socketUrl + "engagements", {
      path: "/ws/",
      transports: ["websocket"],
      auth: {
        "luc-authorization": localStorage.getItem("user_token"),
      },
    });
  }, [retry]);
  //Use this function to sign out the user
  const handleSignOut = () => {
    let currentLang = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : window.navigator.language.split("-")[0];
    LoginController.userlogout().then((data) => {
      dispatch(resetChatMessages());
      localStorage.clear();
      deleteCookie("email");
      window.localStorage.setItem("lang", currentLang);
      window.location.href = "/login";
    });
  };
  const autoSendToken = useCallback(() => {
    if (isFetching) return; // Prevent multiple API calls if already fetching

    refreshToken(
      {},
      {
        onSuccess: (data) => {
          const token = data?.data?.data?.["luc-authorization"];
          if (token) {
            localStorage.setItem("user_token", token);
            //  reload the page to reconnect the socket
            setRetry(4);
          } else {
            handleSignOut();
          }
        },
        onError: () => {
          if (navigator.onLine) {
            handleSignOut();
          }
        },
      },
    );
  }, [isFetching, navigate, refreshToken]);

  useEffect(() => {
    if (retry === 3) {
      autoSendToken();
    }

    const logToConsole = (...args) => {
      if (window.location.host !== "cxm.lucidya.com") {
        console.log(...args);
      }
    };

    const handleConnect = () => {
      logToConsole("Socket connected");
    };

    const handleConnectError = (err) => {
      logToConsole("Socket connect_error:", err);
      // Capture the error in Sentry
      // Sentry.captureException(err);
    };

    const handleError = (err) => {
      logToConsole("Socket error:", err);
      // Capture the error in Sentry
      // Sentry.captureException(err);
    };

    // Fired if the socket gets disconnected after having been connected
    const handleDisconnect = (...res) => {
      setTimeout(() => {
        // Retry to connect
        if (retry < 3) setRetry(retry + 1);
      }, 1000);
      logToConsole("Socket disconnected:", res);
      // If the reason is "io server disconnect" or a custom reason you set server-side
      // you might want to capture that in Sentry, especially if it's unexpected
      if (res !== "client namespace disconnect") {
        // Sentry.captureMessage(`Socket disconnected unexpectedly: ${reason}`);
      }
    };
    const handleConnection = () => {
      logToConsole("Socket connected");
    };
    const onClose = () => {
      logToConsole("Socket closed");
    };

    const onData = (data) => {
      logToConsole("Received data:", data);
    };

    // This handler will be called whenever *any* event is received
    const handleAnyEvent = (eventName, ...args) => {
      logToConsole("Received event:", eventName, "with args:", args);
    };

    socket.on("connection", handleConnection);
    socket.on("connect", handleConnect);
    socket.on("connect_error", handleConnectError);
    socket.on("error", handleError);
    socket.on("disconnect", handleDisconnect);
    socket.on("close", onClose);
    socket.on("data", onData);
    // Listen for every event
    socket.onAny(handleAnyEvent);

    // add a listenr on all messages that comes from any event from the server
    return () => {
      socket.off("connection", handleConnection);
      socket.off("connect", handleConnect);
      socket.off("connect_error", handleConnectError);
      socket.off("error", handleError);
      socket.off("disconnect", handleDisconnect);
      socket.off("close", onClose);
      socket.off("data", onData);
      socket.offAny(handleAnyEvent);
      socket.disconnect();
    };
  }, [socket, retry]);

  // handle open ai Suggestions side bar and close others
  const handelOpenSidebar = () => {
    setCheckAiSuggestions(false);
    setOpenDetailsSideBarReplay(false);
    setOpenDetailsSideBar({
      open: false,
      type: "",
    });
    setOpenSlaSideBar(false);
    setOpenSidebar(true);
  };

  //handle Toggle sla sideBar
  const handleToggleSlaSideBar = () => {
    setCheckAiSuggestions(false);
    setOpenDetailsSideBarReplay(false);
    setOpenDetailsSideBar({
      open: false,
      type: "",
    });
    setOpenSidebar(false);
    setOpenSlaSideBar(!openSlaSideBar);
  };

  // call single interaction for selected card paginaition at DM
  useEffect(() => {
    if (page > 1) {
      callSingleinteraction();
    }
  }, [page]);
  useEffect(() => {
    if (!isEmptyObj(response) && !isEmptyObj(selectedCard)) {
      switch (response.eventName) {
        case "Twitterprivate__EngagementsSingleInteractionDMHistoryPage":
        case "Facebookprivate__EngagementsSingleInteractionDMHistoryPage":
        case "Instagramprivate__EngagementsSingleInteractionDMHistoryPage":
        case "Whatsappprivate__EngagementsSingleInteractionDMHistoryPage":
          let data = response?.eventData?.single_interaction;
          setItemData((prevData) => ({
            ...prevData,
            conversation: data?.conversation || [],
            staticUpdated: false,
          }));
          setFetchData(false);
          break;

        case "Twitter_public__EngagementsSingleInteractionPage":
        case "Instagram_public__EngagementsSingleInteractionPage":
        case "Facebook_public__EngagementsSingleInteractionPage":
        case "Facebook__EngagementsSingleInteractionPage":
        case "Instagram__EngagementsSingleInteractionPage":
        case "Twitter__EngagementsSingleInteractionPage":
        case "Instagramprivate__EngagementsSingleInteractionPage":
        case "Twitterprivate__EngagementsSingleInteractionPage":
        case "Facebookprivate__EngagementsSingleInteractionPage":
        case "Whatsappprivate__EngagementsSingleInteractionPage":
          if (
            response?.monitor_id == selectedCard?.tracker_id ||
            selectedCard?.id ==
              response?.eventData?.single_interaction?.conversation_id
          ) {
            let data = response?.eventData?.parent_single_interaction
              ? {
                  ...response?.eventData?.parent_single_interaction,
                  isDm: false,
                }
              : {
                  ...response?.eventData?.single_interaction,
                  isDm: true,
                };
            setSelectedCardIteractionID(
              data?.system_replies?.interaction_id || 0,
            );
            let slaObj = { ...reduxSlaData };
            slaObj[
              `${selectedCard?.tweet_id ? selectedCard?.tweet_id : selectedCard?.id}-${selectedCard?.tracker_id}`
            ] = {
              ...slaObj[
                `${selectedCard?.tweet_id ? selectedCard?.tweet_id : selectedCard?.id}-${selectedCard?.tracker_id}`
              ],
              slaId: data?.sla_info?.sla_id || data?.assigning_info?.sla_id,
              userId: data?.assigning_info?.user_id,
              teamId: data?.assigning_info?.team_id,
            };
            dispatch(slaData(slaObj));
            let statusItemCard = data?.system_replies?.status
              ? data?.system_replies?.status
              : data?.status;
            if (cardState?.opendFromCard) {
              if (cardState?.activeTab !== "all") {
                if (
                  statusItemCard == "complete" &&
                  cardState?.activeTab !== "complete"
                ) {
                  setStats({
                    ...stats,
                    open: true,
                    title: "",
                    body: CheckValueLocale("engag_to_complete", "", {}, intl),
                    severity: "error",
                  });
                  setCardState({
                    ...cardState,
                    changeStateTo: statusItemCard,
                    changeStateDone: true,
                  });
                } else if (
                  statusItemCard == "reopen" &&
                  cardState?.activeTab !== "active"
                ) {
                  setStats({
                    ...stats,
                    open: true,
                    title: "",
                    body: CheckValueLocale("engag_to_active", "", {}, intl),
                    severity: "error",
                  });
                  setCardState({
                    ...cardState,
                    changeStateTo: statusItemCard,
                    changeStateDone: true,
                  });
                }
                setTimeout(() => {
                  setStats({
                    ...stats,
                    open: false,
                  });
                }, 7000);
              } else {
                if (
                  (statusItemCard == "complete" &&
                    cardState?.cardInitialState !== "complete") ||
                  (statusItemCard == "reopen" &&
                    cardState?.cardInitialState !== "reopen")
                ) {
                  setCardState({
                    ...cardState,
                    changeStateTo: statusItemCard,
                    changeStateDone: true,
                    notClose: true,
                  });
                }
              }
            }
            handleEngagementTabs(engagementTabsActions.update_current_tab, {
              engagementStatus: engagementStatusMapper[statusItemCard] || "new",
            });
            setItemData({
              ...data,
              data_source: selectedCard?.data_source?.toUpperCase(),
              user_mention: selectedCard?.isDm
                ? selectedCard?.user_name
                : selectedCard?.screen_name,
              // pages and total interactions will only be received in the first page
              pages: response?.eventData?.pages || itemData?.pages,
              // total_interactions indicate to the number of message in this conversation, will only be received in the first page
              total_interactions:
                response?.eventData?.total_messages_count ||
                itemData?.total_interactions,
              // has_multiple_conversations indicates if the conversation has multiple conversations which we can show the full dm history
              has_multiple_conversations:
                response?.eventData?.has_multiple_conversations ||
                itemData?.has_multiple_conversations,
            });
            setFetchData(false);
          }
          break;
        case "Twitter_public__EngagementsProfileDataPage":
        case "Instagram_public__EngagementsProfileDataPage":
        case "Facebook_public__EngagementsProfileDataPage":
        case "Instagramprivate__EngagementsProfileDataPage":
        case "Twitterprivate__EngagementsProfileDataPage":
        case "Facebookprivate__EngagementsProfileDataPage":
        case "Whatsappprivate__EngagementsProfileDataPage":
          setProfileSideBarData(response?.eventData);
          setFetchProfileData(false);
          break;

        case "Twitter_public__EngagementsRoutingsLogsPage":
        case "Instagram_public__EngagementsRoutingsLogsPage":
        case "Facebook_public__EngagementsRoutingsLogsPage":
        case "Facebook__EngagementsRoutingsLogsPage":
        case "Instagram__EngagementsRoutingsLogsPage":
        case "Twitter__EngagementsRoutingsLogsPage":
        case "Instagramprivate__EngagementsRoutingsLogsPage":
        case "Twitterprivate__EngagementsRoutingsLogsPage":
        case "Facebookprivate__EngagementsRoutingsLogsPage":
        case "Whatsappprivate__EngagementsRoutingsLogsPage":
          if (page == 1) {
            setSlaLogs(response?.eventData?.engagements_routings_logs);
            let data = response?.eventData?.engagements_routings_logs?.sla_data;
            let slaObj = { ...reduxSlaData };
            slaObj[
              `${selectedCard?.tweet_id ? selectedCard?.tweet_id : selectedCard?.id}-${selectedCard?.tracker_id}`
            ] = {
              ...slaObj[
                `${selectedCard?.tweet_id ? selectedCard?.tweet_id : selectedCard?.id}-${selectedCard?.tracker_id}`
              ],
              frtSec: data?.first_response_time?.time_value,
              ttcSec: data?.time_to_complete?.time_value,
              ttcCompletedDate: data?.time_to_complete?.created_at,
              nrtList: data?.next_response_time?.length
                ? [...data?.next_response_time]
                : [],
              tutSec: data?.unassigned_response_time?.time_value,
            };
            dispatch(slaData(slaObj));
            setFetchLogsData(false);
          }
          break;
        default:
          break;
      }
    }
  }, [response]);

  //handle show more from profile sideBar
  const showTotalEngagementsHandler = () => {
    setTotalEngagements({
      user_name:
        selectedCard?.data_source === "twitter" && !selectedCard?.isDm
          ? selectedCard?.screen_name
          : selectedCard?.user_name,
      data_sources: selectedCard?.data_source?.toUpperCase(),
    });
    setStats({
      ...stats,
      open: true,
      body: CheckValueLocale("show_engagements_snackBar", "", {}, intl),
      severity: "info",
    });
    setTimeout(() => {
      setStats({
        ...stats,
        open: false,
      });
    }, 7000);
  };

  const handelEngagementsDetailsPostwidth = () => {
    let engagementsDetailsPostwidth = window?.innerWidth - 336;
    if (isSLASideBarOpen) {
      engagementsDetailsPostwidth -= 175;
    }
    if (
      openDetailsSideBar?.open ||
      openDetailsSideBarReplay ||
      openSidebar ||
      openSlaSideBar
    ) {
      engagementsDetailsPostwidth -= 290;
    }

    if (engagementsDetailsPostwidth > 750) {
      setShowAllIcon(true);
    } else {
      setShowAllIcon(false);
    }
  };
  useEffect(() => {
    handelEngagementsDetailsPostwidth();
    window.addEventListener("resize", handelEngagementsDetailsPostwidth);
    return () => {
      window.removeEventListener("resize", handelEngagementsDetailsPostwidth);
    };
  }, [
    openDetailsSideBar?.open,
    openDetailsSideBarReplay,
    openSidebar,
    openSlaSideBar,
    isSLASideBarOpen,
  ]);
  return (
    <Box className="engagements-details-wrapper">
      {(itemData?.tweet_id || itemData?.conversation_id) && !fetchData ? (
        <div className="engagements-details-box">
          <div
            className={`details-box ${
              openDetailsSideBar?.open ||
              openDetailsSideBarReplay ||
              openSidebar ||
              openSlaSideBar
                ? "open-details-box"
                : ""
            }`}
          >
            <EngagementHeader
              selectedCard={selectedCard}
              cardState={cardState}
              setCardState={setCardState}
              selectedCardIteractionID={selectedCardIteractionID}
              profileSideBarData={profileSideBarData}
              fetchProfileData={fetchProfileData}
              showTotalEngagementsHandler={showTotalEngagementsHandler}
              itemData={itemData}
              setItemData={setItemData}
              stats={stats}
              setStats={setStats}
              setTabValue={setTabValue}
              setComplete={setComplete}
              headerData={headerData}
              statusChanged={statusChanged}
              setStatusChanged={setStatusChanged}
              setRefresh={setRefresh}
              setOpenDetailsSideBar={setOpenDetailsSideBar}
              setOpenDetailsSideBarReplay={setOpenDetailsSideBarReplay}
              setOpenSidebar={setOpenSidebar}
              setOpenSlaSideBar={setOpenSlaSideBar}
              openSlaSideBar={openSlaSideBar}
              handleToggleSlaSideBar={handleToggleSlaSideBar}
              setTranslationActive={setTranslationActive}
              translationSource={translationSource}
              setTranslationSource={setTranslationSource}
              translationTarget={translationTarget}
              setTranslationTarget={setTranslationTarget}
              languagesArraySource={languagesArraySource}
              languagesArrayTarget={languagesArrayTarget}
              translationActive={translationActive}
              isLogsFetched={!fetchLogsData || page > 1}
              openSidebarToAssignTeam={openSidebarToAssignTeam}
              setOpenSidebarToAssignTeam={setOpenSidebarToAssignTeam}
              aiAgentId={aiAgentId}
              slaKeys={slaKeyValue}
            />
            {itemData?.isDm ? (
              <Chat
                selectedCard={itemData}
                itemSelected={selectedCard}
                setSelectedCardIteractionID={setSelectedCardIteractionID}
                headerData={headerData}
                setPage={setPage}
                page={page}
                setOpenDetailsSideBar={setOpenDetailsSideBar}
                setOpenDetailsSideBarReplay={setOpenDetailsSideBarReplay}
                setOpenSlaSideBar={setOpenSlaSideBar}
                openSlaSideBar={openSlaSideBar}
                text={text}
                setText={setText}
                replyBoxInputTextRef={replyBoxInputTextRef}
                checkAiSuggestions={checkAiSuggestions}
                setCheckAiSuggestions={setCheckAiSuggestions}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                chatData={chatData}
                openSidebar={openSidebar}
                setChatData={setChatData}
                setOpenSidebar={setOpenSidebar}
                handelOpenSidebar={handelOpenSidebar}
                translationActive={translationActive}
                setTranslationActive={setTranslationActive}
                translationSource={translationSource}
                setTranslationSource={setTranslationSource}
                translationTarget={translationTarget}
                setTranslationTarget={setTranslationTarget}
                stats={stats}
                setStats={setStats}
                languagesArraySource={languagesArraySource}
                languagesArrayTarget={languagesArrayTarget}
                statusChanged={statusChanged}
                setStatusChanged={setStatusChanged}
                setRefresh={setRefresh}
                profileSideBarData={profileSideBarData}
                fetchProfileData={fetchProfileData}
                showTotalEngagementsHandler={showTotalEngagementsHandler}
                openDetailsSideBarReplay={openDetailsSideBarReplay}
                savedSuggTab={savedSuggTab}
                aiRepsTab={aiRepsTab}
                savedSuggTabDate={savedSuggTabDate}
                isCashedReplays={isCashedReplays}
                setIsCashedReplays={setIsCashedReplays}
                setAiReplaysRefresh={setAiReplaysRefresh}
                showAllIcon={showAllIcon}
                isSidebarsOpened={
                  isSLASideBarOpen &&
                  (openDetailsSideBar?.open ||
                    openDetailsSideBarReplay ||
                    openSidebar ||
                    openSlaSideBar)
                }
                aiAgentList={aiAgentList}
                setOpenSnackBarAiAgent={setOpenSnackBarAiAgent}
                setSnackBarDataAiAgent={setSnackBarDataAiAgent}
                setInjectedMessage={setInjectedMessage}
                aiAgentId={aiAgentId}
                slaKey={slaKeyValue}
                isLoadingDmHistory={isLoadingDmHistory}
                getInteractionDmHistory={getInteractionDmHistory}
                setIsLoadingDmHistory={setIsLoadingDmHistory}
                socket={socket}
              />
            ) : (
              <PostsAndReplies
                selectedCard={itemData}
                setSelectedCard={setItemData}
                itemSelected={selectedCard}
                headerData={headerData}
                setOpenDetailsSideBar={setOpenDetailsSideBar}
                setSelectedCardIteractionID={setSelectedCardIteractionID}
                setOpenDetailsSideBarReplay={setOpenDetailsSideBarReplay}
                setOpenSlaSideBar={setOpenSlaSideBar}
                openSlaSideBar={openSlaSideBar}
                text={text}
                setText={setText}
                replyBoxInputTextRef={replyBoxInputTextRef}
                openSidebar={openSidebar}
                setOpenSidebar={setOpenSidebar}
                checkAiSuggestions={checkAiSuggestions}
                setCheckAiSuggestions={setCheckAiSuggestions}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                handelOpenSidebar={handelOpenSidebar}
                translationActive={translationActive}
                setTranslationActive={setTranslationActive}
                translationSource={translationSource}
                setTranslationSource={setTranslationSource}
                translationTarget={translationTarget}
                setTranslationTarget={setTranslationTarget}
                stats={stats}
                setStats={setStats}
                languagesArraySource={languagesArraySource}
                languagesArrayTarget={languagesArrayTarget}
                openDetailsSideBarReplay={openDetailsSideBarReplay}
                savedSuggTab={savedSuggTab}
                aiRepsTab={aiRepsTab}
                setIsCashedReplays={setIsCashedReplays}
                showAllIcon={showAllIcon}
                isSidebarsOpened={
                  isSLASideBarOpen &&
                  (openDetailsSideBar?.open ||
                    openDetailsSideBarReplay ||
                    openSidebar ||
                    openSlaSideBar)
                }
                aiAgentList={aiAgentList}
                setRefresh={setRefresh}
                setOpenSnackBarAiAgent={setOpenSnackBarAiAgent}
                setSnackBarDataAiAgent={setSnackBarDataAiAgent}
                setInjectedMessage={setInjectedMessage}
                aiAgentId={aiAgentId}
                slaKey={slaKeyValue}
                socket={socket}
              />
            )}
          </div>
          {openDetailsSideBarReplay ? (
            <DetailsSideBarReply
              setOpenDetailsSideBarReplay={setOpenDetailsSideBarReplay}
              text={text}
              setText={setText}
              replyBoxInputTextRef={replyBoxInputTextRef}
            />
          ) : openDetailsSideBar?.open ? (
            <DetailsSideBar
              selectedCard={selectedCard}
              profileSideBarData={profileSideBarData}
              fetchSideBar={fetchProfileData}
              openDetailsSideBar={openDetailsSideBar}
              setOpenDetailsSideBar={setOpenDetailsSideBar}
              showTotalEngagementsHandler={showTotalEngagementsHandler}
            />
          ) : openSidebar ? (
            <AiSuggestions
              setOpenSidebar={setOpenSidebar}
              text={text}
              selectedValue={selectedValue?.displayName}
              itemData={itemData}
              chatData={chatData}
              setText={setText}
              replyBoxInputTextRef={replyBoxInputTextRef}
              setOpenDetailsSideBarReplay={setOpenDetailsSideBarReplay}
              setCheckAiSuggestions={setCheckAiSuggestions}
              monitor_id={selectedCard?.tracker_id}
              interaction_id={selectedCard?.tweet_id || selectedCard?.id}
              data_source_name={selectedCard?.data_source?.toUpperCase()}
              savedSuggTab={savedSuggTab}
              aiRepsTab={aiRepsTab}
              setSavedSuggTab={setSavedSuggTab}
              setAiRepsTab={setAiRepsTab}
              setSavedSuggTabDate={setSavedSuggTabDate}
              isCashedReplays={isCashedReplays}
              setIsCashedReplays={setIsCashedReplays}
              aiReplaysRefresh={aiReplaysRefresh}
              setAiReplaysRefresh={setAiReplaysRefresh}
            />
          ) : openSlaSideBar ? (
            <SlaDetailsSideBar
              setOpenSlaSideBar={setOpenSlaSideBar}
              selectedCard={selectedCard}
              headerData={headerData}
              slaLogs={slaLogs}
              aiAgentId={aiAgentId}
            />
          ) : null}
        </div>
      ) : (
        <NoDataDetails fetchData={fetchData} />
      )}
      <SnackBar
        open={stats?.open}
        title={stats?.title}
        severity={stats?.severity}
        message={stats?.body}
        handleClose={() => setStats({ ...stats, open: false })}
        alertStyle={{ maxWidth: "300px" }}
      />
    </Box>
  );
};
export default EngagementsDetails;
